import { InfoFormNotification } from "../composables/errors";
import { __ } from "../composables/lang";
import CopiesOption from "../options/CopiesOption.vue";

export default function () {
  return (form) => {
    if (!form.hasFileinfo()) {
      return;
    }

    const { doc: { page_count = null } = {} } = form.getFileinfo();
    const formCopies = form.getValue(CopiesOption);

    if (page_count != formCopies) {
      form.updateItem({option: CopiesOption, value: page_count});
      throw new InfoFormNotification(
        __("Er zijn te ") + (page_count > formCopies ? __("weinig") : __("veel")) + __(" pagina's opgegeven. Wij hebben dit gecorrigeerd naar ") + page_count + "."
      );
    }
  };
}
