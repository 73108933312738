import { InfoFormWarning } from "../composables/errors";
import { __ } from "../composables/lang";
import BorderlessOption from "../options/BorderlessOption.vue";

export default function () {
  return (form) => {
    if (!form.isChecked(BorderlessOption)) {
      throw new InfoFormWarning(__("Zie je witte randen? Kies dan voor {cta}."), {
        api: "Do you see white borders? try: borderless: true",
        cta: [
          {
            text: __("randloos printen"),
            fn: () => {
              form.selectItem(BorderlessOption);
              form.touchItem({ option: BorderlessOption });
            },
          },
        ],
      });
    }
  };
}
