<script setup>
import { getCurrentInstance } from 'vue';
import OrderProduct from '../classes/OrderProduct';
import { useOrderForm } from '../../modules/orderModule';
import HiddenOptions from '../HiddenOptions.vue';
import OptionSection from '../OptionSection.vue';
import NumberOption from '../options/NumberOption.vue';
import { __ } from '../composables/lang';
import FileNamesOption from '../options/FileNamesOption.vue';
import SizeA2Option from '../options/SizeA2Option.vue';
import SizeA3Option from '../options/SizeA3Option.vue';
import SizeAbriOption from '../options/SizeAbriOption.vue';
import SizeA1Option from '../options/SizeA1Option.vue';
import SizeA0Option from '../options/SizeA0Option.vue';
import SizeB1Option from '../options/SizeB1Option.vue';
import SizeB0Option from '../options/SizeB0Option.vue';
import SizeCustomOption from '../options/SizeCustomOption.vue';
import PaperVeryFirmOption from '../options/PaperVeryFirmOption.vue';
import PaperCardOption from '../options/PaperCardOption.vue';
import PaperRecycledOption from '../options/PaperRecycledOption.vue';
import PaperPremiumSatinOption from '../options/PaperPremiumSatinOption.vue';
import PaperPremiumMattOption from '../options/PaperPremiumMattOption.vue';
import PaperFirmSatinOption from '../options/PaperFirmSatinOption.vue';
import PaperPolyesterOption from '../options/PaperPolyesterOption.vue';
import FinishingMattOption from '../options/FinishingMattOption.vue';
import ColorAllOption from '../options/ColorAllOption.vue';
import AccessoryNoneOption from '../options/AccessoryNoneOption.vue';
import AccessoryPhotoframeOption from '../options/AccessoryPhotoframeOption.vue';
import AccessoryPosterStripPlasticOption from '../options/AccessoryPosterStripPlasticOption.vue';
import AccessoryVelcroRoundOption from '../options/AccessoryVelcroRoundOption.vue';
import AccessoryTapeDoubleSidedOption from '../options/AccessoryTapeDoubleSidedOption.vue';
import AccessoryHangerWoodOption from '../options/AccessoryHangerWoodOption.vue';
import DefaultOptions from '../DefaultOptions.vue';
import CheckDocStandardOption from '../options/CheckDocStandardOption.vue';
import CheckDocAdvancedOption from '../options/CheckDocAdvancedOption.vue';
import SizeRoundOption from '../options/SizeRoundOption.vue';
import SizePhotoframeOption from '../options/SizePhotoframeOption.vue';
import SizePhotoframeSizesOption from '../options/SizePhotoframeSizesOption.vue';
import FinishingPassePartoutOption from '../options/FinishingPassePartoutOption.vue';
import AccessoryPhotoframeColorsOption from '../options/AccessoryPhotoframeColorsOption.vue';

defineOptions(
  new OrderProduct("poster", "poster", "Poster")
);

const instance = getCurrentInstance().type;

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
});

const form = useOrderForm();

</script>
<template>
  <HiddenOptions :product="props.product" :group="instance.group" />

  <OptionSection :title="__('oplage')">
    <NumberOption :initial="1" />
  </OptionSection>
  <OptionSection :title="__('document')" divider>
    <FileNamesOption />
  </OptionSection>
  <OptionSection :title="__('formaat')">
    <SizeA3Option />
    <SizeA2Option initial />
    <SizeA1Option />
    <SizeA0Option />
    <SizeB1Option />
    <SizeB0Option />
    <SizeAbriOption />
    <SizePhotoframeOption />
    <SizePhotoframeSizesOption  v-if="form.isChecked(SizePhotoframeOption)" />
    <FinishingPassePartoutOption  v-if="form.isChecked(SizePhotoframeOption)" />
    <SizeCustomOption />
    <SizeRoundOption />
  </OptionSection>
  <OptionSection :title="__('papiersoort')">
    <PaperFirmSatinOption initial icon="star" />
    <FinishingMattOption v-if="form.isChecked(PaperFirmSatinOption)" />
    <PaperRecycledOption new />
    <PaperPremiumMattOption />
    <PaperPremiumSatinOption />
    <PaperVeryFirmOption />
    <PaperCardOption />
    <PaperPolyesterOption />
  </OptionSection>
  <OptionSection :title="__('opdruk')">
    <ColorAllOption initial />
  </OptionSection>
  <OptionSection :title="__('Accessoire')">
    <AccessoryNoneOption initial />
    <AccessoryPhotoframeOption />
    <AccessoryPhotoframeColorsOption v-if="form.isChecked(AccessoryPhotoframeOption)" />
    <AccessoryPosterStripPlasticOption />
    <AccessoryHangerWoodOption />
    <AccessoryVelcroRoundOption />
    <AccessoryTapeDoubleSidedOption />
  </OptionSection>
  <OptionSection :title="__('document controle')" divider>
    <CheckDocStandardOption initial />
    <CheckDocAdvancedOption />
  </OptionSection>

  <DefaultOptions />
</template>
