import { InfoFormWarning } from "../composables/errors";
import { __ } from "../composables/lang";
import SizeA4Option from "../options/SizeA4Option.vue";

export default function () {
  return (form, fileInfo) => {
    if (!form.hasFileinfo()) {
      return;
    }

    const { doc: { spread_suggestion = null } = {} } = fileInfo;

    // console.log(form.getValue(CustomWidthOption));

    if (spread_suggestion && form.getValue(SizeA4Option) == spread_suggestion.toLowerCase()) {
      throw new InfoFormWarning(
        __("Wij hebben een spread gedetecteerd voor een ander formaat. Corrigeren naar {cta}?"),
        {
          cta: [
            {
              text: spread_suggestion,
              fn: () => {
                window.open("/aanleveren/aanlevervoorwaarden/expert#een-kader-om-je-drukwerk");
              },
            },
          ],
        },
      );
    }
  };
}
