<script setup>
import { useStore } from "vuex";
import { __ } from "../order/composables/lang";
import InputDropdown from "./InputDropdown.vue";
import { onBeforeMount, reactive } from "vue";

const emit = defineEmits(["update:modelValue"]);
const store = useStore();

const updateModelValue = function (value) {
  emit("update:modelValue", value);
};

const deliveryMethods = reactive({});

const getDeliveryMethods = async function () {
  const tempDeliveryMethods = reactive({});
  let response = await fetch(store.getters.route("delivery_methods"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  if (response.ok) {
    tempDeliveryMethods.value = await response.json();
    for (const [key, value] of Object.entries(tempDeliveryMethods.value)) {
      deliveryMethods[key] = value;
    }
  } else {
    await response.text();
  }
};

onBeforeMount(() => {
  getDeliveryMethods();
});

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
</script>
<template>
  <InputDropdown
    :model-value="modelValue"
    class="#relative"
    :label="__('Levermethode')"
    :items="deliveryMethods"
    @update:model-value="(value) => updateModelValue(value)"
  />
</template>
