<script setup>
import OrderOption from "../classes/OrderOption";
import { __ } from "../composables/lang";
import { useSetup } from "../composables/option";
import { isMaxString, isMinString, isString } from "../checks/basic";
import TextareaField from "../fields/TextareaField.vue";

defineOptions(
  new OrderOption({
    key: "comment",
    updateUrl: true,
  }),
);

const props = defineProps({
  initial: {
    type: String,
    default: null,
  },
});
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

const max = 1000;
const placeholder = __("plaats hier je opmerking, aanwijzing of onzekerheid");

check(isString());
check(isMinString(1));
check(isMaxString(max));
</script>

<template>
  <tbody>
    <TextareaField
      v-bind="$props"
      :disabled="disabled"
      :section="''"
      :option="option"
      :tooltip-title="tooltipTitle"
      :tooltip-content="tooltipContent"
      :placeholder="placeholder"
      :max="max"
    />
  </tbody>
</template>
