<template>
  <SmallForm
    :title="__('Wachtwoord veranderen')"
    :btn-text="__('Wachtwoord veranderen')"
    btn-color="primary"
    btn-size="full-lg"
    :submitting="submitting"
    autocomplete="off"
    @submit="formSubmitted"
  >
    <InputField
      :error="firstError('email') || firstError('__generic__')"
      :model-value="form.email"
      class="#w-full #mb-[30px] #text-left"
      :label="__('E-mailadres')"
      type="text"
      :required="true"
      :disabled="true"
      @update:model-value="(newValue) => (form.email = newValue)"
    />
    <InputField
      :error="firstError('password')"
      :model-value="form.password"
      class="#w-full #mb-[30px] #text-left"
      :label="__('Wachtwoord')"
      type="password"
      auto-complete="new-password"
      :required="true"
      @update:model-value="(newValue) => (form.password = newValue)"
    />
    <InputField
      :model-value="form.password_confirmation"
      class="#w-full #mb-[30px] #text-left"
      :label="__('Wachtwoord herhalen')"
      type="password"
      auto-complete="new-password"
      :required="true"
      @update:model-value="(newValue) => (form.password_confirmation = newValue)"
    />
  </SmallForm>
</template>

<script>
import SmallForm from "../components/SmallForm.vue";
import InputField from "../components/InputField.vue";
import { mapGetters } from "vuex";
import { lang, form } from "../mixins";

export default {
  components: {
    SmallForm,
    InputField,
  },
  mixins: [lang, form],
  data() {
    return {
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
      },
      formErrors: {},
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(["route", "user"]),
  },
  created() {
    this.form.token = window.location.pathname.replace(this.route("password.store") + "/", "");
    const urlParams = new URLSearchParams(window.location.search);
    this.form.email = urlParams.get("email");
  },
  methods: {
    formSubmitted() {
      this.formErrors = {};
      this.resetPassword();
    },
    updateInput(newValue, $attribute) {
      this.form[$attribute] = newValue;
      this.formErrors = {};
    },
    async resetPassword() {
      this.submitting = true;
      let response = await fetch(this.route("password.store"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          ...this.form,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        window.location = data.redirectUrl;
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting = false;
      } else {
        await response.text();
      }
    },
  },
};
</script>
