<script setup>
import { getCurrentInstance, watch } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import { useStore } from "vuex";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "weight",
  }),
);
const props = defineProps({ initial: { type: [String, Number, Boolean], default: null } });
const option = getCurrentInstance().type;

const store = useStore();
const form = useOrderForm();

watch(store.state.order.calculation, ({ weight = props.initial }) => {
  if (form.getValue(option) === weight) {
    return;
  }

  form.updateItem({ option, value: weight });
});
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="float" />
</template>
