import { InfoFormNotification } from "../composables/errors";
import { getPaperFormat, paperRatioCorrect, translateSizeValue } from "../composables/helpers";
import { __ } from "../composables/lang";
import SizeHiddenOption from "../options/SizeHiddenOption.vue";

export default function () {
  return (form) => {
    if (!form.hasFileinfo()) {
      return;
    }

    const {
      doc: { clean_size = null, has_spreads = null, orientation = null } = {},
      page: { height = null, width = null } = {},
    } = form.getFileinfo();
    const articleSize = form.getValue(SizeHiddenOption);

    if (
      articleSize !== clean_size &&
      !["custom", "photoframe", "210x99"].includes(articleSize) &&
      has_spreads == 0
    ) {
      if (paperRatioCorrect(height, width) && window.constants["size"][articleSize]) {
        var paperFormat = getPaperFormat(Math.round(height), Math.round(width), orientation);
        throw new InfoFormNotification(
          __("Het document is als ") +
            paperFormat.value.toUpperCase() +
            __(" aangeleverd, maar wij kunnen dit zonder problemen schalen (passend maken) naar ") +
            translateSizeValue(articleSize) +
            ".",
        );
      } else {
        //
      }
    }
  };
}
