<script setup>
import { useStore } from "vuex";
import { removeSpaces, removeSpecialCharacters } from "../helpers";
import { __ } from "../order/composables/lang";
import InputField from "./InputField.vue";
import { computed, onBeforeMount, reactive, watch } from "vue";
import InfoDropdown from "./InfoDropdown.vue";
import Button from "./Button.vue";

const emit = defineEmits(["update:modelValue"]);
const store = useStore();

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
    required: true,
  },
  country: {
    type: String,
    default: "",
    required: true,
  },
});

const vatResponse = reactive({});

const validateVatNumber = async () => {
  let response = await fetch(
    store.getters.route("vat_number", { country: props.country, vatNumber: props.modelValue }),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    },
  );

  if (response.ok) {
    vatResponse.value = await response.json();
  }
};
const updateModelValue = (value) => {
  value = removeSpaces(value);
  value = removeSpecialCharacters(value);
  value = value.toUpperCase();

  vatResponse.value = null;

  emit("update:modelValue", value);
};

const vies_border = computed(() => {
  return {
    "!#border-[#EB980C]":
      vatResponse.value && vatResponse.value.error_text && vatResponse.value.allow_save,
    "!#border-[#d9534f]":
      vatResponse.value && vatResponse.value.error_text && !vatResponse.value.allow_save,
  };
});

const vies_text = computed(() => {
  return {
    "#text-[#EB980C]":
      vatResponse.value && vatResponse.value.error_text && vatResponse.value.allow_save,
    "#text-[#d9534f]":
      vatResponse.value && vatResponse.value.error_text && !vatResponse.value.allow_save,
  };
});

onBeforeMount(() => {
  validateVatNumber();
});

watch(
  () => props.country,
  (newValue) => {
    if (newValue) {
      validateVatNumber();
    }
  },
);
</script>
<template>
  <div>
    <InputField
      :model-value="modelValue"
      :label="__('BTW nummer')"
      type="text"
      :input-class="vies_border"
      @update:model-value="(value) => updateModelValue(value)"
      @blur="validateVatNumber"
    />
    <p
      v-if="vatResponse.value && vatResponse.value.error_text"
      class="#text-sm #font-semibold #mt-2 #max-w-[500px]"
      :class="vies_text"
    >
      {{ vatResponse.value.error_text }}
    </p>
    <InfoDropdown
      v-if="vatResponse.value && vatResponse.value.show_help"
      class="#mt-2"
      :dropdown-class="vies_text"
    >
      <template #info>
        <p class="#text-sm #mb-2 #mt-2">
          {{
            __(
              "Bij het invullen van het BTW nummer is het belangrijk dat deze voldoet aan alle eisen van de EU.",
            )
          }}
        </p>
        <p class="#text-sm">
          {{
            __("Is dat het geval? Neem dan contact op met de KVK om te controleren wat er mis is.")
          }}
        </p>
        <div class="#flex #flex-wrap #gap-y-[10px] #gap-x-[15px] #mt-6">
          <Button
            :text="__('BTW valideren')"
            target="_blank"
            rel="noopener noreferrer"
            href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
            size="xs"
            btn-class="grey"
          ></Button>
          <Button
            :text="__('Contact KVK')"
            href="https://www.kvk.nl/hulp-en-contact/"
            target="_blank"
            rel="noopener noreferrer"
            size="xs"
            btn-class="grey"
          ></Button>
        </div>
      </template>
    </InfoDropdown>
  </div>
</template>
