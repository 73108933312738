<script setup>
import OrderOption from "../classes/OrderOption";
import { useSetup } from "../composables/option";
import { useRadioOptionProps } from "../composables/props";
import UploaderField from "../fields/UploaderField.vue";

defineOptions(
  new OrderOption({
    key: "filenames",
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option } = useSetup(props);
</script>

<template>
  <UploaderField v-bind="$props" :option="option" />
</template>
