import { getCurrentInstance } from "vue";

export function useEmitter() {
  const app = getCurrentInstance();
  const emitter = app.appContext.config.globalProperties.emitter;

  return {
    on: emitter.on,
    off: emitter.off,
    emit: emitter.emit,
  };
}
