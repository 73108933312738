<script setup>
import { computed, useSlots } from "vue";

const slots = useSlots();

defineProps({
  title: {
    type: String,
    required: true,
  },
  divider: {
    type: Boolean,
    default: false,
  },
});

const activeSlots = computed(() => {
  return slots.default().filter((slot) => slot.children !== "v-if");
});

const findChoices = (slot) => {
  let choices = [];

  if (!slot.type.key || !slot.type.value) {
    return choices;
  }

  for (const s of activeSlots.value) {
    if (!s.type.key || !s.type.value || s.type.key !== slot.type.key) {
      continue;
    }
    choices.push(s.type.value);
  }

  return choices;
};
</script>
<template>
  <tbody>
    <component
      :is="slot"
      v-for="(slot, index) in activeSlots"
      :key="`slot_${index}`"
      :section="title"
      :divider="'divider' in (slot.props ?? {}) ? slot.props.divider : divider"
      :first="index === 0"
      :last="index + 1 === activeSlots.length"
      :choices="findChoices(slot)"
    />
  </tbody>
</template>
