<script setup>
import { ref, watch } from "vue";
import { useEmitter } from "../order/composables/emitter";
import { __ } from "../order/composables/lang";
import SvgUse from "./SvgUse.vue";
import InputToggle from "./InputToggle.vue";
import InputField from "./InputField.vue";
import Button from "./Button.vue";
import SlideIn from "./SlideIn.vue";

const { emit } = useEmitter();
defineEmits(["submit"]);

const props = defineProps({
  infoItem: {
    type: Object,
    default: () => {},
    required: false,
  },
  payOnAccount: {
    type: Number,
    required: true,
  },
  payOnAccountRequested: {
    type: Boolean,
    required: true,
  },
  tempAccountPayment: {
    type: Boolean,
    required: true,
  },
  company: {
    type: String,
    required: true,
  },
  submitting: {
    type: Boolean,
    required: true,
  },
  message: {
    type: [String, null],
    required: true,
  },
  kvkError: {
    type: String,
    required: false,
    default: "",
  },
  kvkWarning: {
    type: String,
    required: false,
    default: "",
  },
});

const container = ref(null);

const openPopupNew = function () {
  emit("open-info-popup", {
    title: props.infoItem.title,
    info: props.infoItem.info,
    placement: "left",
    element: container.value,
  });
};
const closePopupNew = function () {
  emit("close-info-popup");
};
const stayPopup = function () {
  emit("stay-info-popup", {
    title: props.infoItem.title,
    info: props.infoItem.info,
    placement: "left",
    element: container.value,
  });
};

const payOnAccountRequesting = ref(false);

const kvk_number = defineModel("kvk_number", { type: [String, null], required: true });

watch(
  () => props.payOnAccountRequested,
  () => {
    payOnAccountRequesting.value = false;
  },
);
</script>
<template>
  <div>
    <div class="#group #flex #items-center #justify-between #gap-x-8 #w-full">
      <div
        ref="container"
        class="#flex #min-w-0 #gap-x-4 #items-center #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
      >
        <h3
          class="#inline #min-w-0 #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
        >
          {{ __("Bestellen op rekening") }}
        </h3>
        <div
          v-if="infoItem?.title && infoItem?.info"
          ref="orderonaccounticon"
          class="fonticon"
          @mouseenter="() => openPopupNew()"
          @mouseleave="() => closePopupNew()"
          @click="() => stayPopup()"
        >
          <svg-use
            id="circle-info"
            type="solid"
            class="#h-[1em] #mt-2 md:#hidden md:group-hover:#block #block #transition-all hover:#fill-brightcyan #cursor-pointer #fill-[#a8a8a8] #w-8"
          />
        </div>
      </div>

      <div
        v-if="payOnAccount >= 1"
        class="#bg-emerald-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
      >
        <p class="#text-sm #font-bold #text-[#2CD11A] #min-w-max">
          {{ __("actief") }}
        </p>
      </div>

      <div
        v-else-if="payOnAccount === -1"
        class="#bg-red-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
      >
        <p class="#text-sm #font-bold #text-[#FF0000] #min-w-max">
          {{ __("geblokkeerd") }}
        </p>
      </div>

      <div
        v-else-if="payOnAccountRequested"
        class="#bg-amber-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
      >
        <p class="#text-sm #font-bold #text-[#eb980c] #min-w-max">
          {{ __("aangevraagd") }}
        </p>
      </div>

      <div
        v-else-if="tempAccountPayment"
        class="#bg-amber-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
      >
        <p class="#text-sm #font-bold #text-[#eb980c] #min-w-max">
          {{ __("tijdelijk") }}
        </p>
      </div>

      <InputToggle
        v-else-if="payOnAccount === 0"
        v-model="payOnAccountRequesting"
        :disabled="submitting"
      />

      <div v-else class="#bg-inkdroplight #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1">
        <p class="#text-sm #font-bold #text-inkdropdark #min-w-max">
          {{ __("niet actief") }}
        </p>
      </div>
    </div>
    <SlideIn @enter="() => $refs.kvkNumber.focus()">
      <div
        v-if="payOnAccountRequesting && !payOnAccountRequested"
        class="#flex #items-start #gap-x-[15px] #my-[15px]"
      >
        <InputField
          ref="kvkNumber"
          v-model="kvk_number"
          :label="__('KVK nummer')"
          type="text"
          class="#w-full"
          :error="kvkError"
          :warning="kvkWarning"
        ></InputField>
        <Button
          :text="__('Versturen')"
          btn-class="primary"
          :disabled="submitting"
          :class="{
            '#opacity-50 #pointer-events-none': kvkWarning || !company,
          }"
          @click="$emit('submit')"
        ></Button>
      </div>
    </SlideIn>
    <div
      v-if="message"
      ref="errorMessages"
      class="#min-h-[63px] max-md:#fixed max-md:#w-screen max-md:#left-0 max-md:#bottom-0 max-md:#bg-white max-md:#z-10 #flex #items-center #justify-start max-md:#justify-center max-md:#py-[20px] max-md:#shadow-focus"
    >
      <p
        class="#text-center #text-sm md:#text-base md:#text-left #font-bold #text-[#2CD11A] md:#mt-6"
      >
        {{ message }}
      </p>
    </div>
  </div>
</template>
