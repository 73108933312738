<script setup>
import { __ } from "../composables/lang";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import OrderOption from "../classes/OrderOption";
import { computed } from "vue";
import { useOrderForm } from "../../modules/orderModule";
import CopiesHiddenOption from "./CopiesHiddenOption.vue";
import { FatalFormError } from "../composables/errors";

defineOptions(
  new OrderOption({
    key: "printside",
    value: "single",
    label: __("enkelzijdig"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps({
  ...useRadioOptionProps(),
  disableIfTwoCopies: {
    type: Boolean,
    default: false,
  },
});
const { option, check, disabled: cms_disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));

if (props.disableIfTwoCopies) {
  check((option, form) => {
    if (!option.isChecked()) {
      return;
    }

    if (form.getValue(CopiesHiddenOption) === 2) {
      throw new FatalFormError(
        __("Er is gekozen voor enkelzijdig en er zijn 2 documenten geupload. Er kan 1 soort flyer geupload worden. Kies voor dubbelzijdig printen voor een bedrukte voor- en achterzijde of plaats meerdere artikelen in je winkelmandje."),
      );
    }
  });
}

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }

  if (props.disableIfTwoCopies) {
    return form.getValue(CopiesHiddenOption) === 2;
  } else {
    return false;
  }
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
