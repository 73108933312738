import { InfoFormWarning } from "../composables/errors";
import { __ } from "../composables/lang";
import BorderlessOption from "../options/BorderlessOption.vue";
import FileHasFrameOption from "../options/FileHasFrameOption.vue";

export default function () {
  return (form) => {
    if (!form.hasFileinfo()) {
      return;
    }

    if (form.getValue(FileHasFrameOption) == true && form.isChecked(BorderlessOption)) {
      throw new InfoFormWarning(
        __("Er is een kader in je document gevonden. Dit kan tot gevolg hebben dat de randen ongelijk zijn. Zie {cta}."),
        {
          cta: [
            {
              text: "aanlevervoorwaarden",
              fn: () => {
                form.selectItem(BorderlessOption);
                form.touchItem({ option: BorderlessOption });
              },
            },
          ],
        },
      );
    }
  };
}
