<script setup>
import { ref, useTemplateRef } from "vue";
import EmailField from "./EmailField.vue";
import LabeledInput from "./LabeledInput.vue";
import SlideIn from "./SlideIn.vue";
import { __ } from "../order/composables/lang";
import { watch } from "vue";

const model = defineModel({ type: String, required: true });
const showInvoiceMail = ref(false);
const copyInvoice = useTemplateRef("copyInvoice");

const props = defineProps({
  popup: {
    type: Object,
    default: () => {},
  },
  error: {
    type: String,
    required: false,
    default: "",
  },
});

watch(showInvoiceMail, (newValue) => {
  if (newValue === false) {
    model.value = "";
  } else {
    copyInvoice.value.focus();
  }
});
</script>

<template>
  <div class="#space-y-[15px]">
    <LabeledInput
      v-model="showInvoiceMail"
      :title="__('Apart factuur e-mailadres')"
      :popup="popup"
    ></LabeledInput>
    <SlideIn>
      <EmailField
        v-show="showInvoiceMail"
        ref="copyInvoice"
        v-model="model"
        :error="error"
      ></EmailField>
    </SlideIn>
  </div>
</template>
