<script setup>
// TODO: preview images laden tijdens scrollen

import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import { __ } from "./composables/lang";
import { useOrderForm } from "../modules/orderModule";
import FileNamesOption from "./options/FileNamesOption.vue";
import AnalyzingMessage from "./messages/AnalyzingMessage.vue";
import { useEmitter } from "./composables/emitter";

const store = useStore();
const form = useOrderForm();
const { on, off } = useEmitter();

const changesMade = ref(false);
const searchTerm = ref("");

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const draggingFile = ref(null);
const draggingIndex = ref(null);
const targetIndex = ref(null);
const hovering = ref(null);

const preventDefaults = (event) => {
  event.preventDefault();
};

const events = ["dragenter", "dragover", "dragleave", "drop"];
onMounted(() => {
  events.forEach((eventName) => {
    document.addEventListener(eventName, preventDefaults);
  });
  on("file-upload", (file) => {
    handleFileUpload(file);
  });
});

onUnmounted(() => {
  events.forEach((eventName) => {
    document.removeEventListener(eventName, preventDefaults);
  });
  off("file-upload");
});

watch(
  () => props.open,
  (open) => {
    if (open && changesMade.value) {
      changesMade.value = false;
    }
    if (!open && changesMade.value && !form.isUploading()) {
      form.touchItem({ option: FileNamesOption });
    }
  },
);

const selectedFileNames = computed(() => {
  let filenames = store.state.order.form.filenames ?? "";

  return filenames.split("/").filter((i) => i);
});

const allFiles = computed(() => {
  let sortedFiles = [...store.state.order.files].sort((a, b) => {
    if (selectedFileNames.value.includes(a.name) && selectedFileNames.value.includes(b.name)) {
      let indexA = selectedFileNames.value.indexOf(a.name);
      let indexB = selectedFileNames.value.indexOf(b.name);

      if (indexA > indexB) {
        return 1;
      }
      if (indexA < indexB) {
        return -1;
      }

      return 0;
    }
    if (!selectedFileNames.value.includes(a.name) && selectedFileNames.value.includes(b.name)) {
      return 1;
    }
    if (selectedFileNames.value.includes(a.name) && !selectedFileNames.value.includes(b.name)) {
      return -1;
    }

    if (a.filemtime < b.filemtime) {
      return 1;
    }
    if (a.filemtime > b.filemtime) {
      return -1;
    }

    return 0;
  });

  if (draggingIndex.value === null || targetIndex.value === null) {
    return sortedFiles;
  }

  const reordered = [...sortedFiles];
  const [draggedItem] = reordered.splice(draggingIndex.value, 1);
  reordered.splice(targetIndex.value, 0, draggedItem);

  return reordered;
});

const showUploadRow = computed(() => {
  if (selectedFileNames.value.length > 1) {
    return false;
  }

  if (selectedFileNames.value.length === 1 && hovering.value) {
    return draggingFile.value.name === selectedFileNames.value[0];
  }

  return selectedFileNames.value.length === 0;
});

const totalSize = computed(() => {
  if (store.state.order.fileProgress.length === 0) {
    return 0;
  }

  return store.state.order.fileProgress.reduce((acc, progress) => {
    if (progress.status !== "uploading") {
      return acc;
    }

    return acc + progress.total;
  }, 0);
});

const totalProgressSize = computed(() => {
  if (store.state.order.fileProgress.length === 0) {
    return 0;
  }

  return store.state.order.fileProgress.reduce((acc, progress) => {
    if (progress.status !== "uploading") {
      return acc;
    }

    return acc + progress.loaded;
  }, 0);
});

const totalProgress = computed(() => {
  if (totalSize.value === 0 || totalProgressSize.value === 0) {
    return 0;
  }

  return ((totalProgressSize.value / totalSize.value) * 100).toFixed(2);
});

const rowStyles = (file, index, source) => {
  let classes = {};

  if (index === targetIndex.value) {
    classes["background-color"] = "#ddd";
  } else if (hovering.value === source) {
    classes["background-color"] = "#d9edf7";
  }

  return classes;
};

const calcProgress = (progress) => {
  if (progress.total === 0) {
    return 0;
  }

  return ((progress.loaded / progress.total) * 100).toFixed(2);
};

const formatFileSize = (bytes) => {
  if (typeof bytes !== "number") {
    return "";
  }
  if (bytes >= 1000000000) {
    return (bytes / 1000000000).toFixed(1) + " GB";
  }
  if (bytes >= 1000000) {
    return (bytes / 1000000).toFixed(1) + " MB";
  }
  return (bytes / 1000).toFixed(1) + " KB";
};

const showRow = (file, source) => {
  if (hovering.value && file.unique_id === draggingFile.value?.unique_id) {
    return hovering.value === source;
  }

  if (source === "saved") {
    if (selectedFileNames.value.includes(file.name)) {
      return false;
    }

    return searchTerm.value ? file.name.includes(searchTerm.value) : true;
  }
  if (source === "selected") {
    return selectedFileNames.value.includes(file.name);
  }

  throw new Error("unknown file source");
};

const onDragStart = (file, index, source, event) => {
  event.dataTransfer.dropEffect = "move";
  event.dataTransfer.effectAllowed = "move";

  setTimeout(() => {
    draggingIndex.value = index;
    hovering.value = source;
    draggingFile.value = file;
  }, 1);
};

const onDragOver = (file, index, source) => {
  if (draggingFile.value === null) {
    return;
  }

  hovering.value = source;
  targetIndex.value = index;
};

const onUploaderDragOver = () => {
  hovering.value = "selected";
  if (draggingFile.value) {
    targetIndex.value = 0;
  }
};

const onDragEnd = () => {
  hovering.value = null;
  draggingIndex.value = null;
  targetIndex.value = null;
  draggingFile.value = null;
};

const onDrop = (target, event) => {
  if (target === "selected") {
    if (draggingFile.value === null) {
      for (const file of event.dataTransfer.files) {
        handleFileUpload(file);
      }
      hovering.value = null;
    } else {
      addToSelected(draggingFile.value);
    }
  } else if (target === "saved") {
    if (draggingFile.value !== null) {
      removeFromSelected(draggingFile.value);
    }
  }
};

const addToSelected = (fileToAdd) => {
  const selectedFiles = allFiles.value.filter((file) => {
    if (selectedFileNames.value.includes(file.name)) {
      return true;
    }
    if (file.unique_id === fileToAdd.unique_id) {
      return true;
    }

    return false;
  });
  const fileNames = selectedFiles
    .map((file) => {
      return file.name;
    })
    .join("/");

  form.updateItem({
    option: FileNamesOption,
    value: fileNames,
  });
  changesMade.value = true;
};

const removeFromSelected = (fileToRemove) => {
  if (!selectedFileNames.value.includes(fileToRemove.name)) {
    return;
  }

  const selectedFiles = allFiles.value.filter((file) => {
    if (selectedFileNames.value.includes(file.name)) {
      return true;
    }

    return false;
  });

  const fileNames = selectedFiles
    .filter((file) => {
      return file.unique_id !== fileToRemove.unique_id;
    })
    .map((file) => {
      return file.name;
    })
    .join("/");

  form.updateItem({
    option: FileNamesOption,
    value: fileNames,
  });
  changesMade.value = true;
};

const onFilesChange = (event) => {
  for (let i = 0; i < event.target.files.length; i++) {
    const file = event.target.files[i];
    handleFileUpload(file);
  }

  event.target.value = null;
};

const handleFileUpload = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const xhr = new XMLHttpRequest();
  xhr.open("POST", store.getters.route("files.upload"), true);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  xhr.upload.addEventListener("loadstart", () => {
    console.log(`Uploading ${file.name}`);
    form.setFileProgress({
      name: file.name,
      loaded: 0,
      total: file.size,
      status: "uploading",
    });
  });

  xhr.upload.addEventListener("progress", (event) => {
    if (event.lengthComputable) {
      form.setFileProgress({
        name: file.name,
        loaded: event.loaded,
        total: event.total,
        status: "uploading",
      });
    }
  });

  xhr.upload.addEventListener("load", () => {
    console.log(`Upload finished for ${file.name}`);
    form.setFileProgress({
      name: file.name,
      status: "processing",
    });
  });

  const errorAction = (event) => {
    console.log(`Upload failed for ${file.name}: ${event.type}`);
    form.setFileProgress({
      name: file.name,
      status: "error",
      error: __("Er heeft een onbekende fout plaatsgevonden"),
    });
  };

  xhr.upload.addEventListener("error", errorAction);
  xhr.upload.addEventListener("abort", errorAction);
  xhr.upload.addEventListener("timeout", errorAction);
  xhr.addEventListener("error", errorAction);
  xhr.addEventListener("abort", errorAction);

  xhr.addEventListener("load", () => {
    form.setFileProgress({
      name: file.name,
      status: "finished",
    });
    const response = JSON.parse(xhr.responseText);
    if (xhr.status === 200 && response.unique_id) {
      form.addFile(response);
      addToSelected(response);
      form.clearFileProgress(file.name);
    } else if (xhr.status === 422) {
      form.setFileProgress({
        name: file.name,
        status: "error",
        error: response.errors.file[0],
      });
    } else {
      form.setFileProgress({
        name: file.name,
        status: "error",
        error: __("Er heeft een onbekende fout plaatsgevonden"),
      });
    }
  });

  xhr.send(formData);
};

const deleteFile = async (file) => {
  if (!confirm("Weet je zeker dat je dit bestand wilt verwijderen?")) {
    return;
  }

  try {
    const response = await fetch(store.getters.route("files.delete"), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        filename: file.name,
      }),
    });
    if (response.ok) {
      await response.json();
      form.removeFile(file.name);
    } else if (response.status === 422) {
      const data = await response.json();
      alert(data.message);
    } else {
      await response.text();
    }
  } catch (error) {
    console.error(error);
  }
};
</script>

<template>
  <div id="fileupload" style="margin-top: 20px">
    <div class="row fileupload-buttonbar" style="">
      <div class="col-lg-7">
        <span class="btn btn-secondary fileinput-button tailwind">
          <svg-use id="plus" type="solid" class="#w-[17px] #h-[17px] #fill-white" />
          <span>{{ __("Upload bestand(en)") }}</span>
          <input type="file" name="files[]" multiple="" translate="no" @change="onFilesChange" />
        </span>
        <span class="fileupload-process"></span>
      </div>
      <div class="col-lg-5 fileupload-progress fade in">
        <div
          class="progress active"
          style="float: left; width: 27%; margin-right: 3%; margin-top: 1%"
        >
          <div class="progress-bar" style="width: 100%"></div>
        </div>
        <div
          v-if="totalProgress"
          class="progress-extended"
          style="float: right; width: 65%; margin-top: 1%"
        >
          <span v-if="store.state.order.fileProgress.length > 1">{{ totalProgress }}% | </span>
          <span>{{ formatFileSize(totalProgressSize) }} / {{ formatFileSize(totalSize) }}</span>
        </div>
      </div>
    </div>
    <div class="errors errors_controle" style="display: block">
      <AnalyzingMessage v-if="form.isAnalyzing()" />
      <div
        v-if="selectedFileNames.length > 1"
        id="multi_file"
        class="error_4"
        style="display: block"
      >
        <img src="/legacy/img/icon-verplaatst.png" style="float: left; margin-right: 2%" />
        <h4 style="font-size: 18px; margin: 0">{{ __("Informatie:") }}</h4>
        <p>
          {{
            __(
              "Je hebt meerdere bestanden geselecteerd. Sleep de bestanden in de gewenste volgorde om de volgorde van printen te bepalen.",
            )
          }}
        </p>
      </div>
    </div>

    <table class="table document_list" style="">
      <thead>
        <tr>
          <th colspan="5">{{ __("Geselecteerde documenten") }}</th>
          <th colspan="5"></th>
        </tr>
      </thead>
      <tbody class="selected_files" @drop="(event) => onDrop('selected', event)">
        <tr
          v-if="showUploadRow"
          class="template-download"
          :style="{ backgroundColor: hovering === 'selected' ? '#d9edf7' : null }"
          draggable="false"
          @dragover="onUploaderDragOver"
        >
          <td colspan="5">
            <div class="empty-box">
              <svg viewBox="-49 141 512 512" style="height: 36px; fill: #787878">
                <g>
                  <path
                    d="M36.03,362.25v98.67c0,33.64,27.36,61,61,61H335.7c33.64,0,61-27.36,61-61v-98.67c0,0,0-5-10.41-5c-11.5,0-11.59,5-11.59,5
                        v98.67c0,21.5-17.5,39-39,39H97.03c-21.5,0-39-17.5-39-39v-98.67c0,0,0.93-5-10.57-5C36.03,357.25,36.03,362.25,36.03,362.25z"
                  ></path>
                  <path d="M216.36,421.7"></path>
                  <path
                    d="M299.42,347.56c-8.08-8.08-11.62-4.55-11.62-4.55l-60.44,60.44V257.58c0,0,0.93-5-10.57-5c-11.43,0-11.43,5-11.43,5v145.86
                        l-60.43-60.43c0,0-3.54-3.54-11.62,4.55c-8.13,8.13-3.94,11.01-3.94,11.01l80.04,79.62c1.97,1.97,4.35,2.95,7.16,2.95
                        s5.2-0.98,7.16-2.95l79.62-79.62C303.36,358.57,307.55,355.69,299.42,347.56z"
                  ></path>
                </g>
              </svg>
            </div>
          </td>
        </tr>
        <template
          v-for="(progress, index) in store.state.order.fileProgress"
          :key="`uploading_${index}`"
        >
          <tr id="doc_0" class="template-upload" style="min-height: 60px">
            <td width="1%" style="padding-left: 10px; padding-right: 0px; background: none"></td>
            <td width="7%"></td>
            <td width="75%">
              <p class="name">
                <span class="filename" translate="no">
                  {{ progress.name }}
                </span>
                <template v-if="progress.status === 'error'">
                  <br />
                  <span class="error text-danger" v-html="progress.error"> </span>
                </template>
              </p>
              <div
                v-if="
                  progress.status === 'processing' && !progress.name.toLowerCase().endsWith('pdf')
                "
                class="waiting-box"
              >
                <div class="spinner" style="display: block">
                  <div class="rect1"></div>
                  <div class="rect2"></div>
                  <div class="rect3"></div>
                </div>
                <span>{{ __("Bezig met omzetten naar PDF") }}</span>
              </div>
            </td>
            <td width="8%" class="status" style="padding-top: 12px">
              <div v-if="progress.status === 'uploading'">{{ calcProgress(progress) }}%</div>
              <div v-else-if="progress.status === 'processing'">{{ __("verwerken") }}</div>
            </td>
            <td width="9%" class="tailwind">
              <a
                href="javascript:;"
                class="btn-icon tailwind"
                style="float: right"
                @click.stop="() => form.clearFileProgress(progress.name)"
              >
                <svg-use
                  v-if="progress.status === 'error'"
                  id="octagon-xmark"
                  type="regular"
                  class="#w-[20px] #h-[20px] #fill-[#636363]"
                />
              </a>
            </td>
          </tr>
        </template>

        <template v-for="(file, index) in allFiles" :key="`selected_${file.unique_id}`">
          <tr
            v-if="showRow(file, 'selected')"
            class="template-download"
            :style="rowStyles(file, index, 'selected')"
            draggable="true"
            @dragstart="onDragStart(file, index, 'selected', $event)"
            @dragover="onDragOver(file, index, 'selected')"
            @dragend="onDragEnd"
          >
            <td width="1%" style="padding-left: 10px; padding-right: 0px; background: none"></td>
            <td width="7%">
              <div>
                <span class="preview">
                  <a
                    :title="file.name"
                    rel="lightbox[]"
                    style="float: left"
                    :href="file.preview_link"
                  >
                    <img
                      style="z-index: 200; max-height: 60px; max-width: 60px"
                      loading="lazy"
                      :src="file.preview_link"
                    />
                  </a>
                </span>
              </div>
            </td>
            <td width="75%">
              <p class="name">
                <a :href="file.download_link" target="_blank" rel="noopener noreferrer">
                  <span class="filename" translate="no">
                    {{ file.name }}
                  </span>
                </a>
              </p>
              <p class="datetime">{{ file.datetime }}</p>
            </td>
            <td width="8%" class="status" style="padding-top: 12px">
              <span class="size">
                {{ formatFileSize(file.size) }}
              </span>
            </td>
            <td width="9%" class="tailwind">
              <a
                href="javascript:;"
                class="btn-icon tailwind"
                style="float: right"
                @click.stop="removeFromSelected(file)"
              >
                <svg-use id="xmark" type="solid" class="#w-[20px] #h-[20px] #fill-[#636363]" />
              </a>
            </td>
          </tr>
        </template>
      </tbody>

      <!-- Uploaded files -->
      <thead class="files-head" style="display: table-header-group">
        <tr>
          <th colspan="5">
            <span>{{ __("Opgeslagen documenten") }}</span>
            <input
              id="file_search_input"
              v-model="searchTerm"
              type="input"
              class="form-control form-control-search"
              :placeholder="__('Zoek bestand')"
              style="display: inline-block"
            />
          </th>
        </tr>
      </thead>
      <tbody class="files" @drop="(event) => onDrop('saved', event)">
        <template v-for="(file, index) in allFiles" :key="`saved_${file.unique_id}`">
          <tr
            v-if="showRow(file, 'saved')"
            class="template-download"
            :style="rowStyles(file, index, 'saved')"
            draggable="true"
            @dragstart="onDragStart(file, index, 'saved', $event)"
            @dragover="onDragOver(file, index, 'saved')"
            @dragend="onDragEnd"
          >
            <td width="1%" style="padding-left: 10px; padding-right: 0px; background: none"></td>
            <td width="7%">
              <div>
                <span class="preview">
                  <a
                    :title="file.name"
                    rel="lightbox[]"
                    style="float: left"
                    :href="file.preview_link"
                  >
                    <img
                      style="z-index: 200; max-height: 60px; max-width: 60px"
                      loading="lazy"
                      :src="file.preview_link"
                    />
                  </a>
                </span>
              </div>
            </td>
            <td width="75%">
              <p class="name">
                <a :href="file.download_link" target="_blank" rel="noopener noreferrer">
                  <span class="filename" translate="no">
                    {{ file.name }}
                  </span>
                </a>
              </p>
              <p class="datetime">{{ file.datetime }}</p>
            </td>
            <td width="8%" class="status" style="padding-top: 12px">
              <span class="size">
                {{ formatFileSize(file.size) }}
              </span>
            </td>
            <td width="calc(9%-7px)" class="tailwind">
              <a
                href="javascript:;"
                class="btn-icon tailwind"
                style="float: right"
                @click.stop="deleteFile(file)"
              >
                <svg-use id="trash" type="solid" class="#w-[17px] #h-[17px] #fill-[#636363]" />
              </a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
