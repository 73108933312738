<script setup>
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { computed } from "vue";
import TopcoatNoneOption from "./TopcoatNoneOption.vue";
import { watch } from "vue";
import { useSetup } from "../composables/option";
import { isOneOf, isRequiredIfOther } from "../checks/basic";
import TopcoatOption from "./TopcoatOption.vue";
import { useOrderForm } from "../../modules/orderModule";
import { __ } from "../composables/lang";
import OrderOption from "../classes/OrderOption";
import { FatalFormError } from "../composables/errors";
import PrintSideDoubleOption from "./PrintSideDoubleOption.vue";
import PrintSideSingleOption from "./PrintSideSingleOption.vue";

defineOptions(
  new OrderOption({
    key: "coating",
    value: "double",
    label: __("voor- en achterkant van ieder vel"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

const hidden = computed(() => {
  return form.isChecked(TopcoatNoneOption);
});

watch(
  hidden,
  (topcoatHidden) => {
    if (topcoatHidden && form.isChecked(option)) {
      form.deleteItem(option);
      form.touchItem({ option, programmatically: true });
    } else if (!topcoatHidden && props.initial) {
      form.selectItem(option);
      form.touchItem({ option, programmatically: true });
    }
  },
  { immediate: true },
);

check(isRequiredIfOther(TopcoatOption));
check(isOneOf(props.choices));
check((option, form) => {
  if (
    option.isChecked() &&
    form.isChecked(TopcoatOption) &&
    form.isChecked(PrintSideSingleOption)
  ) {
    throw new FatalFormError(__("Coating aan beide kanten is alleen mogelijk bij {cta} printen."), {
      cta: [
        {
          text: __("dubbelzijdig"),
          fn: () => {
            form.selectItem(PrintSideDoubleOption);
            form.touchItem({ option: PrintSideDoubleOption });
          },
        },
      ],
    });
  }
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :hidden="hidden"
    :option="option"
  />
</template>
