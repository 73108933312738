export function round(num, places) {
  return +(Math.round(num + "e+" + places) + "e-" + places);
}

export function cleanPhone(value) {
  value = value.replace("(0)", "");
  value = value.replace("(", "");
  value = value.replace(")", "");
  value = value.replace(" ", "");
  if (value.startsWith("0")) {
    value = "+31" + value.substring(1);
  }

  return value;
}

export function debounce(func, wait, immediate = false) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function capitalize([first, ...rest]) {
  return first.toUpperCase() + rest.join("");
}

export function removeSpaces(value) {
  value = value.replace(/\s/g, "");
  return value;
}

export function removeLetters(value) {
  value = value.replace(/[^\d\-+\s]+/g, "");
  return value;
}

export function removeSpecialCharacters(value) {
  value = value.replace(/[^a-zA-Z0-9\s]/g, "");
  return value;
}

export function removeNotNumbers(value) {
  value = value.replace(/[^0-9]/g, "");
  return value;
}
