import camelCase from "lodash.camelcase";

export default class OrderOption {
  _id;
  _key;
  _type;
  _value;
  _label;
  _showPriceDiff;
  _updateUrl;
  _excludeFromSave;
  _difference = 0;
  _touched = false;

  constructor({
    key,
    value,
    label,
    showPriceDiff = false,
    updateUrl = false,
    excludeFromSave = false,
  }) {
    this._id = Symbol.for(camelCase(key + (value ? "-" + value : "")));
    this._key = key;
    this._value = value;
    this._label = label;
    this._showPriceDiff = showPriceDiff;
    this._updateUrl = updateUrl;
    this._excludeFromSave = excludeFromSave;
  }

  set type(value) {
    if (!["checkbox", "hidden", "integer", "radio", "textarea", "select"].includes(value)) {
      throw new Error(`Invalid OrderOption type: ${value}`);
    }

    this._type = value;
  }

  set difference(value) {
    if (typeof value !== "number") {
      throw new Error("OrderOption difference must be a number");
    }

    this._difference = value;
  }

  set touched(value) {
    if (typeof value !== "number") {
      throw new Error("OrderOption touched must be a number");
    }

    this._touched = value;
  }

  get id() {
    return this._id;
  }

  get type() {
    return this._type;
  }

  get key() {
    return this._key;
  }

  get value() {
    return this._value;
  }

  get label() {
    return this._label;
  }

  get showPriceDiff() {
    return this._showPriceDiff;
  }

  get updateUrl() {
    return this._updateUrl;
  }

  get excludeFromSave() {
    return this._excludeFromSave;
  }

  get difference() {
    return this._difference;
  }

  get touched() {
    return this._touched;
  }
}
