<script setup>
import { computed, ref } from "vue";
import SvgUse from "../components/SvgUse.vue";
import { useOrderForm } from "../modules/orderModule";
import { __ } from "./composables/lang";

const form = useOrderForm();
const openRow = ref(null);

const titles = {
  file: __("Bestandsformaat"),
  size: __("Afmeting"),
  cutting: __("Snijden"),
  borderless: __("Randloos"),
  text_margin: __("Tekst marge"),
  border: __("Kaders"),
  finishing2: __("Afwerking"),
  layout: __("Indeling"),
  resolution: __("Resolutie"),
  fonts: __("Lettertypen"),
  check_doc: __("Bestandscontrole"),
  periodic: __("Periodieke uitgave"),
  "text margin": __("Tekstmarge"),
};

const delivery_conditions = computed(() => {
  const { delivery_conditions: conditions = {} } = form.getCalculation();

  return Object.fromEntries(
    Object.entries(conditions).filter(function ([key, value]) {
      if (!(key in titles)) {
        console.error(`Er is geen titel voor ${key}`);
        return false;
      }

      return value !== null;
    }),
  );
});

const toggleDetails = (row) => {
  if (row === openRow.value) {
    openRow.value = null;
  } else {
    openRow.value = row;
  }
};
</script>

<template>
  <ul class="extra_info_list delivery_conditions">
    <li v-for="(option, key) of delivery_conditions" :key="option" class="item">
      <a class="extra_link" href="javascript:;" @click="toggleDetails(key)">
        {{ titles[key] }}
        <span class="extra_angle-down tailwind">
          <svg-use
            id="angle-down"
            type="solid"
            class="#w-[11px] #h-[11px] #fill-current #inline"
            :class="{ '#rotate-180': openRow === key }"
          />
        </span>
      </a>
      <div class="deliveryconditions_extrainfo" :class="{ showconditions: openRow === key }">
        <div class="overflow-hidden">
          <p v-html="option"></p>
        </div>
      </div>
    </li>
  </ul>
</template>

<style>
.deliveryconditions_extrainfo {
  display: grid;
  grid-template-rows: 0fr;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.deliveryconditions_extrainfo.showconditions {
  grid-template-rows: 1fr;
}
</style>
