<script setup>
import { removeSpaces, removeSpecialCharacters } from "../helpers";
import InputField from "./InputField.vue";

const emit = defineEmits(["update:modelValue"]);

const updateModelValue = function (value) {
  value = removeSpaces(value);
  value = removeSpecialCharacters(value);
  value = value.toUpperCase();

  emit("update:modelValue", value);
};

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
</script>
<template>
  <InputField :model-value="modelValue" @update:model-value="(value) => updateModelValue(value)">
    <slot></slot
  ></InputField>
</template>
