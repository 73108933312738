<script setup>
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { computed, watch } from "vue";
import TopcoatNoneOption from "./TopcoatNoneOption.vue";
import { useSetup } from "../composables/option";
import TopcoatOption from "./TopcoatOption.vue";
import { useOrderForm } from "../../modules/orderModule";
import { __ } from "../composables/lang";
import OrderOption from "../classes/OrderOption";
import { isOneOf, isRequiredIfOther } from "../checks/basic";

defineOptions(
  new OrderOption({
    key: "coating",
    value: "front",
    label: __("voorkant"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

const hidden = computed(() => {
  return form.isChecked(TopcoatNoneOption);
});

watch(
  hidden,
  (topcoatHidden) => {
    if (topcoatHidden && form.isChecked(option)) {
      form.deleteItem(option);
      form.touchItem({ option, programmatically: true });
    } else if (!topcoatHidden && props.initial) {
      form.selectItem(option);
      form.touchItem({ option, programmatically: true });
    }
  },
  { immediate: true },
);

check(isRequiredIfOther(TopcoatOption));
check(isOneOf(props.choices));
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :initial="false"
    :hidden="hidden"
    :option="option"
  />
</template>
