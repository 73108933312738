<script setup>
import { computed, ref } from "vue";
import { useDirectives } from "./composables/directives";
import * as info from "./info";

const active = ref(null);
const { vOutsideClick } = useDirectives();

const items = computed(() => {
  let source = null;

  if (import.meta.env.SSR) {
    source = global.order_settings;
  } else {
    source = window.order_settings;
  }

  return source.order_info;
});

const findComponent = (item) => {
  for (const [name, entry] of Object.entries(info)) {
    if (item.type.value === name) {
      return entry;
    }
  }

  throw new Error("Unknown order info item " + item.type.value);
};

const onChange = (id) => {
  if (active.value === id) {
    active.value = null;
  } else {
    active.value = id;
  }
};
</script>

<template>
  <div class="box">
    <h4 class="widget-title">Extra info</h4>
    <ul v-outside-click="() => (active = null)" class="media-list">
      <component
        :is="findComponent(item)"
        v-for="item in items"
        :id="item.id"
        :key="`item_${item.id}`"
        :title="item.title"
        :content="item.content.value"
        :instance="item"
        :active="active"
        @change="onChange"
      />
    </ul>
  </div>
</template>
