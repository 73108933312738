<script setup>
import { ref, watch } from "vue";
import { computed } from "vue";
import { useOrderForm } from "../../modules/orderModule";
import { useTextAreaOptionProps } from "../composables/props";
import { capitalize } from "../../helpers";
import OrderOption from "../classes/OrderOption";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  placeholder: {
    type: String,
    default: "",
  },
  max: {
    type: Number,
    default: null,
  },
  ...useTextAreaOptionProps(),
});

const form = useOrderForm();

const id = computed(() => {
  return Symbol.keyFor(props.option.id);
});

form.addOption({
  option: props.option,
  type: "textarea",
});

const preloaded = import.meta.env.SSR
  ? global.preload[props.option.key]
  : window.preload[props.option.key];

const urlParamsObject = import.meta.env.SSR
  ? {}
  : Object.fromEntries(new URLSearchParams(window.location.search).entries());

if (preloaded && !form.hasValue(props.option)) {
  form.updateItem({
    option: props.option,
    value: preloaded,
  });
  form.touchItem({ option: props.option, programmatically: true });
} else if (!form.isEditing() && urlParamsObject[props.option.key]) {
  form.updateItem({
    option: props.option,
    value: urlParamsObject[props.option.key],
  });
} else if (!form.isEditing() && urlParamsObject[props.option.key] === undefined && props.initial) {
  form.updateItem({
    option: props.option,
    value: props.initial,
  });
}

const update = (event) => {
  const val = event.target.value;
  if (val && props.max && val.length > props.max) {
    form.updateItem({
      option: props.option,
      value: val.slice(0, props.max),
    });
    event.target.value = val.slice(0, props.max);
  } else if (val) {
    form.updateItem({
      option: props.option,
      value: val,
    });
  } else {
    form.deleteItem(props.option);
  }
  form.touchItem({ option: props.option });
};

const textarea = ref(null);

const adjustTextareaHeight = () => {
  const el = textarea.value;
  el.style.height = "auto";
  el.style.height = el.scrollHeight + "px";
};

const value = computed(() => {
  return form.getValue(props.option);
});

const remaining = computed(() => {
  if (!props.max) {
    return 0;
  }

  return props.max - (value.value?.length ?? 0);
});

watch(value, () => {
  adjustTextareaHeight();
});
</script>

<template>
  <tr :class="`tr_${option.key} big-spacing-top big-spacing-bottom border-bottom`">
    <td colspan="2" width="81%">
      <textarea
        :id="id"
        ref="textarea"
        :placeholder="capitalize(placeholder)"
        :value="value"
        :name="option.key"
        :disabled="disabled"
        rows="3"
        style="
          overflow: hidden;
          overflow-wrap: break-word;
          resize: horizontal;
          height: 100px;
          color: rgb(59, 59, 59);
        "
        @input="update"
      ></textarea>
      <span v-if="max" style="float: right; font-size: 10px; font-size: 1rem">
        {{ remaining }}
      </span>
    </td>
    <td width="19%" class="align-right"></td>
  </tr>
</template>
