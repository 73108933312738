<script setup>
import { computed } from "vue";
import { useOptionProps } from "../composables/props";
import SvgUse from "../../components/SvgUse.vue";
import { capitalize } from "../../helpers";
import { __ } from "../composables/lang";
import { useEmitter } from "../composables/emitter";
import OrderOption from "../classes/OrderOption";
import HiddenField from "./HiddenField.vue";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  ...useOptionProps(),
});

const { emit } = useEmitter();

const rowClasses = computed(() => {
  let classes = {
    "big-spacing-top": props.first,
    "border-bottom": props.divider && props.last,
  };

  return classes;
});

const openUploader = () => {
  emit("open-uploader");
};
</script>

<template>
  <tr class="tr_document" :class="rowClasses">
    <td>
      {{ first ? capitalize(section) : "" }}
    </td>
    <td colspan="2">
      <span class="docselect btn btn-primary" @click.stop="openUploader">
        <span class="tailwind">
          <svg-use
            id="file-plus"
            type="solid"
            class="#fill-current #w-[14px] #h-[17px] #mr-[5px]"
          ></svg-use>
        </span>
        <span>{{ __("Voeg toe") }}</span>
      </span>
      <HiddenField v-bind="$props" type="string" />
    </td>
  </tr>
</template>
