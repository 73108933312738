<script setup>
import { computed, inject, nextTick, onBeforeMount, onMounted, reactive, ref } from "vue";
import { __ } from "../order/composables/lang";
import InputField from "./InputField.vue";
import LoaderSpinner from "./LoaderSpinner.vue";
import { fetchWithTimeout } from "../../admin/helpers";
import ZipCodeField from "./ZipCodeField.vue";
import InputDropdown from "./InputDropdown.vue";
import { useStore } from "vuex";

const store = useStore();

const zipcode = defineModel("zipcode", { type: String, required: true });
const city = defineModel("city", { type: String, required: true });
const street = defineModel("street", { type: String, required: true });
const streetnumber = defineModel("streetnumber", { type: String, required: true });
const country = defineModel("country", { type: String, required: true });

const props = defineProps({
  zipcodeError: {
    type: String,
    default: "",
    required: false,
  },
  cityError: {
    type: String,
    default: "",
    required: false,
  },
  streetError: {
    type: String,
    default: "",
    required: false,
  },
  streetnumberError: {
    type: String,
    default: "",
    required: false,
  },
  countryError: {
    type: String,
    default: "",
    required: false,
  },
});

const streetNumberField = ref(null);
const autocompleting = ref(false);

const autocompleteAddress = async (zipcode) => {
  if (!country.value || country.value.toLowerCase() !== "nl") {
    return;
  }

  if (/^\d{4}[A-Za-z]{2}$/.test(zipcode) === false) {
    return;
  }

  autocompleting.value = true;

  try {
    let response = await fetchWithTimeout(
      `https://api.pro6pp.nl/v2/suggest/nl/street?authKey=KJyx73TZvCjF6YDr&postalCode=${zipcode}`,
      {
        timeout: 10000,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      },
    );

    if (!response.ok) {
      autocompleting.value = false;

      return;
    }

    let data = await response.json();
    if (!Array.isArray(data) || data.length === 0) {
      autocompleting.value = false;

      return;
    }

    let [{ settlement, street: streetvalue } = {}] = data;
    if (settlement) {
      city.value = settlement;
    }
    if (streetvalue) {
      street.value = streetvalue;
    }

    if (street.value || settlement) {
      streetnumber.value = "";
      nextTick(() => {
        streetNumberField.value.focus();
      });
    }

    autocompleting.value = false;
  } catch (error) {
    autocompleting.value = false;

    return;
  }
};

const countriesForSelect = computed(() => {
  if (store.getters.countries === null) {
    return {};
  }
  let tempcountries = reactive({});
  for (const [key, value] of Object.entries(store.getters.countries)) {
    tempcountries[key] = value.label;
  }
  return tempcountries;
});

const updateZipcode = (value) => {
  zipcode.value = value;
  autocompleteAddress(value);
};
</script>
<template>
  <div>
    <div class="#flex #items-start #gap-x-6">
      <ZipCodeField
        :model-value="zipcode"
        :error="zipcodeError"
        :disabled="autocompleting"
        class="#mb-6"
        size="sm"
        :label="__('Postcode')"
        type="text"
        :required="true"
        @update:model-value="(value) => updateZipcode(value)"
      >
        <div
          v-if="autocompleting"
          class="#hidden #absolute #h-full #w-full sm:#flex #justify-end #items-center #pointer-events-none #pr-2"
        >
          <LoaderSpinner class="!#w-max !#mx-0" size="sm"></LoaderSpinner>
        </div>
      </ZipCodeField>
      <InputField
        v-model="city"
        :error="cityError"
        :disabled="autocompleting"
        class="#mb-6 #flex-1"
        size="lg"
        :label="__('Plaats')"
        type="text"
        :required="true"
      />
    </div>
    <div class="#flex #items-start #gap-x-6">
      <InputField
        v-model="street"
        :error="streetError"
        :disabled="autocompleting"
        class="#mb-6 #flex-1"
        size="lg"
        :label="__('Straatnaam')"
        type="text"
        :required="true"
      />
      <InputField
        ref="streetNumberField"
        v-model="streetnumber"
        :error="streetnumberError"
        :disabled="autocompleting"
        class="#mb-6"
        size="sm"
        :label="__('Nummer')"
        type="text"
        :required="true"
      />
    </div>

    <InputDropdown
      v-model="country"
      :items="countriesForSelect"
      :error="countryError"
      :label="__('Land')"
      :required="true"
      class="#relative"
    />
  </div>
</template>
