<script setup>
import { ref } from "vue";
import { removeSpaces } from "../helpers";
import { __ } from "../order/composables/lang";
import InputField from "./InputField.vue";

const emit = defineEmits(["update:modelValue"]);

const updateModelValue = (value) => {
  value = removeSpaces(value);
  emit("update:modelValue", value);
};

const inputField = ref(null);

const focus = () => {
  inputField.value.focus();
};

defineExpose({
  focus,
});

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <InputField
    ref="inputField"
    :model-value="modelValue"
    :label="__('E-mailadres')"
    type="email"
    @update:model-value="(value) => updateModelValue(value)"
  >
    <slot></slot
  ></InputField>
</template>
