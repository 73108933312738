<script setup>
import { useTemplateRef, ref, computed, watchEffect } from "vue";
import { createPopper } from "@popperjs/core";
import SvgUse from "../components/SvgUse.vue";
import { useStore } from "vuex";

const store = useStore();

const tooltipContainer = useTemplateRef("tooltip-container");
const popperInstance = ref(null);

const open = computed(() => {
  return store.state.order.tooltip.open;
});
const title = computed(() => {
  return store.state.order.tooltip.title;
});
const content = computed(() => {
  return store.state.order.tooltip.content;
});
const anchor = computed(() => {
  return store.state.order.tooltip.anchor;
});

const closeTooltip = () => {
  store.commit("order/closeTooltip");
};

watchEffect(() => {
  if (popperInstance.value) {
    popperInstance.value.destroy();
    popperInstance.value = null;
  }
  if (open.value && anchor.value) {
    popperInstance.value = createPopper(anchor.value, tooltipContainer.value, {
      placement: "right",
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            mainAxis: false,
            altAxis: true,
            padding: 20,
          },
        },
        {
          name: "flip",
          options: {
            fallbackPlacements: ["bottom", "top"],
          },
        },
      ],
    });
  }
});
</script>

<template>
  <div
    ref="tooltip-container"
    :style="{ display: open ? 'block' : 'none' }"
    class="popover right modern popover-recommendation"
    role="tooltip"
  >
    <div class="arrow hidden-xs" style="top: 50%"></div>
    <span class="tailwind">
      <svg-use
        id="xmark"
        type="solid"
        class="#fill-white #w-[17px] #h-[17px] #float-right #cursor-pointer #p-[12px] #box-content hover:#opacity-50"
        @click="closeTooltip"
      ></svg-use>
    </span>
    <h3 class="popover-title">{{ title }}</h3>
    <div class="popover-content" v-html="content"></div>
  </div>
</template>
