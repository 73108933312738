<script setup>
import { computed } from "vue";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";
import { useSelectOptionProps } from "../composables/props";
import OptionRow from "../OptionRow.vue";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  ...useSelectOptionProps(),
});

const form = useOrderForm();

const id = computed(() => {
  return Symbol.keyFor(props.option.id);
});

form.addOption({
  option: props.option,
  type: "select",
});

const preloaded = import.meta.env.SSR
  ? global.preload[props.option.key]
  : window.preload[props.option.key];

const urlParamsObject = import.meta.env.SSR
  ? {}
  : Object.fromEntries(new URLSearchParams(window.location.search).entries());

if (preloaded && preloaded != "0" && !form.hasValue(props.option)) {
  form.updateItem({
    option: props.option,
    value: preloaded,
  });
  form.touchItem({ option: props.option, programmatically: true });
} else if (!form.isEditing() && urlParamsObject[props.option.key]) {
  form.updateItem({
    option: props.option,
    value: urlParamsObject[props.option.key],
  });
} else if (!form.isEditing() && urlParamsObject[props.option.key] === undefined && props.initial) {
  form.updateItem({
    option: props.option,
    value: props.option.value,
  });
}

const update = (event) => {
  if (event.target.value) {
    form.updateItem({
      option: props.option,
      value: event.target.value,
    });
  } else {
    form.deleteItem(props.option);
  }
  form.touchItem({ option: props.option });
};

const value = computed(() => {
  return form.getValue(props.option) ?? props.defaultOption;
});
</script>
<template>
  <OptionRow v-bind="$props">
    <select :id="id" :value="value" @change="update">
      <option
        v-for="selectOption in options"
        :key="selectOption.value"
        :value="selectOption.value"
        :selected="selectOption.value === value"
      >
        {{ selectOption.label }}
      </option>
    </select>
  </OptionRow>
</template>
