export default class OrderProduct {
  slug;
  group;
  title;
  aliases = [];

  constructor(slug, group, title) {
    this.slug = slug;
    this.group = group;
    this.title = title;
  }

  alias(slug, title) {
    this.aliases.push({ slug, title });

    return this;
  }
}
