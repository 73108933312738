<script setup>
import { computed } from "vue";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import TopcoatOption from "./TopcoatOption.vue";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import infoColorGuarantee from "../checks/infoColorGuarantee";
import { useOrderForm } from "../../modules/orderModule";
import { __ } from "../composables/lang";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "papertype",
    value: "nevertear_125",
    label: __("polyester (125 µm)"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled: cms_disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));
check(infoColorGuarantee());

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }

  return form.isChecked(TopcoatOption);
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
