<script setup>
import NumberOption from "../options/NumberOption.vue";
import SizeA7Option from "../options/SizeA7Option.vue";
import SizeA6Option from "../options/SizeA6Option.vue";
import OptionSection from "../OptionSection.vue";
import SizeA5Option from "../options/SizeA5Option.vue";
import SizeA4Option from "../options/SizeA4Option.vue";
import SizeDinLongOption from "../options/SizeDinLongOption.vue";
import ColorAllOption from "../options/ColorAllOption.vue";
import ColorNoneOption from "../options/ColorNoneOption.vue";
import PrintSideDoubleOption from "../options/PrintSideDoubleOption.vue";
import PrintSideSingleOption from "../options/PrintSideSingleOption.vue";
import PaperStandardOption from "../options/PaperStandardOption.vue";
import PaperRomanBiotopOption from "../options/PaperRomanBiotopOption.vue";
import PaperFirmOption from "../options/PaperFirmOption.vue";
import PaperExtraFirmOption from "../options/PaperExtraFirmOption.vue";
import PaperGlossyOption from "../options/PaperGlossyOption.vue";
import PaperVeryFirmOption from "../options/PaperVeryFirmOption.vue";
import PaperNaturalCardOption from "../options/PaperNaturalCardOption.vue";
import PaperCardOption from "../options/PaperCardOption.vue";
import PaperEcoFiberOption from "../options/PaperEcoFiberOption.vue";
import PaperNevertearOption from "../options/PaperNevertearOption.vue";
import PaperNevertearExtraOption from "../options/PaperNevertearExtraOption.vue";
import TopcoatNoneOption from "../options/TopcoatNoneOption.vue";
import TopcoatOption from "../options/TopcoatOption.vue";
import SizeCustomOption from "../options/SizeCustomOption.vue";
import CoatingFrontOption from "../options/CoatingFrontOption.vue";
import CoatingDoubleOption from "../options/CoatingDoubleOption.vue";
import CheckDocStandardOption from "../options/CheckDocStandardOption.vue";
import CheckDocAdvancedOption from "../options/CheckDocAdvancedOption.vue";
import BorderlessOption from "../options/BorderlessOption.vue";
import DefaultOptions from "../DefaultOptions.vue";
import RoundCornersOption from "../options/RoundCornersOption.vue";
import { getCurrentInstance, watch } from "vue";
import { __ } from "../composables/lang";
import { useOrderForm } from "../../modules/orderModule";
import { FormError, InfoFormNotification } from "../composables/errors";
import { InfoFormWarning } from "../composables/errors";
import FilePageCountOption from "../options/FilePageCountOption.vue";
import CopiesHiddenOption from "../options/CopiesHiddenOption.vue";
import OrderProduct from "../classes/OrderProduct";
import FileNamesOption from "../options/FileNamesOption.vue";
import fileHasDifferentFormat from "../checks/fileHasDifferentFormat";
import fileHasSpread from "../checks/fileHasSpread";
import fileHasFrame from "../checks/fileHasFrame";
import suggestBorderless from "../checks/suggestBorderless";
import checkBorderless from "../checks/checkBorderless";
import correctBorderless from "../checks/correctBorderless";
import HiddenOptions from "../HiddenOptions.vue";
import { useStore } from "vuex";
import FileProcessingOption from "../options/FileProcessingOption.vue";
import checkMixedFormats from "../checks/checkMixedFormats";
import checkLetterFormat from "../checks/checkLetterFormat";

// briefpapier
// kleurplaat
// placemat

defineOptions(
  new OrderProduct("flyer", "flyer", "Flyer")
    .alias("a7-flyer", "A7 Flyer")
    .alias("a6-flyer", "A6 Flyer")
    .alias("a5-flyer", "A5 Flyer")
    .alias("a4-flyer", "A4 Flyer")
    .alias("flyer-din-long", "Din long flyer")
    .alias("flyer-eigen-formaat", "Eigen formaat flyer"),
);

const instance = getCurrentInstance().type;

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
});

const showSize = (size) => {
  let customSizeAliases = [
    "a7-flyer",
    "a6-flyer",
    "a5-flyer",
    "a4-flyer",
    "flyer-din-long",
    "flyer-eigen-formaat",
  ];

  switch (size) {
    case "a7":
      return props.product === "a7-flyer" || !customSizeAliases.includes(props.product);
    case "a6":
      return props.product === "a6-flyer" || !customSizeAliases.includes(props.product);
    case "a5":
      return props.product === "a5-flyer" || !customSizeAliases.includes(props.product);
    case "a4":
      return props.product === "a4-flyer" || !customSizeAliases.includes(props.product);
    case "din-long":
      return props.product === "flyer-din-long" || !customSizeAliases.includes(props.product);
    case "custom":
      return props.product === "flyer-eigen-formaat" || !customSizeAliases.includes(props.product);
    default:
      return false;
  }
};

const form = useOrderForm();
const store = useStore();

form.addProductCheck(suggestBorderless());
form.addProductCheck(correctBorderless());
form.addProductCheck(checkBorderless());
form.addProductCheck(checkMixedFormats());
form.addProductCheck(checkLetterFormat());
form.addProductCheck(fileHasDifferentFormat());
form.addProductCheck(fileHasFrame());
form.addProductCheck(fileHasSpread());

form.addProductCheck((form) => {
  if (!form.hasFileinfo()) {
    return;
  }

  if (form.getValue(FilePageCountOption) > 2) {
    throw new FormError(
      __("Er zijn meer dan 2 pagina's gevonden in het bestand, deze kunnen wij niet verwerken. Heb je verschillende ontwerpen? Maak dan gebruik van het winkelmandje door per ontwerp je flyer samen te stellen en toe te voegen."),
    );
  } else if (form.getValue(FilePageCountOption) == 2 && form.isChecked(PrintSideSingleOption)) {
    if (!form.isTouched(PrintSideDoubleOption)) {
      form.selectItem(PrintSideDoubleOption);
      form.touchItem({ option: PrintSideDoubleOption, programmatically: true });
      throw new InfoFormNotification(
        __("Er zijn 2 pagina's gevonden in het bestand, daarom is de optie 'Dubbelzijdig' aangevinkt"),
      );
    }
  } else if (form.getValue(FilePageCountOption) == 1 && form.isChecked(PrintSideDoubleOption)) {
    throw new InfoFormWarning(
      __("Er is 1 pagina geupload en er is gekozen voor dubbelzijdig daarom dupliceren wij deze pagina automatisch voor de achterkant."),
    );
  }
});

form.addProductCheck((form, fileInfo) => {
  if (!form.hasFileinfo()) {
    return;
  }

  const { doc: { bleed_w = null } = {} } = fileInfo;

  if (bleed_w && !form.isChecked(BorderlessOption) && !form.isTouched(BorderlessOption)) {
    form.selectItem(BorderlessOption);
  }
});

watch(store.state.order.form, () => {
  const pageCount = form.getValue(FilePageCountOption);
  const shouldDuplicatePage = pageCount === 1 && form.isChecked(PrintSideDoubleOption);
  const fileProcessing = form.getValue(FileProcessingOption);

  if (shouldDuplicatePage && fileProcessing !== "duplicate_first_page") {
    form.updateItem({ option: FileProcessingOption, value: "duplicate_first_page" });
  } else if (!shouldDuplicatePage && fileProcessing === "duplicate_first_page") {
    form.updateItem({ option: FileProcessingOption, value: "default" });
  }
});
</script>

<template>
  <HiddenOptions :product="props.product" :group="instance.group" />

  <CopiesHiddenOption :initial="1" />

  <OptionSection :title="__('oplage')">
    <NumberOption :initial="1" />
  </OptionSection>
  <OptionSection :title="__('document')" divider>
    <FileNamesOption />
  </OptionSection>
  <OptionSection :title="__('formaat')">
    <SizeA7Option
      v-if="showSize('a7')"
      :initial="product === 'a7-flyer'"
      :hidden="product === 'a7-flyer'"
    />
    <SizeA6Option
      v-if="showSize('a6')"
      :initial="product === 'a6-flyer'"
      :hidden="product === 'a6-flyer'"
    />
    <SizeA5Option
      v-if="showSize('a5')"
      :initial="['flyer', 'a5-flyer'].includes(product)"
      :hidden="product === 'a5-flyer'"
    />
    <SizeA4Option
      v-if="showSize('a4')"
      :initial="product === 'a4-flyer'"
      :hidden="product === 'a4-flyer'"
    />
    <SizeDinLongOption
      v-if="showSize('din-long')"
      :initial="product === 'flyer-din-long'"
      :hidden="product === 'flyer-din-long'"
    />
    <SizeCustomOption v-if="showSize('custom')" :initial="product === 'flyer-eigen-formaat'" />
  </OptionSection>
  <OptionSection :title="__('opdruk')">
    <ColorAllOption initial />
    <ColorNoneOption />
  </OptionSection>
  <OptionSection :title="__('printwijze')">
    <PrintSideSingleOption initial disable-if-two-copies />
    <PrintSideDoubleOption />
    <BorderlessOption initial />
  </OptionSection>
  <OptionSection :title="__('papiersoort')">
    <PaperStandardOption icon="leaf" icon-color="green" />
    <PaperRomanBiotopOption icon="leaf" icon-color="green" />
    <PaperFirmOption initial />
    <PaperExtraFirmOption />
    <PaperGlossyOption
      icon="star"
      icon-title="Aanbevolen"
      icon-content="170 grams is stevig maar niet te duur en heeft een duidelijke glans, wij raden dit papiersoort aan voor een goede flyer"
    />
    <PaperVeryFirmOption />
    <PaperNaturalCardOption />
    <PaperCardOption />
    <PaperEcoFiberOption icon="leaf" icon-color="green" />
    <PaperNevertearOption />
    <PaperNevertearExtraOption />
    <RoundCornersOption />
  </OptionSection>
  <OptionSection :title="__('toplaag')">
    <TopcoatNoneOption initial />
    <TopcoatOption />
  </OptionSection>
  <OptionSection :title="__('coating')">
    <CoatingFrontOption initial />
    <CoatingDoubleOption />
  </OptionSection>
  <OptionSection :title="__('document controle')" divider>
    <CheckDocStandardOption initial />
    <CheckDocAdvancedOption />
  </OptionSection>

  <DefaultOptions />
</template>
