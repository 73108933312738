<script setup>
import InputField from "./InputField.vue";
import { __ } from "../order/composables/lang";
import CustomerTypeSwitch from "./CustomerTypeSwitch.vue";
import VatNumberField from "./VatNumberField.vue";
import EmailField from "./EmailField.vue";
import { useStore } from "vuex";
import PhoneNumberField from "./PhoneNumberField.vue";
import AutocompleteAddress from "./AutocompleteAddress.vue";

const store = useStore();
const { isAdmin } = store.getters;

const company = defineModel("company", { type: String, required: true });
const contact_type = defineModel("contact_type", { type: String, required: true });
const vat_number = defineModel("vat_number", { type: String, required: true });
const name_contact = defineModel("name_contact", { type: String, required: true });
const email = defineModel("email", { type: String, required: true });
const telephone = defineModel("telephone", { type: String, required: true });

const zipcode = defineModel("zipcode", { type: String, required: true });
const city = defineModel("city", { type: String, required: true });
const street = defineModel("street", { type: String, required: true });
const streetnumber = defineModel("streetnumber", { type: String, required: true });
const country = defineModel("country", { type: String, required: true });

const props = defineProps({
  companyError: {
    type: String,
    default: "",
    required: false,
  },
  companyWarning: {
    type: String,
    default: "",
  },
  nameError: {
    type: String,
    default: "",
    required: false,
  },
  emailError: {
    type: String,
    default: "",
    required: false,
  },
  telephoneError: {
    type: String,
    default: "",
    required: false,
  },
  zipcodeError: {
    type: String,
    default: "",
    required: false,
  },
  cityError: {
    type: String,
    default: "",
    required: false,
  },
  streetError: {
    type: String,
    default: "",
    required: false,
  },
  streetnumberError: {
    type: String,
    default: "",
    required: false,
  },
  countryError: {
    type: String,
    default: "",
    required: false,
  },
  isCompany: {
    type: Boolean,
    required: true,
  },
  showVatNumber: {
    type: Boolean,
    default: true,
  },
});
</script>

<template>
  <CustomerTypeSwitch v-model="contact_type"></CustomerTypeSwitch>
  <h2 v-show="isCompany" class="#text-lg #font-semibold #mb-6">
    {{ __("Factuurgegevens") }}
  </h2>
  <InputField
    v-show="isCompany"
    v-model="company"
    :label="__('Bedrijfsnaam')"
    type="text"
    :error="companyError"
    :warning="companyWarning"
    :required="true"
  />
  <AutocompleteAddress
    v-show="isCompany"
    v-model:zipcode="zipcode"
    v-model:city="city"
    v-model:street="street"
    v-model:streetnumber="streetnumber"
    v-model:country="country"
    :zipcode-error="zipcodeError"
    :city-error="cityError"
    :street-error="streetError"
    :streetnumber-error="streetnumberError"
    :country-error="countryError"
  ></AutocompleteAddress>
  <VatNumberField
    v-if="showVatNumber"
    ref="vatNumberField"
    v-model="vat_number"
    :country="country"
  ></VatNumberField>
  <h2 class="#text-lg #font-semibold #mb-6 #mt-12">
    {{ __("Persoonlijke gegevens") }}
  </h2>
  <InputField
    v-model="name_contact"
    :label="__('Naam')"
    :error="nameError"
    :required="true"
  ></InputField>
  <EmailField
    v-model="email"
    :disabled="!isAdmin"
    :error="emailError"
    :required="true"
  ></EmailField>
  <PhoneNumberField v-model="telephone" :error="telephoneError"></PhoneNumberField>
  <AutocompleteAddress
    v-show="!isCompany"
    v-model:zipcode="zipcode"
    v-model:city="city"
    v-model:street="street"
    v-model:streetnumber="streetnumber"
    v-model:country="country"
    class="!#mt-12"
    :zipcode-error="zipcodeError"
    :city-error="cityError"
    :street-error="streetError"
    :streetnumber-error="streetnumberError"
    :country-error="countryError"
  ></AutocompleteAddress>
</template>
