<script setup>
import { ref } from "vue";
import { __ } from "../order/composables/lang";

const emit = defineEmits(["update:modelValue"]);

const updateModelValue = (value) => {
  initialType.value = null;
  emit("update:modelValue", value);
};

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});

const initialType = ref(props.modelValue);

const getCircleClass = (type) => {
  return {
    begincircle: initialType.value === type,
    circletype: props.modelValue === type,
  };
};
</script>
<template>
  <div class="#flex #relative #w-full #max-w-[260px] #mb-6 #gap-x-[10px]">
    <a
      href="javascript:"
      class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
      :class="{ '#opacity-30': modelValue !== 'bedrijf' }"
      @click="updateModelValue('bedrijf')"
    >
      <svg
        class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 266.67 149.97"
        preserveAspectRatio="none"
      >
        <defs>
          <clipPath id="businessclip">
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
              style="fill: none"
            />
          </clipPath>
        </defs>
        <g clip-path="url('#businessclip')">
          <path
            id="bedrijf"
            class="typecircle #stroke-brightcyan #stroke-[30px]"
            :class="getCircleClass('bedrijf')"
            d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
            style="fill: none"
          />
        </g>
      </svg>
      <p class="#relative #z-[1] #font-semibold">
        {{ __("Zakelijk") }}
      </p>
    </a>
    <a
      href="javascript:"
      class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
      :class="{ '#opacity-30': modelValue !== 'particulier' }"
      @click="updateModelValue('particulier')"
    >
      <svg
        class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 266.67 149.97"
        preserveAspectRatio="none"
      >
        <defs>
          <clipPath id="privateclip">
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
              style="fill: none"
            />
          </clipPath>
        </defs>
        <g clip-path="url('#privateclip')">
          <path
            id="particulier"
            class="typecircle #stroke-brightcyan #stroke-[30px]"
            :class="getCircleClass('particulier')"
            d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
            style="fill: none"
          />
        </g>
      </svg>
      <p class="#relative #z-[1] #font-semibold">
        {{ __("Particulier") }}
      </p>
    </a>
    <a
      href="javascript:"
      class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
      :class="{ '#opacity-30': modelValue !== 'student' }"
      @click="updateModelValue('student')"
    >
      <svg
        class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 266.67 149.97"
        preserveAspectRatio="none"
      >
        <defs>
          <clipPath id="studentclip">
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
              style="fill: none"
            />
          </clipPath>
        </defs>
        <g clip-path="url('#studentclip')">
          <path
            id="student"
            class="typecircle #stroke-brightcyan #stroke-[30px]"
            :class="getCircleClass('student')"
            d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
            style="fill: none"
          />
        </g>
      </svg>
      <p class="#relative #z-[1] #font-semibold">
        {{ __("Student") }}
      </p>
    </a>
  </div>
</template>
