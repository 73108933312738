import SizeCustomOption from "../options/SizeCustomOption.vue";
import { __ } from "./lang";
import * as options from "../options";

export function roundNumber(num, dec) {
  var result = Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  return result;
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatCurrency(n, c, d, t) {
  c = isNaN(c = Math.abs(c)) ? 2 : c;
  d = d == undefined ? "." : d;
  t = t == undefined ? "," : t;
  var s = n < 0 ? "-" : "";
  var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "";
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

export //calculate the number of digits
function getNoBehindComma(value, limit_digits) {

  if (limit_digits == undefined)
    limit_digits = 'none';

  if (value > 1) {
    if (limit_digits == 'none')
      return 2;
    else {
      var length = value.toFixed(0).toString().length;
      if (length > limit_digits)
        return 0;
      else
        return (limit_digits - length);
    }
  }

  var difference = Math.abs(value - (Math.round(value/0.01))*0.01);
  if (difference > 0.0001)
    return 3;

  return 2;
}

export function formatMoney(value, decimals) {
  value = parseFloat(value);
  if (value === false)
    return '<i>gratis</i>';

  decimals = typeof(decimals) != 'undefined' ? decimals : getNoBehindComma(value);
  value = roundNumber(value, decimals);

  return formatCurrency(value, decimals, ',', '.');
}

export function getPaperFormat(height, width) {
  const marge = 1;
  const sizes = import.meta.env.SSR ? global.constants.size : window.constants.size;

  for (const key in sizes) {
    const { width: sizeWidth, height: sizeHeight } = sizes[key];
    const widthMatch = Math.abs(sizeWidth - width) <= marge;
    const heightMatch = Math.abs(sizeHeight - height) <= marge;
    const altWidthMatch = Math.abs(sizeWidth - height) <= marge;
    const altHeightMatch = Math.abs(sizeHeight - width) <= marge;

    if ((widthMatch && heightMatch) || (altWidthMatch && altHeightMatch)) {
      for (const [, entry] of Object.entries(options)) {
        if (entry.key === 'size' && entry.value === key) {
          return entry;
        }
      }
    }
  }

  return SizeCustomOption;
}

export function paperRatioCorrect(docHeight, docWidth) {
  var ratio;

  if (docHeight > docWidth)
    ratio = (docHeight / docWidth);
  else
    ratio = (docWidth / docHeight);

  if (Math.abs(ratio - 1.414) < 0.01)
    return true;
  else
    return false;
}

export function translateSizeValue(value) {
  switch (value) {
    case 'circular': return __('rond formaat');
    case 'custom': return __('aangepast formaat');
    case 'photoframe': return __('de gekozen fotolijst');
    case 'o30': return __('rond') + ' ' + '30mm';
    case 'o63': return __('rond') + ' ' + '63mm';
    case 'o90': return __('rond') + ' ' + '90mm';
    default: return value.toUpperCase();
  }
}
