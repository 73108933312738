class BaseError extends Error {
  constructor(message, settings = {}) {
    super(message);

    this.id = Symbol();
    this.type = this.constructor.name;
    this.key = settings.key ?? null;
    this.section = settings.section ?? null;
    this.cta = settings.cta ?? null;
    this.icon = settings.icon ?? null;
    this.iconType = settings.iconType ?? null;
    this.api = settings.api ?? null;
  }
}

export class FormError extends BaseError {
  constructor(message, settings = {}) {
    super(message, settings);
  }
}

export class FatalFormError extends BaseError {
  constructor(message, settings = {}) {
    super(message, settings);
  }
}

export class InfoFormWarning extends BaseError {
  constructor(message, settings = {}) {
    super(message, settings);
  }
}

export class InfoFormNotification extends BaseError {
  constructor(message, settings = {}) {
    super(message, settings);

    this.position = settings.position ?? "bottom";
  }
}
