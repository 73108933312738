<script setup>
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";

import { __ } from "../composables/lang";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "finishing2",
    value: "none",
    label: __("geen toplaag"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

check(isRequired());
check(isOneOf(props.choices));
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
