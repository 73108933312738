import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { reactive, watch } from "vue";

export function useForm(data, extraOptions) {
  let initalForm = cloneDeep(data);

  const form = reactive({
    ...cloneDeep(initalForm),
    isDirty: false,
    errors: {},
    warnings: {},
    data() {
      return Object.keys(initalForm).reduce((carry, key) => {
        carry[key] = this[key];
        return carry;
      }, {});
    },
    async post(url, options = {}) {
      let response = {};
      try {
        response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify(form.data()),
        });
      } catch (error) {
        return {
          ok: false,
          status: 500,
        };
      }
      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          initalForm = form.data();
        }
        return {
          ok: response.ok,
          data: data,
          status: response.status,
        };
      } else if (response.status === 422) {
        const body = await response.json();
        this.errors = Object.fromEntries(
          Object.entries(body.errors).map(([key, value]) => [key, value[0]]),
        );
        return {
          ok: response.ok,
          data: data,
          status: response.status,
        };
      } else {
        await response.text();
        return {
          ok: response.ok,
          data: data,
          status: response.status,
        };
      }
    },
    clearError(field) {
      if (!this.errors) {
        return;
      }
      delete this.errors[field];
    },
    clearAllErrors() {
      this.errors = {};
    },
    ...extraOptions,
  });

  watch(
    form,
    () => {
      form.isDirty = !isEqual(form.data(), initalForm);
    },
    { immediate: true, deep: true },
  );

  for (const [key] of Object.entries(form.data())) {
    watch(
      () => form.data()[key],
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          form.clearError(key);
        }
      },
      { immediate: false, deep: true },
    );
  }

  return form;
}
