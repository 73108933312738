<script setup>
import { __ } from "../composables/lang";
import { useSetup } from "../composables/option";
import { isOneOf } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "production_method",
    value: "budget",
    label: __("budget (4 dagen)"),
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

check(isOneOf(props.choices));
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
