<script setup>
import InputField from "./InputField.vue";
import { __ } from "../order/composables/lang";
import CustomerTypeSwitch from "./CustomerTypeSwitch.vue";
import VatNumberField from "./VatNumberField.vue";
import EmailField from "./EmailField.vue";
import { useStore } from "vuex";
import PhoneNumberField from "./PhoneNumberField.vue";
import AutocompleteAddress from "./AutocompleteAddress.vue";
import LabeledInput from "./LabeledInput.vue";
import { ref, watch } from "vue";

const store = useStore();

const company = defineModel("company", { type: String, required: true });
const contact_type = defineModel("contact_type", { type: String, required: true });
const vat_number = defineModel("vat_number", { type: String, required: true });
const name_contact = defineModel("name_contact", { type: String, required: true });
const email = defineModel("email", { type: String, required: true });
const password = defineModel("password", { type: String, required: true });
const telephone = defineModel("telephone", { type: String, required: true });

const zipcode = defineModel("zipcode", { type: String, required: true });
const city = defineModel("city", { type: String, required: true });
const street = defineModel("street", { type: String, required: true });
const streetnumber = defineModel("streetnumber", { type: String, required: true });
const country = defineModel("country", { type: String, required: true });
const isGuest = defineModel("isGuest", { type: Boolean, required: false, default: false });

const props = defineProps({
  companyError: {
    type: String,
    default: "",
    required: false,
  },
  companyWarning: {
    type: String,
    default: "",
  },
  nameError: {
    type: String,
    default: "",
    required: false,
  },
  emailError: {
    type: String,
    default: "",
    required: false,
  },
  passwordError: {
    type: String,
    default: "",
    required: false,
  },
  telephoneError: {
    type: String,
    default: "",
    required: false,
  },
  zipcodeError: {
    type: String,
    default: "",
    required: false,
  },
  cityError: {
    type: String,
    default: "",
    required: false,
  },
  streetError: {
    type: String,
    default: "",
    required: false,
  },
  streetnumberError: {
    type: String,
    default: "",
    required: false,
  },
  countryError: {
    type: String,
    default: "",
    required: false,
  },
  isCompany: {
    type: Boolean,
    required: true,
  },
  showVatNumber: {
    type: Boolean,
    default: true,
  },
  location: {
    type: String,
    required: false,
    default: "registerpage",
  },
});

const makeAccount = ref(false);
const existingCustomer = ref(false);

watch(existingCustomer, (newValue) => {
  if (newValue) {
    makeAccount.value = false;
  }
});

watch(makeAccount, (newValue) => {
  isGuest.value = !newValue;
});

watch(email, () => {
  existingCustomer.value = false;
});

const isCheckoutPage = () => {
  return props.location === "checkoutpage";
};

const isRegisterPage = () => {
  return props.location === "registerpage";
};

const checkForUser = async () => {
  if (!isCheckoutPage()) {
    return;
  }
  if (email.value === "") {
    return;
  }
  let response = await fetch(store.getters.route("validate.email"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({ email: email.value }),
  });
  let data = await response.json();
  existingCustomer.value = data.success;
};
</script>

<template>
  <CustomerTypeSwitch
    v-model="contact_type"
    :class="{ '#mx-auto !#mt-6': isRegisterPage() }"
  ></CustomerTypeSwitch>
  <h2 v-show="isCompany" class="#text-lg #font-semibold #mb-6">
    {{ __("Factuurgegevens") }}
  </h2>
  <InputField
    v-show="isCompany"
    v-model="company"
    :label="__('Bedrijfsnaam')"
    type="text"
    :error="companyError"
    :warning="companyWarning"
    :required="true"
  />
  <AutocompleteAddress
    v-show="isCompany"
    v-model:zipcode="zipcode"
    v-model:city="city"
    v-model:street="street"
    v-model:streetnumber="streetnumber"
    v-model:country="country"
    :zipcode-error="zipcodeError"
    :city-error="cityError"
    :street-error="streetError"
    :streetnumber-error="streetnumberError"
    :country-error="countryError"
  ></AutocompleteAddress>
  <VatNumberField
    v-show="showVatNumber"
    ref="vatNumberField"
    v-model="vat_number"
    :country="country"
  ></VatNumberField>
  <h2 class="#text-lg #font-semibold #mb-6 #mt-12">
    {{ __("Persoonlijke gegevens") }}
  </h2>
  <InputField
    v-model="name_contact"
    :label="__('Naam')"
    :error="nameError"
    :required="true"
  ></InputField>
  <EmailField
    v-model="email"
    :error="emailError"
    :required="true"
    :disabled="store.getters?.selection?.type === 'waitinglist_customer_invite'"
    @blur="checkForUser"
  ></EmailField>
  <InputField
    v-if="isRegisterPage() || (makeAccount && !existingCustomer)"
    v-model="password"
    :error="passwordError"
    class="#w-full #mb-[10px]"
    :label="__('Wachtwoord')"
    type="password"
    :required="true"
    auto-complete="new-password"
  />
  <LabeledInput
    v-if="!existingCustomer && isCheckoutPage()"
    v-model="makeAccount"
    :title="__('Account aanmaken')"
    :register-form="true"
    class="#w-full #mb-[10px]"
  />
  <div
    v-if="email && existingCustomer && isCheckoutPage()"
    class="#bg-[#d9edf7] #text-sm #p-[10px] #mb-[15px] #mt-[5px] #border-[1px] #border-solid #border-[#bce8f1] #text-[#31708f] #rounded-[4px]"
  >
    {{
      __(
        "Het lijkt erop dat dit e-mailadres al wordt gebruikt voor een ander account. Is dit jouw account? Je kunt ook eerst inloggen om je bestelling later makkelijk terug te vinden.",
      )
    }}
  </div>
  <PhoneNumberField v-model="telephone" :error="telephoneError"></PhoneNumberField>
  <AutocompleteAddress
    v-show="!isCompany"
    v-model:zipcode="zipcode"
    v-model:city="city"
    v-model:street="street"
    v-model:streetnumber="streetnumber"
    v-model:country="country"
    class="!#mt-12"
    :zipcode-error="zipcodeError"
    :city-error="cityError"
    :street-error="streetError"
    :streetnumber-error="streetnumberError"
    :country-error="countryError"
  ></AutocompleteAddress>
</template>
