<script setup>
import { __ } from "../composables/lang";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import OrderOption from "../classes/OrderOption";
import { useOrderForm } from "../../modules/orderModule";
import { computed } from "vue";
import SizeA2Option from "./SizeA2Option.vue";
import SizeA1Option from "./SizeA1Option.vue";
import SizeA0Option from "./SizeA0Option.vue";
import SizeB1Option from "./SizeB1Option.vue";
import SizeB0Option from "./SizeB0Option.vue";
import SizeAbriOption from "./SizeAbriOption.vue";

defineOptions(
  new OrderOption({
    key: "papertype",
    value: "250",
    label: __("zeer stevig (wit 250)"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled: cms_disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }

  return form.isChecked(SizeA2Option) ||
    form.isChecked(SizeA1Option) ||
    form.isChecked(SizeA0Option) ||
    form.isChecked(SizeB1Option) ||
    form.isChecked(SizeB0Option) ||
    form.isChecked(SizeAbriOption);
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
