import { sprintf as sprintfNative } from "sprintf-js";

export function __(key) {
  let source = null;
  if (import.meta.env.SSR) {
    source = global.translations || {};
  } else {
    source = window.translations;
  }

  var translation = source[key];
  if (translation === undefined) {
    return key;
  }
  return translation;
}

export function sprintf(...args) {
  return sprintfNative(...args);
}
