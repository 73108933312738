<template>
  <form action="POST" @submit.prevent>
    <InputField
      v-model="email"
      :error="firstError('login_email') || firstError('__generic__')"
      class="#w-full #mb-6"
      :label="__('E-mail')"
      type="text"
      :required="true"
      :darkmode="darkmode"
      @keyupenter="login"
    />

    <InputField
      v-model="password"
      :error="firstError('login_password')"
      :label="__('Wachtwoord')"
      type="password"
      :required="true"
      :darkmode="darkmode"
      @keyupenter="login"
    />

    <input v-model="remember" class="#mt-6" type="checkbox" />
    <label class="#ml-4 #mb-0 #cursor-pointer #select-none" @click="remember = !remember">{{
      __("Onthoud mij")
    }}</label>
    <p class="#mt-4">
      <a class="hover:!#text-vividmagenta #transition-all" :href="route('register')"
        ><span
          class="#border-0 #border-b-[1px] #border-dotted #border-inkdropdark hover:#border-b-[1px] hover:#border-dotted hover:#border-vividmagenta"
          >{{ __("Registreer") }}</span
        ></a
      >
      |
      <a class="hover:!#text-vividmagenta #transition-all" :href="route('password.request')"
        ><span
          class="#border-0 #border-b-[1px] #border-dotted #border-inkdropdark hover:#border-b-[1px] hover:#border-dotted hover:#border-vividmagenta"
          >{{ __("Wachtwoord vergeten?") }}</span
        ></a
      >
    </p>
    <Button
      :disabled="submitting"
      class="#mt-8"
      :class="{ '#w-full #text-center #mt-[30px]': isLoginPage() }"
      :text="__('Inloggen')"
      :size="isLoginPage() ? 'lg' : 'sm'"
      @click="login"
    >
    </Button>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { lang, form } from "../mixins";
import Button from "../components/Button.vue";
import InputField from "../components/InputField.vue";

export default {
  components: {
    Button,
    InputField,
  },
  mixins: [lang, form],
  props: {
    location: {
      type: String,
      default: "",
      required: false,
    },
    darkmode: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      submitting: false,
      remember: false,
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters(["user", "route"]),
  },
  watch: {
    email(newValue) {
      if (newValue) {
        this.clearAllErrors();
      }
    },
    password(newValue) {
      if (newValue) {
        this.clearAllErrors();
      }
    },
  },
  methods: {
    async login() {
      this.submitting = true;

      const body = {
        login_email: this.email,
        login_password: this.password,
      };
      if (this.remember) {
        body.remember = 1;
      }

      if (this.isOrderPage()) {
        body.redirectRoute = "article2";
      } else {
        body.redirectRoute = "homepage";
      }

      let response = await fetch(this.route("login"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();
        window.location = data.redirectUrl;
      } else if (response.status === 422) {
        const data = await response.json();
        this.formErrors = data.errors;
        this.submitting = false;
      } else {
        await response.text();
      }
    },
    isLoginPage() {
      return this.location === "loginpage";
    },
    isOrderPage() {
      return this.location === "article2";
    },
    borderColor(field) {
      return {
        "#border-[#d9534f]": this.hasError(field),
        "#border-neutral-400": !this.hasError(field),
      };
    },
  },
};
</script>
