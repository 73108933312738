<script setup>
import OrderOption from "../classes/OrderOption";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";

defineOptions(
  new OrderOption({
    key: "size",
    value: "a0",
    label: "A0 (841x1189)",
    showPriceDiff: true,
    updateUrl: true,
  }),
);

const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

check(isRequired());
check(isOneOf(props.choices));
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
