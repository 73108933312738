<script setup>
import { getCurrentInstance, watch } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import { useStore } from "vuex";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "file_has_trimbox",
  }),
);
const props = defineProps({ initial: { type: [String, Number, Boolean], required: true } });
const option = getCurrentInstance().type;

const store = useStore();
const form = useOrderForm();

watch(
  [store.state.order.fileinfo, () => form.isAnalyzing()],
  ([{ doc: { has_trimbox = props.initial } = {} }, analyzing]) => {
    if (analyzing || form.getValue(option) === has_trimbox) {
      return;
    }

    form.updateItem({ option, value: has_trimbox });
  },
  { immediate: import.meta.env.SSR ? true : false },
);
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="bit" />
</template>
