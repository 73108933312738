<script setup>
import { removeLetters, removeSpaces } from "../helpers";
import { __ } from "../order/composables/lang";
import InputField from "./InputField.vue";

const emit = defineEmits(["update:modelValue"]);

const updateModelValue = (value) => {
  value = removeLetters(value);
  value = removeSpaces(value);
  emit("update:modelValue", value);
};

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
</script>
<template>
  <InputField
    :model-value="modelValue"
    :label="__('Telefoonnummer')"
    @update:model-value="(value) => updateModelValue(value)"
  >
    <slot></slot
  ></InputField>
</template>
