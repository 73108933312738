<script setup>
import { getCurrentInstance, watch } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import { useStore } from "vuex";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "default_doc",
  }),
);
defineProps({ initial: { type: [String, Number, Boolean], required: true } });
const option = getCurrentInstance().type;

const store = useStore();
const form = useOrderForm();

watch(store.state.order.form, ({ filenames }) => {
  let default_doc = filenames ? 0 : 1;

  if (form.getValue(option) === default_doc) {
    return;
  }

  form.updateItem({ option, value: default_doc });
});
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="bit" />
</template>
