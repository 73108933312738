<script setup>
import OrderOption from "../classes/OrderOption";
import { __ } from "../composables/lang";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { computed } from "vue";
import FilePageCountOption from "./FilePageCountOption.vue";
import { useOrderForm } from "../../modules/orderModule";
import { FatalFormError } from "../composables/errors";

defineOptions(
  new OrderOption({
    key: "printside",
    value: "double",
    label: __("dubbelzijdig"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps({
  ...useRadioOptionProps(),
  disableIfOneCopy: {
    type: Boolean,
    default: false,
  },
});
const { option, check, disabled: cms_disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));

if (props.disableIfOneCopy) {
  check((option, form) => {
    if (!option.isChecked()) {
      return;
    }

    if (form.getValue(FilePageCountOption) === 1) {
      throw new FatalFormError(
        "Je hebt 1 pagina opgegeven, wij kunnen dit niet dubbelzijdig afdrukken",
      );
    }
  });
}

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }

  if (props.disableIfOneCopy) {
    console.log(form.getValue(FilePageCountOption))
    return form.getValue(FilePageCountOption) === 1;
  } else {
    return false;
  }
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
