<script setup>
import { useIntegerOptionProps } from "../composables/props";
import IntegerField from "../fields/IntegerField.vue";
import { useSetup } from "../composables/option";
import OrderOption from "../classes/OrderOption";
import { isInteger, isMinInteger, isRequired } from "../checks/basic";

defineOptions(
  new OrderOption({
    key: "copies",
    updateUrl: true,
  }),
);
const props = defineProps(useIntegerOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

check(isRequired());
check(isInteger());
check(isMinInteger(1));
</script>

<template>
  <IntegerField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
