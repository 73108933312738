<script setup>
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import PaperEcoFiberOption from "./PaperEcoFiberOption.vue";
import PaperNevertearOption from "./PaperNevertearOption.vue";
import PaperNevertearExtraOption from "./PaperNevertearExtraOption.vue";
import { computed } from "vue";
import { FatalFormError } from "../composables/errors";
import TopcoatNoneOption from "./TopcoatNoneOption.vue";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useOrderForm } from "../../modules/orderModule";
import { __, sprintf } from "../composables/lang";
import OrderOption from "../classes/OrderOption";
import infoColorGuarantee from "../checks/infoColorGuarantee";

defineOptions(
  new OrderOption({
    key: "finishing2",
    value: "coating",
    label: __("coating"),
    updateUrl: true,
  }),
);
const props = defineProps(useRadioOptionProps());
const { option, check, disabled: cms_disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));
check(infoColorGuarantee());

const unavailablePapertypes = [
  PaperEcoFiberOption,
  PaperNevertearOption,
  PaperNevertearExtraOption,
];

check((option, form) => {
  if (!option.isChecked()) {
    return;
  }

  for (const papertype of unavailablePapertypes) {
    if (form.isChecked(papertype)) {
      throw new FatalFormError(
        sprintf(__("%s kunnen wij niet voorzien van coating. Kies voor {cta}"), papertype.label),
        {
          cta: [
            {
              text: __("Geen toplaag"),
              fn: () => {
                form.selectItem(TopcoatNoneOption);
                form.touchItem({ option: TopcoatNoneOption });
              },
            },
          ],
        },
      );
    }
  }
});

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }

  for (const papertype of unavailablePapertypes) {
    if (form.isChecked(papertype)) {
      return true;
    }
  }

  return false;
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
