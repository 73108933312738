<script setup>
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import SvgUse from "../components/SvgUse.vue";
import { __ } from "./composables/lang";
import { computed, ref, watch } from "vue";
import { useOrderForm } from "../modules/orderModule";

const props = defineProps({
  open: {
    type: Boolean,
    required: false,
  },
  initialFocus: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const form = useOrderForm();

watch(
  () => props.open,
  (newValue) => {
    if (newValue === true) {
      const scrollbar = window.innerWidth - document.documentElement.clientWidth;

      document.querySelector(":root").style.setProperty("--scrollbar-width", `${scrollbar}px`);
    } else {
      document.querySelector(":root").style.setProperty("--scrollbar-width", `0px`);
    }
  },
);

const deliveryConditions = computed(() => {
  const { delivery_conditions_html_clean } = form.getCalculation();

  return delivery_conditions_html_clean;
});

defineEmits(["close"]);

const modalFocus = ref(null);
</script>

<template>
  <Dialog :open="open" :initial-focus="modalFocus" class="page-legacy" @close="$emit('close')">
    <div class="modal-backdrop" aria-hidden="true" />
    <div class="modal-container">
      <div class="modal-inner-container">
        <DialogPanel class="dialog-panel">
          <svg-use
            id="xmark"
            ref="modalFocus"
            type="solid"
            class="close-modal-button"
            @click="$emit('close')"
          ></svg-use>
          <DialogTitle class="dialog-title">{{ __("Aanlevervoorwaarden") }}</DialogTitle>
          <ul id="deliverydetails" v-html="deliveryConditions"></ul>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>

<style>
.modal-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.close-modal-button {
  position: absolute;
  box-sizing: content-box;
  top: 0px;
  right: 0px;
  padding-right: 15px;
  padding-top: 15px;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.close-modal-button:hover {
  fill: #ff367d;
}

.dialog-title {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 0 25px 0 0;
  text-align: center;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
  width: 100%;
}

.modal-container {
  z-index: 9999;
  position: fixed;
  inset: 0;
  width: 100vw;
  padding: 60px 30px;
  overflow-y: auto;
}

.modal-inner-container {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

.dialog-panel {
  position: relative;
  width: 100%;
  max-width: 778px;
  border-radius: 6px;
  background: white;
  padding: 30px;
}
</style>
