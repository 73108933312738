<template>
  <button
    ref="button"
    :class="[styling, { waiting: disabled }]"
    :disabled="disabled"
    type="submit"
    :style="fixed"
    class="#font-medium #inline-block #rounded-md #border-[1px] #border-solid #cursor-pointer #min-w-max hover:!#border-[1px] hover:!#border-b-[1px] hover:#border-solid hover:#opacity-100"
    @click="$emit('click')"
  >
    <span v-if="disabled" class="spinner">
      <span class="rect1" />
      <span class="rect2" />
      <span class="rect3" />
    </span>
    <template v-else>
      <span :class="{ 'max-[350px]:#hidden': icon === 'angle-left' }">
        {{ text }}
      </span>
      <slot></slot>
      <svg-use
        v-if="icon === 'angle-right'"
        id="angle-right"
        type="regular"
        class="#inline-block #mt-[-3px] #mr-[5px] #w-[0.75em] #h-[1em] #fill-white"
      />
    </template>
  </button>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    btnClass: {
      type: String,
      default: "primary",
    },
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "sm",
    },
  },
  emits: ["click"],
  data() {
    return {
      width: null,
      height: null,
    };
  },
  computed: {
    fixed() {
      if (!this.disabled) {
        return {};
      }
      return {
        width: this.width + "px !important",
        height: this.height + "px !important",
      };
    },
    styling() {
      return {
        "#bg-brightcyan #text-white #border-[#1fc5ff] hover:#bg-[#05bfff] hover:!#text-white hover:#border-[#00a7e0]":
          this.btnClass === "primary",
        "#bg-vividmagenta #text-white #border-[#ff1c6d] hover:#bg-[#ff035c] hover:!#text-white hover:#border-[#de004f]":
          this.btnClass === "secondary",
        "#bg-inkdroplight #text-inkdropdark #border-transparent hover:#bg-[#dbdbdf] hover:#text-inkdropdark hover:#border-transparent":
          this.btnClass === "grey",
        "#bg-white #text-[#636363] #border #border-transparent hover:#bg-inkdroplight hover:#text-[#636363] hover:#border-transparent":
          this.btnClass === "white",
        "#text-lg #px-[18px] #py-[12px]": this.size === "lg",
        "#px-[12px] #py-[6px]": this.size === "sm",
        "#text-lg #px-[18px] #py-[12px] #w-full": this.size === "full-lg",
        "#px-[12px] #py-[6px] #w-full": this.size === "full-sm",
      };
    },
  },
  watch: {
    disabled() {
      this.height = this.$refs.button.offsetHeight;
      this.width = this.$refs.button.offsetWidth;
    },
  },
};
</script>

<style></style>
