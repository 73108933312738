<template>
  <div v-if="open" class="full_width noproduct-lightbox lightbox">
    <div v-outside-click="() => (open = false)" class="grid-container">
      <div class="grid_8 nodocument" style="top: 0px">
        <h1>{{ __("Geen bestand geselecteerd") }}</h1>
        <p>{{ __("Je hebt nog geen bestand geselecteerd. Wil je het documenten nu uploaden?") }}</p>
        <a
          href="javascript:;"
          class="cancel_upload btn right response yes"
          style="line-height: 220%"
          @click.stop="no"
        >
          {{ __("Nee, ga verder") }}
        </a>
        <a
          href="javascript:;"
          class="exit_product_upload btn right pageblue open_lightbox_cc response"
          data-light="docselect"
          style="line-height: 255%"
          @click.stop="yes"
        >
          {{ __("Ja") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { __ } from "./composables/lang";
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useEmitter } from "./composables/emitter";
import { useDirectives } from "./composables/directives";

const { emit, on, off } = useEmitter();
const { vOutsideClick } = useDirectives();

// little trick to prevent closing popup on hot reloads
const open = ref(document.body.classList.contains("lightbox-open"));
watch(open, (newValue) => {
  if (newValue) {
    document.body.classList.add("lightbox-open");
  } else {
    document.body.classList.remove("lightbox-open");
  }
});

const createLegacyOverlay = () => {
  if (document.getElementById("legacyLightboxOverlay") === null) {
    var overlay = document.createElement("div");
    overlay.id = "legacyLightboxOverlay";
    document.body.appendChild(overlay);
  }
};

onMounted(() => {
  on("open-no-files-dialog", () => {
    createLegacyOverlay();
    open.value = true;
  });
});

onUnmounted(() => {
  off("open-no-files-dialog");
});

const no = () => {
  open.value = false;
  emit("accept-no-files");
};

const yes = () => {
  open.value = false;
  nextTick(() => {
    emit("open-uploader");
  });
};
</script>
