<template>
  <div ref="container" class="">
    <div
      ref="referenceElement"
      aria-describedby="tooltip"
      class="#w-max"
      @click="toggle($refs.referenceElement)"
    >
      <slot name="trigger"></slot>
    </div>
    <div
      id="tooltip"
      ref="popupElement"
      v-outside-click="($event) => handleOutsideClick($event)"
      role="tooltip"
      class="#absolute #bg-white #p-8 #shadow-popup #hidden #z-[1]"
      :class="[show ? '!#block' : '#hidden', containerClasses]"
    >
      <div
        id="arrow"
        data-popper-arrow
        class="#absolute #z-[-1] #w-6 #h-6 #bg-white before:#absolute before:#w-6 before:#h-6 before:#bg-white #invisible before:#visible before:#rotate-45"
      ></div>
      <div ref="slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import outsideClick from "../directives/outsideClick.js";

export default {
  name: "ToolTip",
  directives: {
    outsideClick,
  },
  props: {
    containerClasses: {
      type: String,
      default: "",
    },
    placement: {
      type: String,
      default: "bottom",
      required: false,
    },
  },
  data() {
    return {
      isPopupVisible: false,
      popperInstance: null,
      show: false,
      popper: null,
      anchorElement: null,
    };
  },
  methods: {
    toggle(anchor) {
      if (this.show && this.anchorElement === anchor) {
        this.show = false;
        this.popper.destroy();
        this.anchorElement = null;
      } else {
        this.anchorElement = anchor;
        this.popper = createPopper(anchor, this.$refs.popupElement, {
          placement: this.placement,
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
            {
              name: "preventOverflow",
              options: {
                mainAxis: true,
                altAxis: true,
                boundary: "clippingParents",
                padding: 10,
              },
            },
          ],
        });
        this.show = true;
        this.$nextTick(() => {
          this.popper.update();
        });
      }
    },
    closePopup() {
      this.show = false;
      this.popper.destroy();
    },
    handleOutsideClick(e) {
      if (this.show === false) {
        return;
      }

      const path = e.composedPath ? e.composedPath() : e.path;

      if (
        path.includes(this.$refs.referenceElement) ||
        path.includes(this.$refs.popupElement) ||
        path.includes(this.anchorElement)
      ) {
        return;
      }

      this.show = false;
    },
  },
};
</script>

<style>
#tooltip[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #arrow {
  left: -4px;
}
</style>
