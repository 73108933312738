import { InfoFormNotification } from "../composables/errors";
import { __ } from "../composables/lang";
import BorderlessOption from "../options/BorderlessOption.vue";
import FileBorderHasContentOption from "../options/FileBorderHasContentOption.vue";
import FileContentAtBottomOption from "../options/FileContentAtBottomOption.vue";
import FileContentAtLeftOption from "../options/FileContentAtLeftOption.vue";
import FileContentAtRightOption from "../options/FileContentAtRightOption.vue";
import FileContentAtTopOption from "../options/FileContentAtTopOption.vue";
import FileOrientationOption from "../options/FileOrientationOption.vue";
import SizeA4Option from "../options/SizeA4Option.vue";
import SizeA5Option from "../options/SizeA5Option.vue";

export default function () {
  return (form) => {
    if (!form.hasFileinfo()) {
      return;
    }

    if (form.getValue(FileBorderHasContentOption) == true) {
      let borderlessValue = true;
      if (
        form.isChecked(SizeA4Option) &&
        form.getValue(FileContentAtLeftOption) == false &&
        form.getValue(FileContentAtRightOption) == false &&
        form.getValue(FileOrientationOption) == "portrait"
      ) {
        borderlessValue = false;
      } else if (
        form.isChecked(SizeA4Option) &&
        form.getValue(FileContentAtBottomOption) == false &&
        form.getValue(FileContentAtTopOption) == false &&
        form.getValue(FileOrientationOption) == "landscape"
      ) {
        borderlessValue = false;
      } else if (
        form.isChecked(SizeA5Option) &&
        form.getValue(FileContentAtBottomOption) == false &&
        form.getValue(FileContentAtTopOption) == false &&
        form.getValue(FileOrientationOption) == "portrait"
      ) {
        borderlessValue = false;
      } else if (
        form.isChecked(SizeA5Option) &&
        form.getValue(FileContentAtLeftOption) == false &&
        form.getValue(FileContentAtRightOption) == false &&
        form.getValue(FileOrientationOption) == "landscape"
      ) {
        borderlessValue = false;
      } else if (!form.isChecked(BorderlessOption) && ! form.isTouched(BorderlessOption)) {
        form.selectItem(BorderlessOption);
        form.touchItem({option: BorderlessOption});
        throw new InfoFormNotification(
          __("Vanwege de layout in je document moeten wij dit randloos produceren. Daarom is de randloos optie aangevinkt."),
        );
      }

      if (borderlessValue == false && ! form.isTouched(BorderlessOption)) {
        form.touchItem({option: BorderlessOption});
        form.deleteItem(BorderlessOption);
      }
    }
  };
}
