<script setup>
import { getCurrentInstance } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "action",
  }),
);
const option = getCurrentInstance().type;

let initial = null;
if (!import.meta.env.SSR) {
  const urlParams = new URLSearchParams(window.location.search);
  initial = urlParams.get("action");
}
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="string" />
</template>
