<script setup>
import { getCurrentInstance } from 'vue';
import OrderProduct from '../classes/OrderProduct';
import HiddenOptions from '../HiddenOptions.vue';
import OptionSection from '../OptionSection.vue';
import NumberOption from '../options/NumberOption.vue';
import FileNamesOption from '../options/FileNamesOption.vue';
import { __ } from '../composables/lang';
import ColorAllOption from '../options/ColorAllOption.vue';
import ColorNoneOption from '../options/ColorNoneOption.vue';
import PrintSideSingleOption from '../options/PrintSideSingleOption.vue';
import PrintSideDoubleOption from '../options/PrintSideDoubleOption.vue';
import PaperStandardOption from '../options/PaperStandardOption.vue';
import PaperFirmOption from '../options/PaperFirmOption.vue';
import PaperExtraFirmOption from '../options/PaperExtraFirmOption.vue';
import PaperRusticOption from '../options/PaperRusticOption.vue';
import CopiesOption from '../options/CopiesOption.vue';
import DefaultOptions from '../DefaultOptions.vue';
import SizeHiddenOption from '../options/SizeHiddenOption.vue';
import { useOrderForm } from '../../modules/orderModule';
import correctCopies from '../checks/correctCopies';
import checkRatio from '../checks/checkRatio';

defineOptions(
  new OrderProduct("a4-prints", "losbladig", "A4 prints")
);

const instance = getCurrentInstance().type;

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
});

const form = useOrderForm();

form.addProductCheck(correctCopies());
form.addProductCheck(checkRatio());

</script>
<template>
  <HiddenOptions :product="props.product" :group="instance.group" />

  <SizeHiddenOption initial="a4" />

  <OptionSection :title="__('oplage')">
    <NumberOption :initial="1" />
  </OptionSection>
  <OptionSection :title="__('document')" divider>
    <FileNamesOption />
  </OptionSection>
  <OptionSection :title="__('oplage')">
    <CopiesOption :initial="1" />
  </OptionSection>
  <OptionSection :title="__('opdruk')">
    <ColorNoneOption initial />
    <ColorAllOption />
  </OptionSection>
  <OptionSection :title="__('printwijze')">
    <PrintSideSingleOption initial disable-if-two-copies />
    <PrintSideDoubleOption disable-if-one-copy />
  </OptionSection>
  <OptionSection :title="__('papiersoort')" divider>
    <PaperStandardOption initial icon="leaf" icon-color="green" />
    <PaperFirmOption />
    <PaperRusticOption new />
    <PaperExtraFirmOption />
  </OptionSection>

  <DefaultOptions />
</template>
