<script setup>
import { getCurrentInstance, watch } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import { useStore } from "vuex";
import { useOrderForm } from "../../modules/orderModule";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "file_invalid_fonts",
  }),
);
const props = defineProps({ initial: { type: [String, Number, Boolean], required: true } });
const option = getCurrentInstance().type;

const store = useStore();
const form = useOrderForm();

watch(
  [store.state.order.fileinfo, () => form.isAnalyzing()],
  ([{ doc: { invalid_fonts = props.initial } = {} }]) => {
    invalid_fonts = invalid_fonts ? 1 : 0;

    if (form.getValue(option) === invalid_fonts) {
      return;
    }

    form.updateItem({ option, value: invalid_fonts });
  },
  { immediate: import.meta.env.SSR ? true : false },
);
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="bit" />
</template>
