import * as Sentry from "@sentry/vue";
import mitt from "mitt";
import { createApp } from "vue";
import { loadStore } from "./store";
import SelfService from "./apps/SelfService.vue";
import StoreHours from "./apps/StoreHours.vue";
import ContactTelephone from "./apps/ContactTelephone.vue";
import ContactChat from "./apps/ContactChat.vue";
import ContactCallBack from "./apps/ContactCallBack.vue";
import WikiLogin from "./apps/WikiLogin.vue";
import WikiSearch from "./apps/WikiSearch.vue";
import CmsEdit from "./apps/CmsEdit.vue";
import CmsCallback from "./apps/CmsCallback.vue";
import AdminDialog from "./apps/AdminDialog.vue";
import NarrowCast from "./apps/NarrowCast.vue";
import CustomerDetails from "./apps/CustomerDetails.vue";
import HeaderProfile from "./apps/HeaderProfile.vue";
import PdcPlay from "./apps/PdcPlay.vue";
import Debug from "./apps/Debug.vue";
import RegisterPage from "./apps/RegisterPage.vue";
import RegisterCheckout from "./apps/CheckoutForm.vue";
import LoginPage from "./apps/LoginPage.vue";
import LoginCheckout from "./apps/LoginCheckout.vue";
import TrustpilotHomepage from "./apps/TrustpilotHomepage.vue";
import TrustpilotProducts from "./apps/TrustpilotProducts.vue";
import ResetPassword from "./apps/ResetPassword.vue";
import ChangePassword from "./apps/ChangePassword.vue";
import WaitingListPage from "./apps/WaitingListPage.vue";
import WaitingListCheckout from "./apps/WaitingListCheckout.vue";
import ProductCarousel from "./apps/ProductCarousel.vue";
import NarrowcastsOverview from "./apps/NarrowcastsOverview.vue";
import FeedbackForm from "./apps/FeedbackForm.vue";
import UpdatePhoneNumberForm from "./apps/UpdatePhoneNumberForm.vue";
import PaymentPage from "./apps/PaymentPage.vue";
import IntercomApp from "./apps/IntercomApp.vue";
import OrderOverviewPage from "./apps/OrderOverviewPage.vue";
import OrderStatusPage from "./apps/OrderStatusPage.vue";
import PhotoBooth from "./apps/PhotoBooth.vue";
import OrderArticle from "./apps/OrderArticle.vue";
import LightBox from "./apps/LightBox.vue";
import AccountMergePage from "./apps/AccountMergePage.vue";
import NewsOverview from "./apps/NewsOverview.vue";
import OrderInfo from "./order/OrderInfo.vue";
import OrderUploader from "./order/OrderUploader.vue";
import SaveOrder from "./order/SaveOrder.vue";
import UpdateFavorite from "./order/UpdateFavorite.vue";
import NoFilesDialog from "./order/NoFilesDialog.vue";

const apps = {
  "contact-telephone": ContactTelephone,
  "contact-callback": ContactCallBack,
  "contact-chat": ContactChat,
  selfservice: SelfService,
  "photo-booth": PhotoBooth,
  storehours: StoreHours,
  "wiki-login": WikiLogin,
  "wiki-search": WikiSearch,
  "cms-edit": CmsEdit,
  "cms-callback": CmsCallback,
  "admin-dialog": AdminDialog,
  "narrow-cast": NarrowCast,
  "customer-details": CustomerDetails,
  "header-profile": HeaderProfile,
  "pdc-play": PdcPlay,
  debug: Debug,
  "register-page": RegisterPage,
  "register-checkout": RegisterCheckout,
  "login-page": LoginPage,
  "login-checkout": LoginCheckout,
  "trustpilot-homepage": TrustpilotHomepage,
  "trustpilot-products": TrustpilotProducts,
  "reset-password": ResetPassword,
  "change-password": ChangePassword,
  "waiting-list-page": WaitingListPage,
  "waiting-list-checkout": WaitingListCheckout,
  "feedback-form": FeedbackForm,
  "update-phone-number-form": UpdatePhoneNumberForm,
  "product-carousel": ProductCarousel,
  "narrowcasts-overview": NarrowcastsOverview,
  "payment-page": PaymentPage,
  "intercom-app": IntercomApp,
  "order-overview-page": OrderOverviewPage,
  "order-status-page": OrderStatusPage,
  "order-article": OrderArticle,
  "light-box": LightBox,
  "account-merge-page": AccountMergePage,
  "news-overview": NewsOverview,
  "order-info": OrderInfo,
  "order-uploader": OrderUploader,
  "save-order": SaveOrder,
  "update-favorite": UpdateFavorite,
  "no-files-dialog": NoFilesDialog,
};

window.emitter = mitt();
loadStore().then((store) => {
  for (const [id, app] of Object.entries(apps)) {
    var items = document.getElementsByClassName(`vue-app-${id}`);
    for (const [index, item] of Object.entries(items)) {
      let name = "name" in app ? app.name : id;
      if (items.length > 1) {
        name = name + " " + (parseInt(index) + 1);
      }
      const vapp = createApp({ ...app, name });
      vapp.config.globalProperties.emitter = window.emitter;

      const dsn = store.getters.config("services.sentry.js_dsn");
      if (dsn) {
        Sentry.init({
          app: vapp,
          dsn: dsn,
          integrations: [],
        });
      }

      vapp.use(store).mount(item);
    }
  }

  setConfig(store.getters.user);
  setConfig(store.getters.guest);

  function setConfig(userOrGuest) {
    if (userOrGuest && userOrGuest.email) {
      let config = {
        enhanced_email: userOrGuest.email,
      };

      if (userOrGuest.telephone) {
        config.enhanced_phone = userOrGuest.telephone;
      }

      window.dataLayer.push(config);
    }
  }
});
