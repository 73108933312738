<template>
  <BaseInfo v-bind="$props" :content="dynamicContent"></BaseInfo>
</template>

<script setup>
import { computed } from "vue";
import BaseInfo from "./BaseInfo.vue";
import { useStore } from "vuex";

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  instance: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const dynamicContent = computed(() => {
  if (!store.state.order.calculation?.weight) {
    return props.instance.initial_content.value;
  }

  return props.content.replace(
    "{{ $weight }}",
    Number.parseFloat(store.state.order.calculation.weight).toFixed(1),
  );
});
</script>
