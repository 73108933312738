<script setup>
import { ref } from "vue";
import { __ } from "../order/composables/lang";
import Button from "./Button.vue";
import CustomerDetailsOption from "./CustomerDetailsOption.vue";

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
  isCompany: {
    type: Boolean,
    required: true,
  },
  shippingLabel: {
    type: [String, null],
    required: true,
  },
  error: {
    type: String,
    default: "",
  },
  submitting: {
    type: Boolean,
    default: false,
  },
  uploadLabel: {
    type: Function,
    required: true,
  },
  deleteLabel: {
    type: Function,
    required: true,
  },
  popup: {
    type: Object,
    default: () => {},
  },
});

const uploader = ref(null);
</script>
<template>
  <div>
    <CustomerDetailsOption :title="__('Verzendlabel logo')" :popup="popup">
      <input
        ref="uploader"
        type="file"
        accept="image/png, image/jpeg"
        style="display: none"
        @change="uploadLabel(uploader.files[0])"
      />
      <Button
        v-if="!shippingLabel"
        btn-class="grey"
        size="xs"
        :disabled="submitting"
        :text="__('Uploaden')"
        @click="uploader.click()"
      >
      </Button>
      <Button
        v-else
        btn-class="grey"
        size="xs"
        :disabled="submitting"
        :text="__('Verwijderen')"
        @click="deleteLabel"
      >
      </Button>
    </CustomerDetailsOption>

    <p v-if="message" class="#text-left #text-sm md:#text-base #font-semibold #text-[#2CD11A]">
      {{ message }}
    </p>

    <p v-if="error" class="#text-left #text-[#d9534f] #text-sm #font-semibold">
      {{ error }}
    </p>
    <img
      v-show="isCompany && shippingLabel"
      style="width: 100%; max-height: 100px; object-fit: contain; object-position: left"
      :src="shippingLabel"
    />
  </div>
</template>
