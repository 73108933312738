import { InfoFormWarning } from "../composables/errors";
import { __ } from "../composables/lang";

export default function () {
  return (option) => {
    if (option.isChecked()) {
      throw new InfoFormWarning(
        __("Vanwege de gekozen afwerking wordt er met toner techniek geprint en is er geen kleurgarantie")
      );
    }
  };

}
