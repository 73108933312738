<script setup>
import { ref, useTemplateRef } from "vue";
import Button from "../components/Button.vue";
import SubmitButton from "../components/SubmitButton.vue";
import ToolTip from "../components/ToolTip.vue";
import InputField from "../components/InputField.vue";
import { __ } from "./composables/lang";
import { useStore } from "vuex";
import { useOrderForm } from "../modules/orderModule";

const { user, order } = useStore().state;
const form = useOrderForm();

const mailTooltip = useTemplateRef("tooltip-sharemail");
const copyTooltip = useTemplateRef("tooltip-sharecopy");
const copyTooltipFailed = useTemplateRef("tooltip-sharecopy-failed");
const favoriteTooltip = useTemplateRef("tooltip-sharefavorite");

const createPermalink = () => {
  const queryString = window.location.search;
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(queryString);
  const params = new URLSearchParams();
  for (const [key, value] of searchParams.entries()) {
    if (
      (!key.startsWith("file_") &&
        key !== "comment" &&
        key !== "action" &&
        key !== "order_id" &&
        key !== "id" &&
        key !== "weight" &&
        key !== "thickness" &&
        key !== "production_free" &&
        key !== "extra_post" &&
        key !== "disable_preview" &&
        key !== "is_mobile" &&
        key !== "add_file_method" &&
        key !== "filenames" &&
        key !== "customer_type") ||
      (key === "filenames" && user)
    ) {
      params.append(key, value);
    }
  }

  return url.origin + url.pathname + "?" + params.toString();
};

const shareMail = (target) => {
  mailTooltip.value.toggle(target);
};

const shareCopy = async (target) => {
  let formData = new URLSearchParams();
  formData.append("url", createPermalink());
  formData.append("group_name", "permalink");

  const response = await fetch(window.BASE_URL + "/ajax/create-short-url", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formData,
  });

  if (response.ok) {
    const data = await response.text();
    navigator.clipboard
      .writeText(data)
      .then(() => {
        copyTooltip.value.toggle(target);
      })
      .catch(() => {
        copyTooltipFailed.value.toggle(target);
      });
  } else {
    copyTooltipFailed.value.toggle(target);
  }
};

const shareFavorite = async (target) => {
  let index = 0;
  let formData = new URLSearchParams();
  formData.append("f", "addToFavorites");

  for (const [key, value] of Object.entries(order.form)) {
    const option = order.options.find((option) => {
      if (option.value !== undefined) {
        return option.key === key && option.value === value;
      }
      return option.key === key;
    });
    if (option && !option.excludeFromSave) {
      formData.append("form_data[" + index + "][name]", key);
      formData.append("form_data[" + index + "][value]", value !== null ? value : "");
      index++;
    }
  }

  const response = await fetch(window.LEGACY_URL + "/inc/ajax.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formData,
  });

  if (response.ok) {
    favoriteTooltip.value.toggle(target);
  }
};

const mail = ref(null);
const sendingMail = ref(false);
const mailMessage = ref(null);
const mailError = ref(null);

const submitMail = async () => {
  if (!mail.value || mail.value.indexOf("@") === -1) {
    mailError.value = __("Geen/fout emailadres");
    return;
  }

  let formData = new URLSearchParams();
  formData.append("f", "sendSharePermalinkMail");
  formData.append("shareEmail", mail.value);
  formData.append("permalink", createPermalink());

  sendingMail.value = true;
  const response = await fetch(window.LEGACY_URL + "/inc/ajax.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formData,
  });
  sendingMail.value = false;

  if (response.ok) {
    mailMessage.value = __("De configuratie is verstuurd naar:") + " " + mail.value;
    mailError.value = null;
  } else {
    mailError.value = __("Er is iets fout gegaan");
  }
};
</script>

<template>
  <div v-if="!form.isEditingFavorite()" class="tailwind">
    <div class="#bg-inkdroplight #rounded-[6px] #p-[20px] #relative">
      <h4 class="#text-[20px] #mb-4 #font-medium">{{ __("Bewaar") }}</h4>
      <div class="#space-y-2">
        <Button
          btn-class="white"
          size="full-sm"
          alignment="left"
          @click="shareCopy($event.currentTarget)"
          >{{ __("Kopieer link") }}</Button
        >
        <Button
          btn-class="white"
          size="full-sm"
          alignment="left"
          @click="shareMail($event.currentTarget)"
          >{{ __("Via mail") }}</Button
        >
        <Button
          v-if="user"
          btn-class="white"
          size="full-sm"
          alignment="left"
          @click="shareFavorite($event.currentTarget)"
          >{{ __("Maak favoriet") }}</Button
        >
      </div>
    </div>
    <ToolTip ref="tooltip-sharecopy" container-classes="!#p-4 #rounded-[4px]" placement="top">
      <p>{{ __("Gekopieerd") }}</p>
    </ToolTip>
    <ToolTip
      ref="tooltip-sharecopy-failed"
      container-classes="!#p-4 #rounded-[4px]"
      placement="top"
    >
      <p>{{ __("Error: Niet gekopieerd") }}</p>
    </ToolTip>
    <ToolTip ref="tooltip-sharemail" container-classes="!#p-4 #rounded-[4px] #w-[270px]">
      <form
        v-if="!mailMessage"
        id="sharepermalink"
        action="POST"
        name="sharepermalink"
        class="#flex #w-full"
        @submit.prevent="submitMail"
      >
        <InputField
          id="shareEmail"
          v-model="mail"
          name="email"
          :label="__('Emailadres')"
        ></InputField>
        <SubmitButton :disabled="sendingMail">{{ __("Stuur") }}</SubmitButton>
      </form>
      <p v-else>{{ mailMessage }}</p>
      <p v-if="mailError">{{ mailError }}</p>
    </ToolTip>
    <ToolTip ref="tooltip-sharefavorite" container-classes="!#p-4 #rounded-[4px]" placement="top">
      <p>{{ __("Toegevoegd") }}</p>
    </ToolTip>
  </div>
</template>
