<script setup>
import { getCurrentInstance } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "product",
    updateUrl: true,
  }),
);
defineProps({ initial: { type: [String, Number, Boolean], required: true } });
const option = getCurrentInstance().type;
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="string" />
</template>
