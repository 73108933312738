import { createStore } from "vuex";
import lightboxModule from "./modules/lightboxModule";
import orderModule from "./modules/orderModule";

export const loadStore = async () => {
  let response = await fetch(window.SETUP_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      page: window.PAGE_ID ?? null,
    }),
  });
  response = await response.json();

  const legacy = document.getElementsByClassName("page-legacy").length !== 0;

  const store = createStore({
    modules: {
      lightbox: lightboxModule,
      order: orderModule,
    },
    state() {
      return {
        legacy,
        config: response.config,
        routes: response.routes,
        selection: response.selection,
        storehours: response.storehours,
        user: response.user,
        guest: response.guest,
        cms_user: response.cms_user,
        customer_type: response.customer_type,
        customer_id: response.customer_id,
        isAdmin: response.is_admin,
        isProduction: response.is_production,
        countries: null,
      };
    },
    getters: {
      route:
        (state) =>
        (name, params = {}) => {
          if (state.routes[name] === undefined) {
            console.warn(`Route ${name} not found`);
            return null;
          }
          let url = state.routes[name];
          for (const [key, value] of Object.entries(params)) {
            url = url.replace(`{${key}}`, value);
          }

          return url;
        },
      config:
        (state) =>
        (key = "", fallback = null) => {
          let level = state.config;
          if (key) {
            for (const part of key.split(".")) {
              try {
                if (part in level) {
                  level = level[part];
                } else {
                  return fallback;
                }
              } catch (error) {
                return fallback;
              }
            }
          }
          return level;
        },
      isProduction: (state) => () => {
        return state.isProduction;
      },
      selection(state) {
        return state.selection;
      },
      storehours(state) {
        return state.storehours;
      },
      legacy(state) {
        return state.legacy;
      },
      user(state) {
        return state.user;
      },
      guest(state) {
        return state.guest;
      },
      cms_user(state) {
        return state.cms_user;
      },
      customerType(state) {
        return state.user ? state.user.type : state.customer_type ?? null;
      },
      customerID(state) {
        return state.user ? state.user.id : state.customer_id ?? null;
      },
      isAdmin(state) {
        return state.isAdmin;
      },
      countries(state) {
        return state.countries;
      },
    },
    mutations: {
      fillCountries(state, newCountries) {
        state.countries = newCountries;
      },
    },
  });

  return store;
};
