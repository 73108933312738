<template>
  <div>
    <h3 class="#font-serif #text-lg #mb-[10px]">
      {{ __("Volg je pakket") }}
    </h3>
    <div class="#space-y-4">
      <a
        v-for="trackingcode in codes"
        :key="trackingcode.code"
        :href="trackingcode.link"
        target="_blank"
        rel="noopener noreferrer"
        class="#flex #justify-between #items-center #bg-inkdroplight #rounded-[6px] #py-3 #px-4 hover:!#text-vividmagenta"
      >
        <p class="#text-current">{{ trackingcode.code }}</p>
        <svg-use id="angle-right" type="solid" class="#fill-current #w-[11px] #h-[17px]"></svg-use>
      </a>
    </div>
  </div>
</template>

<script>
import SvgUse from "./SvgUse.vue";
import { lang } from "../mixins";
export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    codes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
