<script setup>
import { useCustomerForm } from "../composables/customer-form";
import FadeIn from "../components/FadeIn.vue";
import { useStore } from "vuex";
import Button from "../components/Button.vue";
import { __ } from "../order/composables/lang";
import RegisterForm from "../components/RegisterForm.vue";
import CheckBox from "../components/CheckBox.vue";

const store = useStore();
const form = useCustomerForm({
  password: "",
  is_guest: false,
  default_invoice_address: true,
  keep_updated: false,
});

const register = async () => {
  const response = await form.update(store.getters.route("register"));
  if (response.ok) {
    window.location = store.getters.route("homepage");
  }
};

if (store.getters.selection?.type === "waitinglist_customer_invite") {
  form.email = store.getters.selection.customer_email;
}
</script>

<template>
  <FadeIn>
    <div class="#space-y-6">
      <RegisterForm
        v-model:contact_type="form.contact_type"
        v-model:company="form.company"
        v-model:vat_number="form.vat_number"
        v-model:name_contact="form.name_contact"
        v-model:email="form.email"
        v-model:password="form.password"
        v-model:telephone="form.telephone"
        v-model:zipcode="form.zipcode"
        v-model:city="form.city"
        v-model:street="form.street"
        v-model:streetnumber="form.streetnumber"
        v-model:country="form.country"
        v-model:is-guest="form.is_guest"
        location="registerpage"
        :is-company="form.isCompany"
        :show-vat-number="form.isCompany && form.fromEurope && form.country !== 'NL'"
        :company-error="form.errors.company"
        :company-warning="form.warnings.company"
        :name-error="form.errors.name_contact"
        :email-error="form.errors.email"
        :password-error="form.errors.password"
        :telephone-error="form.errors.telephone"
        :zipcode-error="form.errors.zipcode"
        :city-error="form.errors.city"
        :street-error="form.errors.street"
        :streetnumber-error="form.errors.streetnumber"
        :country-error="form.errors.country"
      >
      </RegisterForm>
      <CheckBox v-model="form.keep_updated">
        <span>
          {{ __("Ik wil graag de nieuwsbrief van Print&Bind ontvangen") }}
        </span>
      </CheckBox>
      <Button
        btn-class="secondary"
        size="lg"
        class="#w-full !#mt-12"
        :disabled="form.submitting.details"
        @click="register"
        >{{ __("Registreren") }}</Button
      >
    </div>
  </FadeIn>
</template>
