<template>
  <div v-if="option.active" class="[&:not(&:first-child)]:#border-t #border-[#d5d5d5]">
    <RadioButton
      :model-value="selectedMethod"
      :for="option.value"
      :input-id="option.value"
      :option-value="option.value"
      :disabled="!option.active"
      class="#py-[15px] #flex #items-center #w-full"
      @update:model-value="(newValue) => $emit('update:selectedMethod', newValue)"
    >
      <div class="#group #w-full">
        <div class="#flex #w-full #justify-between #items-center">
          <div>
            <span
              class="#inline-block #ml-2"
              :class="[
                disabledStyling,
                { 'group-hover:#text-brightcyan': selectedMethod !== option.value },
              ]"
            >
              {{ option.label }}
            </span>
            <span
              v-if="option.tag"
              class="#inline-block #ml-4 #text-vividmagenta #text-sm #font-semibold"
            >
              {{ option.tag }}
            </span>
          </div>
          <div
            class="#inline #w-24 #h-14 #ml-4 #border #border-[#d5d5d5] #rounded-md #overflow-hidden #py-2 #px-4"
            :class="{ 'group-hover:#border-brightcyan': selectedMethod !== option.value }"
          >
            <img
              :src="option.image"
              alt=""
              class="#object-contain #w-full #h-full"
              :class="disabledStyling"
            />
          </div>
        </div>
      </div>
    </RadioButton>
    <p
      v-if="selectedMethod === 'account' && option.value === 'account' && onAccountMessage"
      class="#text-sm #font-medium #text-[#d9534f] #-mt-2 #mb-6 #ml-10"
      v-html="onAccountMessage"
    >
    </p>
  </div>
</template>

<script>
import CustomDropdown from "../components/CustomDropdown.vue";
import RadioButton from "../components/RadioButton.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";
import { lang } from "../mixins";

export default {
  components: {
    CustomDropdown,
    RadioButton,
    SvgUse,
  },
  mixins: [lang],
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    selectedMethod: {
      type: String,
      default: "",
    },
    onAccountStatus: {
      type: Number,
      default: 0,
    },
  },
  emits: ["update:selectedMethod"],
  data() {
    return {
      popularBanks: () => {},
      remainingBanks: () => {},
      showAll: false,
    };
  },
  computed: {
    disabledStyling() {
      return {
        "#opacity-35":
          !this.option.active || (this.option.value === "account" && this.onAccountStatus < 1),
      };
    },
    onAccountMessage() {
      if (this.onAccountStatus === 0.5) {
        return this.__(
          "De optie 'op rekening' is nog niet actief. Deze aanvraag wordt handmatig beoordeeld, dit duurt maximaal 48 uur. Duurt dit te lang? Neem dan contact op met de klantenservice (liefst via de chat).",
        );
      } else if (this.onAccountStatus === 0) {
        return this.__(
          'Alleen vaste klanten kunnen gebruik maken van de betaaloptie op rekening. Je kunt deze optie aanvragen vanuit je <a href="https://www.printenbind.nl/profiel" class="underline">profiel</a>',
        );
      } else if (this.onAccountStatus === -1) {
        return this.__(
          "De optie 'op rekening' is automatisch geblokkeerd vanwege openstaande facturen.",
        );
      }
      return "";
    },
  },
  mounted() {
    //
  },
  methods: {},
};
</script>
