<script setup>
import { computed, ref } from "vue";

const props = defineProps({
  dirty: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: "",
  },
  formErrors: {
    type: Object,
    default: () => {},
  },
  submitting: {
    type: Boolean,
    default: false,
  },
  messageStyling: {
    type: Object,
    default: () => {},
  },
  show: {
    type: Boolean,
    default: false,
  },
  showTimer: {
    type: Boolean,
    default: false,
  },
  removeFlashMessage: {
    type: Function,
    required: true,
  },
});

const element = ref(null);
const height = computed(() => {
  return element.value?.offsetHeight;
});

defineExpose({ height });
</script>
<template>
  <div
    v-if="show"
    ref="element"
    class="error-messages imperfect-rectangle #flex #gap-x-4 #justify-between #items-center #px-6 #py-4 #sticky #bottom-6 #min-h-[70px]"
    :class="messageStyling"
  >
    <slot></slot>
    <div v-if="showTimer" class="#self-start #flex #items-center #gap-x-4">
      <div class="#relative #rounded-full #overflow-hidden #w-10 #h-2 #mt-2 #bg-inkdropdark">
        <Transition appear name="timer">
          <div v-if="showTimer" class="#bg-white #w-10 #h-2 #origin-left" />
        </Transition>
      </div>
      <a
        href="javascript:"
        class="#text-[25px] #font-bold #leading-none #text-white"
        @click="removeFlashMessage"
        >x</a
      >
    </div>
  </div>
</template>
