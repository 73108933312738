<script setup>
import { computed } from "vue";
import OptionRow from "../OptionRow.vue";
import { useCheckboxOptionProps } from "../composables/props";
import { useOrderForm } from "../../modules/orderModule";
import { capitalize } from "../../helpers";
import OrderOption from "../classes/OrderOption";

const props = defineProps({
  option: {
    type: [Object],
    validator(value) {
      return value instanceof OrderOption;
    },
    required: true,
  },
  ...useCheckboxOptionProps(),
});
const form = useOrderForm();

const id = computed(() => {
  return Symbol.keyFor(props.option.id);
});

const classes = computed(() => {
  return {
    deactive: props.disabled,
  };
});

form.addOption({
  option: props.option,
  type: "checkbox",
});

const preloaded = import.meta.env.SSR
  ? global.preload[props.option.key]
  : window.preload[props.option.key];

const urlParamsObject = import.meta.env.SSR
  ? {}
  : Object.fromEntries(new URLSearchParams(window.location.search).entries());

if (preloaded && preloaded != "0" && !form.hasValue(props.option)) {
  form.updateItem({
    option: props.option,
    value: preloaded,
  });
  form.touchItem({ option: props.option, programmatically: true });
} else if (!form.isEditing() && urlParamsObject[props.option.key]) {
  form.updateItem({
    option: props.option,
    value: urlParamsObject[props.option.key],
  });
} else if (!form.isEditing() && urlParamsObject[props.option.key] === undefined && props.initial) {
  form.updateItem({
    option: props.option,
    value: props.option.value,
  });
}

const update = (event) => {
  if (event.target.checked) {
    form.selectItem(props.option);
  } else {
    form.deleteItem(props.option);
  }
  form.touchItem({ option: props.option });
};

const checked = computed(() => {
  return form.isChecked(props.option);
});
</script>

<template>
  <OptionRow v-show="!hidden" v-bind="$props">
    <div class="checkbox fancy-checkbox">
      <input
        :id="id"
        type="checkbox"
        :value="option.value"
        :name="option.key"
        :checked="checked"
        @change="update"
      />
      <label class="fancy-control-ui" :for="id" />
      <label :class="classes" :for="id">{{ capitalize(option.label) }}</label>
    </div>
  </OptionRow>
</template>
