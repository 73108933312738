<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import PriceDetailRow from "./PriceDetailRow.vue";
import PriceSection from "./PriceSection.vue";
import { __ } from "./composables/lang.js";

const props = defineProps({
  calculation: {
    type: Object,
    required: true,
  },
});
const store = useStore();
const withTax = store.getters.config("with_tax", 1) === 1;

const showDetails = ref(sessionStorage.toggle_details ?? "");
watch(showDetails, () => {
  sessionStorage.setItem("toggle_details", showDetails.value);
});

const toggleDetails = () => {
  if (showDetails.value === "active") {
    showDetails.value = "";
  } else {
    showDetails.value = "active";
  }
};

const formatMoney = (val, digits = 2) => {
  if (Number.isNaN(Number(val))) {
    return "";
  }

  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: digits,
  })
    .format(val)
    .replace("€", "")
    .trim();
};

const totalPrice = computed(() => {
  const { price_total_incl, price_total } = props.calculation;
  if (withTax) {
    return formatMoney(price_total_incl);
  }

  return formatMoney(price_total);
});

const priceOfOneExample = computed(() => {
  const { price_1_exemp, taxrate } = props.calculation;

  if (withTax) {
    let tax = price_1_exemp * (parseFloat(taxrate) / 100);

    return formatMoney(price_1_exemp + tax, 3);
  }

  return formatMoney(price_1_exemp, 3);
});

const deliveryDate = computed(() => {
  const {
    delivery_data: { day_text_nl, time, delivery_method, day, month_nl, days },
  } = props.calculation;

  let date = day_text_nl;

  if (delivery_method === "pickup") {
    date += " " + time;
  }

  if (days > 7) {
    date += "<br>" + day + " " + month_nl;
  }

  return date;
});

const orderingTime = computed(() => {
  const {
    delivery_data: { production_method, delivery_method },
  } = props.calculation;

  var curr_date = new Date();

  if (production_method === "fast") {
    if (delivery_method == "pickup" && curr_date.getHours() > 13 && curr_date.getHours() < 22) {
      return __("bestel voor 22:00u");
    } else {
      return __("bestel voor 13:00u");
    }
  }

  return __("bestel voor 18:00u");
});

const color = computed(() => {
  const { copies, price_color, price_black, color } = props.calculation;
  let label = __("Afdruk kleur");
  let amountPerCopy = price_color ?? "";

  if (color === "none") {
    label = __("Afdruk zw/w");
    amountPerCopy = price_black;
  }

  return {
    firstLabel: label,
    secondLabel: copies > 1 ? `${copies}x ${formatMoney(amountPerCopy, 3)}` : "",
    amount: copies > 0 ? formatMoney(amountPerCopy * copies, 3) : "",
  };
});

const paper = computed(() => {
  const { price_paper } = props.calculation;

  return {
    firstLabel: __("Papier"),
    amount: formatMoney(price_paper, 3),
  };
});

const finishing2 = computed(() => {
  const { price_finishing2, finishing2, number_finishing2 } = props.calculation;
  let amount = price_finishing2;

  if (finishing2 === "none") {
    return null;
  }

  if (number_finishing2 > 1) {
    amount = amount * number_finishing2;
  }

  return {
    firstLabel: __("Coating"),
    secondLabel:
      number_finishing2 > 1 ? `${number_finishing2}x ${formatMoney(price_finishing2, 3)}` : "",
    amount: formatMoney(amount, 3),
  };
});

const finishingExtra = computed(() => {
  const { finishing_extra, price_finishing_surtax } = props.calculation;

  if (!finishing_extra) {
    return null;
  }

  return {
    secondLabel: __("toeslag"),
    amount: formatMoney(price_finishing_surtax, 3),
  };
});

const priceOfOneExampleNoTax = computed(() => {
  const { price_1_exemp } = props.calculation;

  return {
    amount: formatMoney(price_1_exemp, 3),
  };
});

const taxRate = computed(() => {
  const { price_1_exemp, taxrate } = props.calculation;
  let tax = price_1_exemp * (parseFloat(taxrate) / 100);

  return {
    secondLabel: props.calculation.taxrate ? `${__("btw")} ${props.calculation.taxrate}%` : "",
    amount: formatMoney(tax, 3),
  };
});

const copiesInclTax = computed(() => {
  const { price_1_exemp, taxrate } = props.calculation;
  let tax = price_1_exemp * (parseFloat(taxrate) / 100);

  return {
    secondLabel: __("incl. btw"),
    amount: formatMoney(price_1_exemp + tax, 3),
  };
});

const totalCopies = computed(() => {
  const { number, price_total_sub, price_1_exemp } = props.calculation;
  return {
    firstLabel: __("Exemplaren"),
    secondLabel: `${number}x ${formatMoney(price_1_exemp, 3)}`,
    amount: formatMoney(price_total_sub, 2),
  };
});

const checkDoc = computed(() => {
  const { check_doc, price_check_doc } = props.calculation;

  if (check_doc === "standard") {
    return null;
  }

  return {
    firstLabel: `${__("Controle document")} ${__("uitgebreid")}`,
    amount: formatMoney(price_check_doc, 2),
  };
});

const startUp = computed(() => {
  const { startup } = props.calculation;
  return {
    firstLabel: __("Servicekosten"),
    amount: formatMoney(startup, 2),
  };
});

const priceBeforeProduction = computed(() => {
  const { percentage_production, price_total, price_production, price_delivery } =
    props.calculation;

  if (percentage_production === 0) {
    return null;
  }

  return {
    amount: formatMoney(price_total - price_production - price_delivery, 2),
  };
});

const priceProduction = computed(() => {
  const { price_production, production_method, percentage_production } = props.calculation;

  let label = __("Spoed");

  if (production_method === "standard") {
    return null;
  }

  if (production_method === "budget") {
    label = `${__("Productie")} ${__("Budget")}`;
  }

  if (production_method === "slow") {
    label = `${__("Productie")} ${__("Extra budget")}`;
  }

  return {
    firstLabel: label,
    secondLabel: `${percentage_production}%`,
    amount: formatMoney(price_production, 2),
  };
});

const priceDelivery = computed(() => {
  const { delivery_method, price_delivery } = props.calculation;

  if (delivery_method === "pickup") {
    return null;
  }

  return {
    firstLabel: __("Verzendkosten"),
    amount: formatMoney(price_delivery, 2),
  };
});

const totalExcl = computed(() => {
  const { price_total } = props.calculation;
  return {
    secondLabel: `${__("excl.")} ${__("btw")}`,
    amount: formatMoney(price_total, 2),
  };
});

const totalTax = computed(() => {
  const { total_tax, taxrate } = props.calculation;
  return {
    secondLabel: `${__("btw")} ${taxrate}%`,
    amount: formatMoney(total_tax, 2),
  };
});

const totalIncl = computed(() => {
  const { price_total_incl } = props.calculation;
  return {
    secondLabel: `${__("incl.")} ${__("btw")}`,
    amount: formatMoney(price_total_incl, 2),
  };
});
</script>

<template>
  <div id="tablePriceDelivery">
    <div id="calculated_prices">
      <table width="100%" cellspacing="0" cellpadding="0">
        <tbody>
          <tr class="spacing-top">
            <td width="5%" class="blue bold" style="font-size: 24px">€</td>
            <td width="30%">
              <span class="bold blue" style="font-size: 24px">{{ totalPrice }}</span>
              <span style="font-size: 12px; padding-left: 5px">
                {{ withTax ? __("incl") : __("excl") }}. {{ __("btw") }}
              </span>
            </td>
            <td width="35%" style="padding-top: 14px">
              <span class="small">{{ priceOfOneExample }} {{ __("p/stuk") }}</span>
            </td>
            <td width="30%" style="text-align: right; padding-top: 14px">
              <a href="javascript:;" class="hide_show small border-bottom" @click="toggleDetails">
                {{ showDetails ? __("Verberg details") : __("Toon details") }}
              </a>
            </td>
          </tr>
          <tr class="xl-spacing-top">
            <td width="5%" class="blue bold" style="font-size: 16px">
              <span class="tailwind">
                <svg-use
                  id="calendar"
                  type="regular"
                  class="#fill-brightcyan #w-[17px] #h-[17px]"
                ></svg-use>
              </span>
            </td>
            <td width="30%" class="blue bold" style="font-size: 16px">
              <span id="delivery_date_span" v-html="deliveryDate"></span>
            </td>
            <td width="65%" colspan="2" class="small" style="padding-bottom: 0px">
              {{ orderingTime }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div id="details_table_modern" :class="showDetails ? 'grid-rows-1' : 'grid-rows-0'">
        <div class="overflow-hidden">
          <table>
            <tbody>
              <tr class="big-spacing-top spacing-bottom">
                <td class="bold" colspan="3">{{ __("Per exemplaar") }}</td>
              </tr>
              <PriceSection>
                <PriceDetailRow v-bind="color"></PriceDetailRow>
                <PriceDetailRow v-bind="paper"></PriceDetailRow>
                <PriceDetailRow v-if="finishing2" v-bind="finishing2"></PriceDetailRow>
                <PriceDetailRow v-if="finishingExtra" v-bind="finishingExtra"></PriceDetailRow>
              </PriceSection>
              <PriceSection>
                <PriceDetailRow
                  v-bind="priceOfOneExampleNoTax"
                  class="spacing-top"
                  :blue-bold="!withTax"
                ></PriceDetailRow>
                <PriceDetailRow v-bind="taxRate" class="spacing-bottom"></PriceDetailRow>
              </PriceSection>
              <PriceDetailRow
                v-bind="copiesInclTax"
                :blue-bold="withTax"
                class="big-spacing-bottom spacing-top"
              ></PriceDetailRow>
              <tr class="big-spacing-top">
                <td colspan="3" class="bold">{{ __("Totaal") }}</td>
              </tr>
              <PriceSection>
                <PriceDetailRow v-bind="totalCopies"></PriceDetailRow>
                <PriceDetailRow v-bind="checkDoc"></PriceDetailRow>
                <PriceDetailRow v-bind="startUp"></PriceDetailRow>
              </PriceSection>
              <PriceSection>
                <PriceDetailRow
                  v-if="priceBeforeProduction"
                  v-bind="priceBeforeProduction"
                ></PriceDetailRow>
                <PriceDetailRow
                  v-if="priceBeforeProduction"
                  v-bind="priceProduction"
                ></PriceDetailRow>
                <PriceDetailRow v-if="priceDelivery" v-bind="priceDelivery"></PriceDetailRow>
              </PriceSection>
              <PriceSection>
                <PriceDetailRow v-bind="totalExcl" :blue-bold="!withTax"></PriceDetailRow>
                <PriceDetailRow v-bind="totalTax"></PriceDetailRow>
              </PriceSection>
              <PriceDetailRow
                v-bind="totalIncl"
                :blue-bold="withTax"
                class="spacing-top big-spacing-bottom"
              ></PriceDetailRow>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
