<script setup>
import { useOrderForm } from "../modules/orderModule";
import { __ } from "./composables/lang";
import FileUrlOption from "./options/FileUrlOption.vue";

const form = useOrderForm();
const update = (event) => {
  if (event.target.value) {
    form.updateItem({
      option: FileUrlOption,
      value: event.target.value,
    });
  } else {
    form.updateItem({
      option: FileUrlOption,
      value: null,
    });
  }
};
</script>

<template>
  <table class="padding_correct">
    <tbody>
      <tr>
        <td>
          <p>
            {{ __("Stuur je document via") }}
            <a href="http://printenbind.wetransfer.com" target="blank">
              {{ __("WeTransfer (link)") }}
            </a>
            {{ __("en plaats de link (deze ontvang je per email van WeTransfer)") }}
          </p>
          <input
            type="text"
            :placeholder="__('WeTransfer link')"
            size="80"
            maxlength="200"
            :value="form.getValue(FileUrlOption)"
            @input="update"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
