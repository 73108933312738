<script setup>
import { computed, useSlots } from "vue";
const slots = useSlots();

const activeSlots = computed(() => {
  return slots.default().filter((slot) => slot.children !== "v-if");
});
</script>

<template>
  <component
    :is="slot"
    v-for="(slot, index) in activeSlots"
    :key="`slot_${index}`"
    :first="index === 0"
    :last="index + 1 === activeSlots.length"
  ></component>
</template>
