import BorderlessOption from "../options/BorderlessOption.vue";

export default function () {
  return (form) => {
    if (form.isChecked(BorderlessOption)) {
      // deactivateField('finishingStapling');

      // if ((getSelRadioVal('stapling') == '1-corner') || (getSelRadioVal('stapling') == '2-left'))
      //   error = _('Randloos printen is helaas niet mogelijk in combinatie met nieten');
    }
  };
}
