import { nextTick } from "vue";
import { InfoFormWarning } from "../composables/errors";
import { getPaperFormat, roundNumber } from "../composables/helpers";
import FullscreenOption from "../options/FullscreenOption.vue";
import SizeA4Option from "../options/SizeA4Option.vue";
import SizeCustomOption, {
  CustomHeightOption,
  CustomWidthOption,
} from "../options/SizeCustomOption.vue";
import { __ } from "../composables/lang";

export default function () {
  return (form, fileInfo) => {
    if (!form.hasFileinfo()) {
      return;
    }

    const {
      page: { width = null, height = null } = {},
      doc: { dimensions = null, orientation = null } = {},
    } = fileInfo;
    const size = form.size();

    var widthDifference = Math.abs(size.width - width);
    var heightDifference = Math.abs(size.height - height);
    var previewRatio = roundNumber(size.width / size.height, 3);
    // var previewRelativeRatio =
    //   size.width < size.height
    //     ? roundNumber(size.width / size.height, 3)
    //     : roundNumber(size.height / size.width, 3);
    var pageRatio = roundNumber(width / height, 3);
    // var pageRelativeRatio =
    //   width < height ? roundNumber(width / height, 3) : roundNumber(height / width, 3);
    var sameRatio = Math.abs(roundNumber(pageRatio - previewRatio, 2)) < 0.01;
    // var sameRelativeRatio =
    //   Math.abs(roundNumber(pageRelativeRatio - previewRelativeRatio, 2)) < 0.01;
    var isDifferent =
      (Math.round(size.width) != Math.round(width) && widthDifference > 1) ||
      (Math.round(size.height) != Math.round(height) && heightDifference > 1);

    var setFullscreenMessage = false;
    if (sameRatio == false && isDifferent == true) {
      setFullscreenMessage = true;
    }

    if (form.getValue(FullscreenOption) && setFullscreenMessage) {
      //
    }

    if (isDifferent && orientation != "mixed") {
      var paperFormat = getPaperFormat(Math.round(height), Math.round(width));
      var differenceType =
        dimensions == size.width + "x" + size.height || dimensions == size.height + "x" + size.width
          ? "andere orientatie"
          : "ander formaat";
      var formatMessage =
        paperFormat == SizeCustomOption
          ? width + "x" + height + "mm"
          : paperFormat.value.toUpperCase();
      var currentFormatMessage =
        form.getValue(SizeCustomOption) == "custom"
          ? size.width + "x" + size.height + "mm"
          : form.getValue(SizeCustomOption)
            ? form.getValue(SizeCustomOption).toUpperCase()
            : __("geen");
      var message =
        __("Er is ") +
        currentFormatMessage +
        __(" als formaat gekozen, maar het document heeft een ") +
        differenceType +
        __(". Corrigeren naar ") +
        formatMessage +
        "? <br><br>{cta} - {cta}";

      if (!form.isTouched(SizeCustomOption) && form.isAvailable(paperFormat)) {
        throw new InfoFormWarning(message, {
          cta: [
            {
              text: __("Ja"),
              fn: () => {
                if (paperFormat == SizeCustomOption) {
                  form.selectItem(SizeCustomOption);
                  nextTick(() => {
                    form.updateItem({
                      option: CustomWidthOption,
                      value: parseInt(width),
                    });
                    form.touchItem({ option: CustomWidthOption, programmatically: true });
                    form.updateItem({
                      option: CustomHeightOption,
                      value: parseInt(height),
                    });
                    form.touchItem({ option: CustomHeightOption, programmatically: true });
                  });
                } else {
                  form.selectItem(paperFormat);
                }
                form.touchItem({ option: SizeCustomOption, programmatically: true });
              },
            },
            {
              text: __("Nee"),
              close: true,
              fn: () => {
                form.touchItem({ option: SizeCustomOption, programmatically: true });
              },
            },
          ],
        });
      }
    }
  };
}
