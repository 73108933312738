<template>
  <div v-if="show" class="error_1 loading_bar">
    <div class="waiting-box">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
      </div>
      <span>{{ __("Je bestanden worden geanalyseerd.") }}</span>
    </div>
  </div>
  <div v-if="showLong" class="error_1 responsive analyze_takes_long">
    {{ __("Het analyseren duurt langer dan normaal en kan nog enkele minuten duren.") }}
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import { __ } from "../composables/lang";

const show = ref(false);
const showLong = ref(false);

const showTimeout = ref(null);
const showLongTimeout = ref(null);

onMounted(() => {
  showTimeout.value = setTimeout(() => {
    show.value = true;
  }, 2000);
  showLongTimeout.value = setTimeout(() => {
    showLong.value = true;
  }, 10000);
});

onBeforeUnmount(() => {
  clearTimeout(showTimeout.value);
  clearTimeout(showLongTimeout.value);
});
</script>
