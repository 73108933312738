import BorderlessOption from "../options/BorderlessOption.vue";
import SpinePrintedOption from "../options/SpinePrintedOption.vue";
import FinishingInlayOption from "../options/FinishingInlayOption.vue";
import FullscreenOption from "../options/FullscreenOption.vue";
import { __ } from "../composables/lang";
import { InfoFormNotification } from "../composables/errors";

export default function () {
  return (form) => {
    const { page: { portrait_size = null, clean_size = null } = {}, doc: { has_spreads = null } = {} } = form.getFileinfo();

    if (portrait_size == 'mixed' && clean_size == 'mixed' && !form.isChecked(SpinePrintedOption) && form.getValue(FinishingInlayOption) != 'inlay') {
      if (form.isChecked(BorderlessOption) && has_spreads == 0 && ! form.isChecked(FullscreenOption)) {
        throw new InfoFormNotification(
          __("Document bevat pagina's in afwijkend formaat. Mogelijk blijven witranden zichtbaar. Gebruik de preview om dit te controleren."),
        );
      }
      else {
        throw new InfoFormNotification(
          __("Document bevat pagina's in afwijkend formaat, wij schalen deze passend naar het juiste formaat."),
        );
      }
    }
  };
}
