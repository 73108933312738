<script setup>
import OrderOption from "../classes/OrderOption";
import { useSetup } from "../composables/option";
import { isRequired } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import { ref } from "vue";
import SelectField from "../fields/SelectField.vue";

defineOptions(
  new OrderOption({
    key: "accessory_item_photoframe_colors",
    value: "",
    label: "Fotolijst kleuren",
    showPriceDiff: true,
    updateUrl: true,
  }),
);

const props = defineProps(useRadioOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

const options = ref([
  {value: "none", label: "- selecteer -"},
  {value: "photoframe_black", label: "Zwart"},
  {value: "photoframe_white", label: "Wit"},
  {value: "photoframe_wood", label: "Eiken"},
]);

check(isRequired());
</script>

<template>
  <SelectField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
    :options="options"
    :default-option="'none'"
  />
</template>
