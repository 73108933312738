<template>
  <div class="#flex #flex-col #relative">
    <div
      class="#relative #flex #items-center"
      @mouseover="openPopup = true"
      @mouseleave="openPopup = false"
    >
      <textarea
        data-clarity-unmask="true"
        :value="modelValue"
        :name="name"
        :rows="rows"
        class="#peer #transition #resize-none #autofill:#bg-white #block #border-neutral-300 #border-[1px] #w-full #rounded-md #py-3 #px-4 #outline-none focus:#shadow-brightcyan focus:#border-brightcyan focus:#shadow-focus"
        @input="triggerInput"
      />
      <p
        class="#truncate #transition-all #absolute #whitespace-nowrap #w-max #left-4 #right-full #bg-white #px-2 #-ml-2 #rounded-md peer-focus:#text-[11px] peer-focus:#top-[-7px] peer-focus:#text-[#707070] peer-focus:#max-w-max peer-focus:#min-w-max peer-active:#top-[-7px] peer-active:#text-[11px] peer-active:#text-[#707070] #select-none #pointer-events-none #box-border"
        :class="labelStyling"
      >
        {{ label }} <span v-if="required" class="#text-vividmagenta">*</span>
      </p>
    </div>
    <p v-if="error" class="#text-[#d9534f] #text-sm #font-semibold #mt-2">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 5,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "blur"],
  data() {
    return {
      openPopup: false,
    };
  },
  computed: {
    styling() {
      return {
        "!#border-[#d9534f]": this.error,
      };
    },
    labelStyling() {
      return {
        "#text-[11px] #min-w-max #top-[-7px] #text-[#707070]": this.modelValue,
        "#text-[#d5d5d5] #w-5/6 #top-[8px]": !this.modelValue,
      };
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    triggerInput(e) {
      this.$emit("update:modelValue", e.target.value);
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
};
</script>
