import { getCurrentInstance } from "vue";
import { FatalFormError } from "./errors";
import { useStore } from "vuex";

export function useCMS(name, check) {
  let disabled = false;
  let tooltipTitle = null;
  let tooltipContent = null;

  const source = import.meta.env.SSR ? global.order_settings : window.order_settings;

  if ("option_info" in source) {
    const option_info = source.option_info.find((i) => i.option.key === name);
    if (option_info) {
      tooltipTitle = option_info.title;
      tooltipContent = option_info.content;
    }
  }

  if ("disabled" in source) {
    const disabled_info = source.disabled.find((i) => i.option.key === name);
    if (disabled_info) {
      disabled = true;

      check((option) => {
        if (!option.isChecked()) {
          return;
        }

        throw new FatalFormError(disabled_info.error_text);
      });
    }
  }

  return { disabled, tooltipTitle, tooltipContent };
}

export function useSetup(props) {
  const store = useStore();
  const option = getCurrentInstance().type;

  const check = (fn) => {
    store.commit("order/addOptionCheck", { option, section: props.section, fn });
  };

  const { disabled, tooltipTitle, tooltipContent } = useCMS(option.__name, check);

  return {
    option,
    check,
    disabled,
    tooltipTitle,
    tooltipContent,
  };
}
