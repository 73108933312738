<template>
  <a
    ref="item"
    href="javascript:;"
    class="metabutton #group #text-right #whitespace-nowrap #w-[35px] #h-[35px] #py-[10px] #relative #leading-[35px] #flex #items-center #justify-center #gap-x-3 #rounded-[6px] #transition-all hover:sm:#bg-[#3b3841]"
    :class="{ '#bg-[#3b3841]': open }"
    @click="open = !open"
  >
    <svg-use id="user" type="solid" class="#h-[1em] #w-6 #fill-white" :class="iconStyle" />
  </a>
  <div v-if="open" ref="container">
    <div
      v-if="user"
      class="metadropdown #will-change-[filter] #absolute #box-content #top-[30px] #right-[-79px] #p-8 #bg-inkdropdarklight #w-[240px] #text-base #rounded-b-md #text-white min-[400px]:#right-0 #z-[6]"
    >
      <p>
        {{ __("Hallo") }} <strong class="#font-medium">{{ user.name }}</strong>
      </p>
      <ul class="#mt-3 #space-y-2">
        <li>
          <a
            class="#flex #items-center #gap-x-2 #max-w-max #transition-all"
            :href="route('profile')"
          >
            <svg-use id="user" type="solid" class="#h-[1em] #w-[1.25em] #fill-white" />

            <span
              class="#border-dotted #border-inkdropdark #border-0 #border-b-[1px] hover:#border-white"
              >{{ __("Mijn profiel") }}</span
            >
          </a>
        </li>
        <li>
          <a
            class="#flex #items-center #gap-x-2 #max-w-max #transition-all"
            :href="route('orders')"
          >
            <svg-use id="clock" type="solid" class="#h-[1em] #w-[1.25em] #fill-white" />
            <span
              class="#border-dotted #border-inkdropdark #border-0 #border-b-[1px] hover:#border-white"
              >{{ __("Bestellingen") }}</span
            >
          </a>
        </li>
        <li>
          <a
            class="#flex #items-center #gap-x-2 #max-w-max #transition-all"
            :href="route('documents')"
          >
            <svg-use id="file-lines" type="solid" class="#h-[1em] #w-[1.25em] #fill-white" />

            <span
              class="#border-dotted #border-inkdropdark #border-0 #border-b-[1px] hover:#border-white"
              >{{ __("Documenten") }}</span
            >
          </a>
        </li>
        <li>
          <a
            class="#flex #items-center #gap-x-2 #max-w-max #transition-all"
            :href="route('favorites')"
          >
            <svg-use id="star" type="solid" class="#h-[1em] #w-[1.25em] #fill-white" />
            <span
              class="#border-dotted #border-inkdropdark #border-0 #border-b-[1px] hover:#border-white"
              >{{ __("Favorieten") }}</span
            >
          </a>
        </li>
      </ul>
      <Button :disabled="submitting" class="#mt-8" :text="__('Uitloggen')" @click="logout" />
    </div>
    <div
      v-else
      class="metadropdown #will-change-[filter] #absolute #box-content #top-[30px] #right-[-79px] #p-8 #bg-inkdropdarklight #w-[240px] #text-base #rounded-b-md #text-white min-[400px]:#right-0 #z-[6]"
    >
      <LoginForm :darkmode="true"></LoginForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { lang } from "../mixins";
import Button from "../components/Button.vue";
import LoginForm from "../components/LoginForm.vue";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    Button,
    LoginForm,
    SvgUse,
  },
  mixins: [lang],
  data() {
    return {
      open: false,
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(["user", "route"]),
    iconStyle() {
      return {
        // "group-hover:sm:#fill-inkdropdark": !this.user,
        "!#fill-[#FF367D]": this.user,
        // "!#fill-inkdropdark": !this.user && this.open,
      };
    },
  },
  watch: {
    open(newValue, oldValue) {
      this.emitter.emit("hide-navigation");
      this.emitter.emit("close-dropdown-menus");
      this.emitter.emit("cart-hide");
    },
  },
  created() {
    this.emitter.on("open-login", () => {
      this.open = true;
    });
  },
  beforeUnmount() {
    this.emitter.off("open-login");
  },
  mounted() {
    this.clickOutsideEvent = (event) => {
      if (!this.open) {
        return;
      }
      for (let ref of ["item", "container"]) {
        ref = this.$refs[ref];
        if (event.target === ref || ref.contains(event.target)) {
          return;
        }
      }
      this.open = false;
    };
    document.body.addEventListener("mousedown", this.clickOutsideEvent);
  },
  unmounted() {
    document.body.removeEventListener("mousedown", this.clickOutsideEvent);
  },
  methods: {
    logout() {
      this.submitting = true;
      window.location = this.route("logout");
    },
  },
};
</script>
