<script setup>
import CustomerForm from "../components/CustomerForm.vue";
import { useCustomerForm } from "../composables/customer-form";
import FadeIn from "../components/FadeIn.vue";
import { useStore } from "vuex";
import Button from "../components/Button.vue";
import { __ } from "../order/composables/lang";
import RegisterForm from "../components/RegisterForm.vue";
import { onBeforeUnmount } from "vue";
import { useEmitter } from "../order/composables/emitter";

const store = useStore();
const { on, off } = useEmitter();

const form = useCustomerForm({
  password: store.getters.user?.password || "",
  is_guest: store.getters.user !== null || true,
  default_invoice_address: true,
});

const update = async function () {
  if (store.getters.user !== null) {
    delete form.password;
    delete form.is_guest;
    const response = await form.update(store.getters.route("profile"));
    if (response.ok) {
      window.location = store.getters.route("article3");
    }
  } else {
    const response = await form.update(store.getters.route("register"));
    if (response.ok) {
      window.location = store.getters.route("article3");
    }
  }
};

const backToOrder = function () {
  window.location.href = store.getters.route("shoppingcart");
};

on("go-to-article3", () => {
  update();
});

onBeforeUnmount(() => {
  off("go-to-article3");
});
</script>

<template>
  <FadeIn>
    <div class="#space-y-6">
      <CustomerForm
        v-if="store.getters.user"
        v-model:contact_type="form.contact_type"
        v-model:company="form.company"
        v-model:vat_number="form.vat_number"
        v-model:name_contact="form.name_contact"
        v-model:email="form.email"
        v-model:telephone="form.telephone"
        v-model:zipcode="form.zipcode"
        v-model:city="form.city"
        v-model:street="form.street"
        v-model:streetnumber="form.streetnumber"
        v-model:country="form.country"
        :country="form.country"
        :is-company="form.isCompany"
        :show-vat-number="form.isCompany && form.fromEurope && form.country !== 'NL'"
        :company-error="form.errors.company"
        :company-warning="form.warnings.company"
        :name-error="form.errors.name_contact"
        :email-error="form.errors.email"
        :telephone-error="form.errors.telephone"
        :zipcode-error="form.errors.zipcode"
        :city-error="form.errors.city"
        :street-error="form.errors.street"
        :streetnumber-error="form.errors.streetnumber"
        :country-error="form.errors.country"
      >
      </CustomerForm>
      <RegisterForm
        v-else
        v-model:contact_type="form.contact_type"
        v-model:company="form.company"
        v-model:vat_number="form.vat_number"
        v-model:name_contact="form.name_contact"
        v-model:email="form.email"
        v-model:password="form.password"
        v-model:telephone="form.telephone"
        v-model:zipcode="form.zipcode"
        v-model:city="form.city"
        v-model:street="form.street"
        v-model:streetnumber="form.streetnumber"
        v-model:country="form.country"
        v-model:is-guest="form.is_guest"
        location="checkoutpage"
        :country="form.country"
        :is-company="form.isCompany"
        :show-vat-number="form.isCompany && form.fromEurope && form.country !== 'NL'"
        :company-error="form.errors.company"
        :company-warning="form.warnings.company"
        :name-error="form.errors.name_contact"
        :email-error="form.errors.email"
        :password-error="form.errors.password"
        :telephone-error="form.errors.telephone"
        :zipcode-error="form.errors.zipcode"
        :city-error="form.errors.city"
        :street-error="form.errors.street"
        :streetnumber-error="form.errors.streetnumber"
        :country-error="form.errors.country"
      >
      </RegisterForm>
    </div>
  </FadeIn>
  <div class="#flex #justify-between #items-stretch #w-full max-md:#mb-12">
    <Button
      class="#mt-[15px] lg:!#text-lg lg:!#px-[18px] lg:!#py-[12px] lg:#h-max"
      :text="__('Opdracht')"
      btn-class="grey"
      icon="angle-left"
      icon-type="regular"
      icon-placement="left"
      @click="backToOrder"
    />
    <Button
      class="#mt-[15px] lg:!#text-lg lg:!#px-[18px] lg:!#py-[12px] lg:#h-max"
      :text="__('Kies leverwijze')"
      btn-class="secondary"
      :disabled="form.submitting.details"
      icon="angle-right"
      icon-type="regular"
      @click="update"
    />
  </div>
</template>
