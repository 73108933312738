<script setup>
import DeliveryPickupOption from "./options/DeliveryPickupOption.vue";
import DeliveryMailOption from "./options/DeliveryMailOption.vue";
import OptionSection from "./OptionSection.vue";
import ProductionFastOption from "./options/ProductionFastOption.vue";
import ProductionStandardOption from "./options/ProductionStandardOption.vue";
import ProductionBudgetOption from "./options/ProductionBudgetOption.vue";
import ProductionExtraBudgetOption from "./options/ProductionExtraBudgetOption.vue";
import CommentOption from "./options/CommentOption.vue";
import { __ } from "./composables/lang";
import { useOrderForm } from "../modules/orderModule";
import DeliveryMethodHiddenOption from "./options/DeliveryMethodHiddenOption.vue";
import DeliveryOptionHiddenOption from "./options/DeliveryOptionHiddenOption.vue";
import ProductionMethodHiddenOption from "./options/ProductionMethodHiddenOption.vue";
const form = useOrderForm();
</script>

<template>
  <template v-if="form.isEditing() || !form.isFirstArticle()">
    <DeliveryMethodHiddenOption />
    <DeliveryOptionHiddenOption />
    <ProductionMethodHiddenOption />
  </template>
  <template v-else>
    <OptionSection :title="__('leverwijze')">
      <DeliveryPickupOption initial />
      <DeliveryMailOption />
    </OptionSection>
    <OptionSection :title="__('productie')" divider>
      <ProductionFastOption />
      <ProductionStandardOption initial />
      <ProductionBudgetOption />
      <ProductionExtraBudgetOption />
    </OptionSection>
  </template>
  <CommentOption />
</template>
