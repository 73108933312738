<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useEmitter } from "../order/composables/emitter";
import { useDirectives } from "../order/composables/directives";
import { useStore } from "vuex";
import WetransferDelivery from "./WetransferDelivery.vue";
import EmailDelivery from "./EmailDelivery.vue";
import UploadDelivery from "./UploadDelivery.vue";
import DeliveryConditions from "./DeliveryConditions.vue";
import { __ } from "./composables/lang";
import { useOrderForm } from "../modules/orderModule";
import AddFileMethodOption from "./options/AddFileMethodOption.vue";
import FileNamesOption from "./options/FileNamesOption.vue";
import FileUrlOption from "./options/FileUrlOption.vue";

const form = useOrderForm();
const store = useStore();
const { on, off } = useEmitter();
const { vOutsideClick } = useDirectives();

const delivery_method = ref("upload");
const selectedFilesBackup = ref(null);
const fileUrlBackup = ref(null);

// little trick to prevent closing popup on hot reloads
const open = ref(document.body.classList.contains("lightbox-open"));
watch(open, (newValue) => {
  if (newValue) {
    document.body.classList.add("lightbox-open");
  } else {
    document.body.classList.remove("lightbox-open");
  }
});

const createLegacyOverlay = () => {
  if (document.getElementById("legacyLightboxOverlay") === null) {
    var overlay = document.createElement("div");
    overlay.id = "legacyLightboxOverlay";
    document.body.appendChild(overlay);
  }
};

onMounted(() => {
  on("open-uploader", () => {
    createLegacyOverlay();
    open.value = true;
  });
});

onUnmounted(() => {
  off("open-uploader");
});

watch(
  () => store.state.order.form.customer_id,
  async () => {
    try {
      console.log("AJAX call: boot");
      let url = store.getters.route("files.index");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        await response.text();
        form.clearFiles();
      } else {
        form.setFiles(await response.json());
      }
    } catch (error) {
      form.clearFiles();
      console.error(error);
    }
  },
  { immediate: true },
);

const switchToUpload = () => {
  delivery_method.value = "upload";
  form.updateItem({ option: AddFileMethodOption, value: "upload" });
  if (selectedFilesBackup.value) {
    form.updateItem({ option: FileNamesOption, value: selectedFilesBackup.value });
    form.touchItem({ option: FileNamesOption });
    selectedFilesBackup.value = null;
  }

  if (!fileUrlBackup.value) {
    fileUrlBackup.value = form.getValue(FileUrlOption);
  }
  form.updateItem({ option: FileUrlOption, value: null });
};
const switchToEmail = () => {
  delivery_method.value = "email";
  form.updateItem({ option: AddFileMethodOption, value: "email" });

  if (!selectedFilesBackup.value) {
    selectedFilesBackup.value = form.getValue(FileNamesOption);
  }
  if (!fileUrlBackup.value) {
    fileUrlBackup.value = form.getValue(FileUrlOption);
  }
  form.updateItem({ option: FileUrlOption, value: null });
  form.updateItem({ option: FileNamesOption, value: null });
  form.touchItem({ option: FileNamesOption });
};
const switchToWetransfer = () => {
  delivery_method.value = "wetransfer";
  form.updateItem({ option: AddFileMethodOption, value: "wetransfer" });
  if (fileUrlBackup.value) {
    form.updateItem({ option: FileUrlOption, value: fileUrlBackup.value });
    fileUrlBackup.value = null;
  }

  if (!selectedFilesBackup.value) {
    selectedFilesBackup.value = form.getValue(FileNamesOption);
  }
  form.updateItem({ option: FileNamesOption, value: null });
  form.touchItem({ option: FileNamesOption });
};
</script>

<template>
  <div v-show="open" class="full_width docselect-lightbox lightbox">
    <div v-outside-click="() => (open = false)" class="grid_8 grid_10 absolute">
      <div id="uploadmodule">
        <div class="tab-content">
          <div id="upload" role="tabpanel" class="tab-pane active">
            <h1 id="article" style="float: left">{{ __("Voeg je document toe") }}</h1>

            <table class="tables calculate" cellpadding="0" cellspacing="0" width="80%">
              <thead>
                <tr>
                  <th width="50%">
                    <span class="article_id" style=""></span>{{ __("Aanlevermethode") }}
                  </th>
                  <th>{{ __("Aanlevervoorwaarden") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="40%" style="vertical-align: top" class="select_options">
                    <div class="radio fancy-radio">
                      <input v-model="delivery_method" type="radio" value="upload" />
                      <label class="fancy-control-ui" @click="switchToUpload"></label>
                      <label @click="switchToUpload">
                        {{ __("Upload & selecteer (tot 500mb)") }}
                      </label>
                    </div>
                    <div class="radio fancy-radio">
                      <input v-model="delivery_method" type="radio" value="email" />
                      <label class="fancy-control-ui" @click="switchToEmail"></label>
                      <label @click="switchToEmail">
                        {{ __("Via de mail (tot 25mb)") }}
                      </label>
                    </div>
                    <div class="radio fancy-radio">
                      <input v-model="delivery_method" type="radio" value="wetransfer" />
                      <label class="fancy-control-ui" @click="switchToWetransfer"></label>
                      <label @click="switchToWetransfer">
                        {{ __("WeTransfer (tot 2gb)") }}
                      </label>
                    </div>
                  </td>
                  <td width="60%">
                    <div>
                      <DeliveryConditions />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="padding: 0px">
                    <div
                      v-show="delivery_method === 'email'"
                      id="addFileMethodEmail_div"
                      class="unfold"
                    >
                      <EmailDelivery />
                    </div>

                    <div
                      v-show="delivery_method === 'wetransfer'"
                      id="addFileMethodWetransfer_div"
                      class="unfold"
                    >
                      <WetransferDelivery />
                    </div>
                    <div
                      v-show="delivery_method === 'upload'"
                      id="addFileMethodUpload_div"
                      class="unfold"
                    >
                      <UploadDelivery :open="open" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="delivery_method !== 'upload'"
          class="preview-upload-message hidden-xs"
          style="display: block"
        >
          {{ __("Alleen bij direct uploaden van bestanden wordt de preview getoond.") }}
        </div>
        <p class="exit_product_upload_p">
          <a
            href="javascript:;"
            class="exit_product_upload close_file_upload_screen btn right pageblue"
            style="line-height: 250%; margin-left: 2%; padding-left: 2%; padding-right: 2%"
            @click="() => (open = false)"
          >
            {{ __("Gereed") }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
