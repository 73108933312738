import throttle from "lodash.throttle";
import { onBeforeUnmount, onMounted, ref } from "vue";

export function useMobile() {
  const screenWidth = ref(window.innerWidth);
  const mobile = ref(false);

  const onResize = function () {
    screenWidth.value = window.innerWidth;
    mobile.value = screenWidth.value < 768;
  };
  const throttledOnResize = throttle(onResize, 100, { leading: false, trailing: true });

  onMounted(() => {
    window.addEventListener("resize", throttledOnResize);
    onResize();
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", throttledOnResize);
  });

  return { mobile, screenWidth };
}
