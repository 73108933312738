import BorderlessOption from "../options/BorderlessOption.vue";
import SpinePrintedOption from "../options/SpinePrintedOption.vue";
import { __ } from "../composables/lang";
import { InfoFormNotification } from "../composables/errors";
import SizeCustomOption from "../options/SizeCustomOption.vue";

export default function () {
  return (form) => {
    const { page: { size = null } = {} } = form.getFileinfo();

    if (size == 'mixed' && form.getValue(SizeCustomOption) != 'custom' && !form.isChecked(SpinePrintedOption)) {
      if (form.isChecked(BorderlessOption)) {
        throw new InfoFormNotification(
          __('Het document is in "letter" formaat aangeleverd, wij kunnen dit niet randloos printen. Letter formaat is het Amerikaanse A4 formaat (maar dan iets breder en minder hoog)'),
        );
      }
      else {
        throw new InfoFormNotification(
          __('Het document is in "letter" formaat aangeleverd, de witranden rondom zal iets anders op papier zijn dan op het scherm maar dit is meestal geen probleem. Letter formaat is het Amerikaanse A4 formaat (maar dan iets breder en minder hoog)'),
        );
      }
    }
  };
}
