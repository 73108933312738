<script setup>
import Button from "../components/Button.vue";
import { useCustomerForm } from "../composables/customer-form";
import FadeIn from "../components/FadeIn.vue";
import DeliveryMethodField from "../components/DeliveryMethodField.vue";
import { __ } from "../order/composables/lang";
import InputArea from "../components/InputArea.vue";
import CustomerForm from "../components/CustomerForm.vue";
import LabeledInput from "../components/LabeledInput.vue";
import { useStore } from "vuex";
import InfoPopup from "../components/InfoPopup.vue";
import CustomerDetailsOption from "../components/CustomerDetailsOption.vue";
import InputDropdown from "../components/InputDropdown.vue";
import PayOnAccount from "../components/PayOnAccount.vue";
import InvoiceEmailField from "../components/InvoiceEmailField.vue";
import UploadLabelField from "../components/UploadLabelField.vue";
import { computed, nextTick, ref, useTemplateRef, watch } from "vue";
import MessageBar from "../components/MessageBar.vue";
import SvgUse from "../components/SvgUse.vue";
import cloneDeep from "lodash.clonedeep";
import { useMobile } from "../composables/mobile";

const store = useStore();

const { screenWidth } = useMobile();

const flashTimer = ref(null);
const messageBar = useTemplateRef("messageBar");

const setIntercomIconLocation = () => {
  nextTick(() => {
    if (
      !hideDirty.value &&
      (form.isDirty || form.messages.details !== null || Object.keys(form.errors).length > 0) &&
      screenWidth.value < 1360
    ) {
      let value = messageBar.value.height + 25;
      window.emitter.emit("set-intercom-location", value);
    } else {
      window.emitter.emit("set-intercom-location", 20);
    }
  });
};

const flashMessage = (type, message) => {
  form.messages[type] = message;
  setIntercomIconLocation();
  flashTimer.value = setTimeout(() => {
    form.messages[type] = null;
    setIntercomIconLocation();
  }, 4000);
};

const removeFlashMessage = () => {
  for (const [key] of Object.entries(form.messages)) {
    form.messages[key] = null;
  }
  clearTimeout(flashTimer.value);
  setIntercomIconLocation();
};

const form = useCustomerForm(
  {
    email4invoice: store.getters.user.email4invoice || "",
    send_delivery_method: store.getters.user.send_delivery_method || "-",
    allow_sms: store.getters.user.allow_sms || false,
    preferred_locale: store.getters.user.preferred_locale || "nl",
    send_address: store.getters.user.send_address || "",
    send_country: "",
    is_guest: store.getters.user.is_guest === false ? false : true,
    kvk_number: "",
    reference_required: store.getters.user.reference_required || false,
  },
  {
    infoItems: [],
    delete_files: null,
    temp_account_payment: store.getters.user.temp_account_payment || false,
    showInvoiceMail: store.getters.user.email4invoice || false,
    shippingLabel: null,
    deleteFilesPeriods: {
      all: __("Alles"),
      one_month: __("Ouder dan 1 maand"),
      one_year: __("Ouder dan 1 jaar"),
      two_years: __("Ouder dan 2 jaar"),
    },
    messages: {
      details: null,
      label: null,
      password: null,
      pay_on_account: null,
    },
    warnings: {
      company: null,
      kvk_number: null,
    },
    locales: computed(() => {
      let locales = {};
      for (const [key, value] of Object.entries(store.getters.config("app.locales"))) {
        locales[key] = value["language"];
      }
      return locales;
    }),
  },
);

const update = async () => {
  if (form.pay_on_account === 0 && form.kvk_number && !form.warnings["kvk_number"]) {
    await requestPayOnAccount();
  }

  const response = await form.update(store.getters.route("profile"));

  if (response.ok) {
    if (response.data.success && response.data.message) {
      setTimeout(() => {
        flashMessage("details", response.data.message);
        hideDirty.value = false;
        initialForm = cloneDeep(form);
      }, 500);
    }
  }
};

let initialForm = cloneDeep(form);
const hideDirty = ref(false);

const changePassword = async () => {
  form.submitting.password = true;

  let response = await fetch(store.getters.route("password.request"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({
      email: store.getters.user.email,
    }),
  });

  if (response.ok) {
    const data = await response.json();
    if (data.success && data.message) {
      flashMessage("password", data.message);
    }
    form.submitting.password = false;
  } else if (response.status === 422) {
    const body = await response.json();
    form.errors = body.errors;
    form.submitting.password = false;
  } else {
    await response.text();
  }
};
const deleteFiles = async () => {
  let response = await fetch(store.getters.route("profile.files.delete"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({ type: form.delete_files }),
  });

  response = await response.json();

  if (response.success) {
    setTimeout(() => {
      flashMessage("details", response.message);
    }, 500);
  }
};
const requestPayOnAccount = async () => {
  form.submitting.pay_on_account = true;
  form.clearAllErrors();

  let response = await fetch(store.getters.route("form.pay_on_account"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({ kvk_number: form.kvk_number }),
  });

  if (response.ok) {
    const data = await response.json();
    if (data.success && data.message) {
      await flashMessage("pay_on_account", data.message);
      form.pay_on_account_requested = true;
    }
    form.submitting.pay_on_account = false;
  } else if (response.status === 422) {
    const body = await response.json();
    form.errors = body.errors;
    form.submitting.pay_on_account = false;
  } else {
    await response.text();
  }
};
const uploadLabel = async (file) => {
  form.submitting.label = true;
  form.clearAllErrors();

  var data = new FormData();
  data.append("shipping_label_logo", file);

  let response = await fetch(store.getters.route("form.upload_shipping_label_logo"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: data,
  });

  if (response.ok) {
    const data = await response.json();
    if (data.success && data.message) {
      await flashMessage("label", data.message);
      getShippingLabel();
    }
    form.submitting.label = false;
  } else if (response.status === 422) {
    const body = await response.json();
    form.errors = Object.fromEntries(
      Object.entries(body.errors).map(([key, value]) => [key, value[0]]),
    );
    form.submitting.label = false;
    setIntercomIconLocation();
  } else {
    await response.text();
  }
};
const deleteLabel = async () => {
  form.submitting.label = true;

  let response = await fetch(store.getters.route("form.remove_shipping_label_logo"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({}),
  });

  if (response.ok) {
    const data = await response.json();
    if (data.success && data.message) {
      await getShippingLabel();
      flashMessage("label", data.message);
    }
    form.submitting.label = false;
  } else if (response.status === 422) {
    const body = await response.json();
    form.errors = body.errors;
    form.submitting.label = false;
  } else {
    await response.text();
  }
};

const getInfoItems = async () => {
  const response = await fetch(store.getters.route("get_info_items"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    body: JSON.stringify({
      items: [
        "newsletter",
        "smsnotifications",
        "billing",
        "shippinglabel",
        "papersample",
        "orderonaccount",
      ],
    }),
  });
  if (response.ok) {
    form.infoItems = await response.json();
  } else {
    await response.text();
  }
};

const getLocales = () => {
  for (const [key, value] of Object.entries(store.getters.config("app.locales"))) {
    form.locales[key] = value["language"];
  }
};

const getShippingLabel = async () => {
  let response = await fetch(store.getters.route("shipping_label"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  if (response.ok) {
    const data = await response.json();
    if (data.success && data.link) {
      form.shippingLabel = data.link;
    } else {
      form.shippingLabel = null;
    }
  } else {
    await response.text();
  }
};

const paperSamples = () => {
  window.open(store.getters.route("legacy", { "path?": "article?product=samples" }), "_blank");
};

getInfoItems();
getLocales();
getShippingLabel();

watch(
  [() => form.keep_updated, () => form.allow_sms, () => form.reference_required],
  () => {
    hideDirty.value = true;
    update();
  },
  { deep: true },
);

watch([() => form.kvk_number, () => form.company], () => {
  if (form.isDirty && form.company !== initialForm.company) {
    form.warnings.kvk_number = __(
      "Je moet eerst de nieuwe bedrijfsnaam opslaan voordat je een KVK nummer kan opslaan.",
    );
  } else {
    form.warnings.kvk_number = null;
  }
});

watch(
  () => form.data(),
  () => {
    removeFlashMessage();
    setIntercomIconLocation();
  },
  { deep: true },
);

const messageStyling = computed(() => {
  return {
    "#bg-vividmagenta": form.isDirty === true,
    "#bg-brightcyan": form.messages.details || form.messages.label,
    "!#bg-[#d9534f]": Object.keys(form.errors).length > 0 && form.isDirty === false,
  };
});
</script>
<template>
  <FadeIn>
    <div class="tailwind">
      <div class="#relative #flex #flex-col md:#flex-row #mt-6">
        <div class="#grow #basis-3/5 md:#pr-[30px] md:#border-r-[1px] md:#border-neutral-300">
          <div class="#max-w-[500px] #space-y-6">
            <CustomerForm
              v-model:contact_type="form.contact_type"
              v-model:company="form.company"
              v-model:vat_number="form.vat_number"
              v-model:name_contact="form.name_contact"
              v-model:email="form.email"
              v-model:telephone="form.telephone"
              v-model:zipcode="form.zipcode"
              v-model:city="form.city"
              v-model:street="form.street"
              v-model:streetnumber="form.streetnumber"
              v-model:country="form.country"
              :is-company="form.isCompany"
              :show-vat-number="form.isCompany && form.fromEurope"
              :company-error="form.errors.company"
              :company-warning="form.warnings.company"
              :name-error="form.errors.name_contact"
              :email-error="form.errors.email"
              :telephone-error="form.errors.telephone"
              :zipcode-error="form.errors.zipcode"
              :city-error="form.errors.city"
              :street-error="form.errors.street"
              :streetnumber-error="form.errors.streetnumber"
              :country-error="form.errors.country"
            >
            </CustomerForm>
            <h2 class="#text-lg #font-semibold #mb-6 #mt-12">
              {{ __("Standaard bezorggevens") }}
            </h2>
            <DeliveryMethodField
              v-model="form.send_delivery_method"
              :error="form.errors.country"
            ></DeliveryMethodField>
            <InputArea v-model="form.send_address" :label="__('Afleveradres')" :rows="4" />
          </div>
        </div>

        <div class="#grow #basis-2/5 #space-y-6 md:#pl-[20px] md:#min-w-[335px]">
          <div v-if="form.isCompany" class="#space-y-6">
            <h2 class="#text-lg #font-semibold">
              {{ __("Bestelopties") }}
            </h2>
            <PayOnAccount
              v-if="form.is_guest === false || form.temp_account_payment"
              v-model:kvk_number="form.kvk_number"
              :info-item="form.infoItems.orderonaccount"
              :pay-on-account="form.pay_on_account"
              :pay-on-account-requested="form.pay_on_account_requested"
              :temp-account-payment="form.temp_account_payment"
              :company="form.company"
              :submitting="form.submitting.pay_on_account"
              :message="form.messages.pay_on_account"
              :kvk-error="form.errors.kvk_number"
              :kvk-warning="form.warnings.kvk_number"
              @submit="update"
            ></PayOnAccount>
            <InvoiceEmailField
              v-model="form.email4invoice"
              :error="form.errors.email4invoice"
              :popup="form.infoItems.billing"
            ></InvoiceEmailField>
            <LabeledInput
              v-model="form.reference_required"
              :title="__('Referentie verplicht')"
            />
            <UploadLabelField
              :is-company="form.isCompany"
              :shipping-label="form.shippingLabel"
              :error="form.errors.shipping_label_logo"
              :submitting="form.submitting.label"
              :message="form.messages.label"
              :upload-label="uploadLabel"
              :delete-label="deleteLabel"
              :popup="form.infoItems.shippinglabel"
            ></UploadLabelField>
          </div>
          <h2 class="#text-lg #font-semibold">
            {{ __("Communicatie") }}
          </h2>
          <LabeledInput
            v-model="form.allow_sms"
            :title="__('SMS Notificaties')"
            :error="form.errors.allow_sms"
            :popup="form.infoItems.smsnotifications"
          />
          <LabeledInput
            v-model="form.keep_updated"
            :title="__('Nieuwsbrief ontvangen')"
            :error="form.errors.keep_updated"
            :popup="form.infoItems.newsletter"
          />
          <h2 class="#text-lg #font-semibold">
            {{ __("Account") }}
          </h2>
          <CustomerDetailsOption
            :title="__('Papiermonsterboek')"
            :popup="form.infoItems.papersample"
          >
            <Button btn-class="grey" size="xs" @click="paperSamples">
              {{ __("Aanvragen") }}
            </Button>
          </CustomerDetailsOption>
          <div>
            <CustomerDetailsOption :title="__('Wachtwoord')">
              <Button
                btn-class="grey"
                size="xs"
                :disabled="form.submitting.password"
                :text="__('Wijzigen')"
                @click="changePassword"
              >
              </Button>
            </CustomerDetailsOption>
            <p
              v-if="form.messages.password"
              class="#text-left #text-sm md:#text-base #font-semibold #text-[#2CD11A]"
            >
              {{ form.messages.password }}
            </p>
          </div>
          <CustomerDetailsOption :title="__('Voorkeurstaal')">
            <InputDropdown
              v-model="form.preferred_locale"
              :items="form.locales"
              :error="form.errors.country"
            />
          </CustomerDetailsOption>
          <div>
            <CustomerDetailsOption :title="__('Bestanden verwijderen')">
              <InputDropdown
                v-model="form.delete_files"
                :items="form.deleteFilesPeriods"
              ></InputDropdown>
            </CustomerDetailsOption>
            <Button
              v-if="form.delete_files"
              btn-class="secondary"
              size="xs"
              class="#float-right #mt-3"
              @click="deleteFiles"
            >
              {{ __("Verwijderen") }}
            </Button>
          </div>
        </div>
      </div>
      <MessageBar
        ref="messageBar"
        class="#mt-4"
        :dirty="form.isDirty"
        :message="form.messages.details"
        :form-errors="form.errors"
        :submitting="form.submitting.details"
        :message-styling="messageStyling"
        :show="
          !hideDirty &&
          (form.isDirty || form.messages.details !== null || Object.keys(form.errors).length > 0)
        "
        :show-timer="form.messages.details && !form.isDirty"
        :remove-flash-message="removeFlashMessage"
      >
        <p v-if="form.isDirty" class="#text-white #font-semibold #text-sm #block">
          {{ __("Je wijzigingen zijn nog niet opgeslagen.") }}
        </p>
        <p
          v-else-if="form.messages.details"
          class="#text-white #font-semibold #text-sm #flex #items-center #gap-x-4"
        >
          <svg-use id="circle-check" type="solid" class="#h-[1em] #w-[17px] #fill-white" />
          {{ form.messages.details }}
        </p>
        <p
          v-else-if="Object.keys(form.errors).length > 0"
          class="#text-white #font-semibold #text-sm #flex #items-center #gap-x-2"
        >
          <svg-use id="circle-exclamation" type="solid" class="#h-[1em] #w-[17px] #fill-white" />
          {{ __("Een aantal ingevulde gegevens zijn niet juist.") }}
        </p>

        <Button
          v-if="form.isDirty"
          btn-class="grey"
          size="sm"
          class="max-md:!#px-[12px] max-md:!#py-[6px]"
          :disabled="form.submitting.details"
          @click="update"
        >
          {{ __("Opslaan") }}
        </Button>
      </MessageBar>
      <InfoPopup />
    </div>
  </FadeIn>
</template>
