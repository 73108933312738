<script setup>
import OrderOption from "../classes/OrderOption";
import { useSetup } from "../composables/option";
import { isOneOf, isRequired } from "../checks/basic";
import { useRadioOptionProps } from "../composables/props";
import RadioField from "../fields/RadioField.vue";
import { useOrderForm } from "../../modules/orderModule";
import { computed } from "vue";
import SizeA3Option from "./SizeA3Option.vue";
import SizeAbriOption from "./SizeAbriOption.vue";
import SizeB0Option from "./SizeB0Option.vue";
import SizeB1Option from "./SizeB1Option.vue";
import SizeA0Option from "./SizeA0Option.vue";
import SizePhotoframeOption from "./SizePhotoframeOption.vue";
import SizePhotoframeSizesOption from "./SizePhotoframeSizesOption.vue";
import SizeA2Option from "./SizeA2Option.vue";
import SizeA1Option from "./SizeA1Option.vue";

defineOptions(
  new OrderOption({
    key: "accessory_item",
    value: "photoframe",
    label: "Fotolijst",
    showPriceDiff: true,
    updateUrl: true,
  }),
);

const props = defineProps(useRadioOptionProps());
const { option, check, disabled: cms_disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
check(isOneOf(props.choices));

const isAllowedPhotoframeSize = () => {
  const validSizes = ["300x400", "400x500", "500x700"];
  return (
    form.isChecked(SizePhotoframeOption) &&
    validSizes.includes(form.getValue(SizePhotoframeSizesOption))
  );
};

const isAllowedSize = () => {
  return form.isChecked(SizeA2Option) || form.isChecked(SizeA1Option);
};

const disabled = computed(() => {
  if (cms_disabled) {
    return true;
  }
  if (isAllowedPhotoframeSize() || isAllowedSize()) {
    return false;
  }
  return true;
});
</script>

<template>
  <RadioField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
