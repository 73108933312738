import { FatalFormError } from "../composables/errors";
import { __, sprintf } from "../composables/lang";

export function isRequired() {
  return (option) => {
    if (option.isEmpty()) {
      throw new FatalFormError(__("Er moet een waarde ingevuld worden"), {
        api: "this field is required",
      });
    }
  };
}

export function isOneOf(choices) {
  return (option) => {
    if (option.isEmpty()) {
      return;
    }

    if (!choices.includes(option.getValue())) {
      throw new FatalFormError(
        sprintf(__("Moet 1 van de volgende waardes zijn: %s"), choices.join(", ")),
        { api: `must be one of: ${choices.join(", ")}` },
      );
    }
  };
}

export function isRequiredIfOther(other) {
  return (option, form) => {
    if (!form.isChecked(other) && option.isEmpty()) {
      return;
    }
    if (!form.isChecked(other) && !option.isEmpty()) {
      throw new FatalFormError(
        sprintf(__("Is alleen mogelijk als %s de waarde %s heeft"), other.key, other.value),
        { api: `is only possible if ${other.key} = ${other.value}: ` },
      );
    }
    if (option.isEmpty()) {
      throw new FatalFormError(
        sprintf(
          __("Er moet een waarde ingevuld worden als %s de waarde %s heeft"),
          other.key,
          other.value,
        ),
      );
    }
  };
}

export function isInteger() {
  return (option) => {
    if (option.isEmpty()) {
      return;
    }

    if (!option.isInt()) {
      throw new FatalFormError(__("Moet een getal zijn"), {
        api: `must be a number `,
      });
    }
  };
}

export function isString() {
  return (option) => {
    if (option.isEmpty()) {
      return;
    }

    if (!option.isString()) {
      throw new FatalFormError(__("Moet tekst zijn"), {
        api: `must be a text `,
      });
    }
  };
}

export function isMinString(min) {
  return (option) => {
    if (option.isEmpty() || !option.isString()) {
      return;
    }

    if (option.getValue().length < min) {
      throw new FatalFormError(sprintf(__("Moet minimaal %s characters lang zijn"), min), {
        api: `must be at least ${min} characters`,
      });
    }
  };
}

export function isMaxString(max) {
  return (option) => {
    if (option.isEmpty() || !option.isString()) {
      return;
    }

    if (option.getValue().length > max) {
      throw new FatalFormError(sprintf(__("Mag maximaal %s characters zijn"), max), {
        api: `must be at most ${max} characters`,
      });
    }
  };
}

export function isMinInteger(min) {
  return (option) => {
    if (option.isEmpty() || !option.isInt()) {
      return;
    }

    if (option.getValue() < min) {
      throw new FatalFormError(sprintf(__("Moet minimaal %s zijn"), min), {
        api: `this number must be at least ${min}`,
      });
    }
  };
}

export function isMaxInteger(max) {
  return (option) => {
    if (option.isEmpty() || !option.isInt()) {
      return;
    }

    if (option.getValue() > max) {
      throw new FatalFormError(sprintf(__("Mag maximaal %s zijn"), max), {
        api: `this number must be at most ${max}`,
      });
    }
  };
}
