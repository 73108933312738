<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useEmitter } from "../composables/emitter";
import BaseMessage from "./BaseMessage.vue";

const props = defineProps({
  instance: {
    type: Object,
    required: true,
  },
});

const highlight = ref(false);
const interval = ref(null);
const intervalCounter = ref(0);

const { on, off } = useEmitter();

onMounted(() => {
  on("highlight-error", (option) => {
    if (interval.value) {
      return;
    }

    if (option.id === props.instance.key) {
      interval.value = setInterval(() => {
        intervalCounter.value++;

        if (intervalCounter.value == 10) {
          clearInterval(interval.value);
          interval.value = null;
          intervalCounter.value = 0;
        }
        highlight.value = !highlight.value;
      }, 100);
    }
  });
});

onUnmounted(() => {
  off("highlight-error");
  if (interval.value) {
    clearInterval(interval.value);
  }
});
</script>

<template>
  <div class="error_2" :class="{ 'white-bg': highlight }">
    <BaseMessage v-bind="$props" />
  </div>
</template>
