<script setup>
import { FatalFormError } from "../composables/errors";
import { __, sprintf } from "../composables/lang";
import { useSetup } from "../composables/option";
import { useCheckboxOptionProps } from "../composables/props";
import CheckboxField from "../fields/CheckboxField.vue";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "finishing_extra",
    value: "round_corner",
    label: __("ronde hoeken"),
    showPriceDiff: true,
    updateUrl: true,
  }),
);
const props = defineProps(useCheckboxOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);

check((option) => {
  if (option.isEmpty()) {
    return;
  }

  let choices = ["round_corner"];
  if (!choices.includes(option.getValue())) {
    throw new FatalFormError(
      sprintf(__("moet 1 van de volgende waardes zijn: %s"), choices.join(", ")),
    );
  }
});
</script>

<template>
  <CheckboxField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
  />
</template>
