<template>
  <h3 class="#font-serif #text-lg #mb-[10px]">
    {{ __("Artikelen") }}
  </h3>
  <div>
    <div
      v-for="article in list"
      :key="article.id"
      class="#flex #items-start #justify-start #gap-x-[15px] #w-full #py-[30px] #border-t #border-solid #border-[#d5d5d5] first:#pt-0 first:#border-t-0"
    >
      <div class="#w-[70px] #hidden sm:#block">
        <img
          :src="article.img && !article.missing ? article.img : '/img/cart-empty-document.png'"
          alt=""
          class="#w-full"
          :class="
            article.missing || !article.img
              ? ''
              : '#cursor-pointer #transition-all #border #border-[#d5d5d5] hover:#opacity-50'
          "
          @click.stop="() => handleOpenLightbox(article)"
        />
      </div>
      <div class="#w-full #flex-1 #space-y-8 sm:#space-y-2 #grid #grid-cols-3 #gap-x-[30px]">
        <div
          :class="{
            'max-sm:#col-span-3 #col-span-2': showDetails && location !== 'payment-page',
            '#col-span-3': !showDetails,
            '#col-span-2': location === 'payment-page',
          }"
        >
          <div class="#flex #justify-start #items-baseline #gap-x-2 max-sm:#flex-wrap">
            <p class="#text-base #font-medium #min-w-max">
              {{ article.quantity }} x {{ article.product_name }}
            </p>
            <p class="#text-xs #col-span-3 #text-[#707070]">{{ __("art.") }} {{ article.id }}</p>
          </div>
          <div class="#flex #text-xs sm:#text-sm #text-[#707070] #font-normal">
            <p
              v-for="(text, i) in splitUpReference(article.reference)"
              :key="i"
              :class="{ '#line-clamp-1 #break-all': i === 0, '#whitespace-pre': i !== 0 }"
            >
              {{ text }}
            </p>
          </div>
        </div>
        <div
          v-if="showDetails"
          :class="{
            'sm:#justify-self-end max-sm:#col-span-3 max-sm:#order-last':
              location !== 'payment-page',
            '!#mt-0': location === 'payment-page',
          }"
        >
          <div
            v-if="location === 'payment-page'"
            class="#flex #justify-end #items-center #gap-x-4 sm:#justify-self-end max-sm:#col-span-3 max-sm:#order-last"
          >
            <a
              :href="`order?articleID=${article.id}&action=delete`"
              class="#p-3 #group #cursor-pointer"
            >
              <svg-use
                id="trash"
                type="solid"
                class="#w-[13.5px] #h-[13.5px] #fill-inkdropdark group-hover:#fill-red-600"
              ></svg-use>
            </a>
            <Button btn-class="grey" size="xs" :href="article.url">
              <span class="#hidden #mr-4 sm:#inline">{{ __("Wijzig") }}</span>
              <svg-use
                id="pen"
                type="solid"
                class="#w-[0.9em] #h-[0.9em] #fill-inherit #inline"
              ></svg-use>
            </Button>
          </div>
          <div v-else aria-describedby="tooltip" class="#w-max">
            <Button
              btn-class="grey"
              :text="__('Opnieuw bestellen')"
              size="xs"
              @click="
                toggleReOrder(
                  $event.currentTarget.parentElement,
                  article.id,
                  article.quantity,
                  article.missing,
                )
              "
            >
              <svg-use id="cart-shopping" type="solid" class="#h-[1em] #w-[1em] #fill-inkdropdark">
              </svg-use>
            </Button>
          </div>
        </div>
        <img
          :src="article.img && !article.missing ? article.img : '/img/cart-empty-document.png'"
          alt=""
          class="#w-full #max-w-[100px] #block #col-span-2 sm:#hidden"
          :class="
            article.missing || !article.img
              ? ''
              : '#cursor-pointer #transition-all #border #border-[#d5d5d5] hover:#opacity-50'
          "
          @click.stop="() => handleOpenLightbox(article)"
        />
        <p class="#col-span-3" v-html="article.info" />
        <div v-if="article.filenames?.length > 0" class="#col-span-3">
          <p v-for="(file, i) in article.filenames" :key="i" class="#text-xs #max-w-max">
            <a
              v-if="file.link && file.name"
              :href="file.link"
              target="_blank"
              rel="noopener noreferrer"
              class="#flex #break-words #cursor-pointer #border-b #border-dotted #border-transparent hover:#border-current hover:!#text-vividmagenta"
              :title="file.name"
            >
              <span
                v-for="(text, i) in splitUpReference(file.name)"
                :key="i"
                :class="{ '#line-clamp-1 #break-all': i === 0, '#whitespace-pre': i !== 0 }"
              >
                {{ text }}
              </span>
            </a>
          </p>
        </div>
        <div
          v-if="article.comment"
          class="#bg-inkdroplight #p-4 #text-sm #col-span-2 #rounded-[4px] #w-max #max-w-full"
        >
          <p class="#font-medium">{{ __("Opmerking") }}</p>
          <p>{{ article.comment }}</p>
        </div>
        <div v-if="location === 'payment-page' && article.specs.document" class="#col-span-3">
          <DeliveryTerms :specs="article.specs.document"></DeliveryTerms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button.vue";
import SvgUse from "../components/SvgUse.vue";
import { lang } from "../mixins";
import { createNamespacedHelpers } from "vuex";
import DeliveryTerms from "./DeliveryTerms.vue";

const { mapGetters, mapMutations } = createNamespacedHelpers("lightbox");

export default {
  components: {
    Button,
    SvgUse,
    DeliveryTerms,
  },
  mixins: [lang],
  inject: {
    toggleReOrder: {
      from: "toggleReOrder",
      default: () => {},
    },
    showDetails: {
      from: "showDetails",
      default: true,
    },
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
    location: {
      type: String,
      default: "order-overview",
    },
  },
  emits: ["createMessage"],
  data() {
    return {
      items_rendered: 5,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    list() {
      return this.articles.slice(0, this.items_rendered);
    },
    images() {
      let images = [];
      for (const article of this.articles) {
        if (!article.missing && article.img) {
          images.push(article.img);
        }
      }
      return images;
    },
  },
  mounted() {
    this.renderArticle();
  },
  updated() {
    this.renderArticle();
  },
  methods: {
    ...mapMutations(["openLightbox"]),
    renderArticle() {
      if (this.items_rendered < this.articles.length) {
        setTimeout(() => {
          this.items_rendered += 10;
        }, 1);
      }
    },

    handleOpenLightbox(article) {
      if (article.missing || !article.img) {
        return;
      }
      this.openLightbox({ img: article.img, images: this.images });
    },
    splitUpReference(ref) {
      if (ref && ref.length > 7) {
        let firstPart = ref.slice(0, ref.length - 7);
        let lastPart = ref.slice(-7);
        let newRef = [firstPart.replace(/ /g, "\u00A0"), lastPart];
        return newRef;
      }

      return ref;
    },
  },
};
</script>
