<script setup>
import { useTemplateRef, ref } from "vue";
import Button from "../components/Button.vue";
import ToolTip from "../components/ToolTip.vue";
import { __ } from "./composables/lang";
import { useStore } from "vuex";
import { useOrderForm } from "../modules/orderModule";

const { user, order } = useStore().state;
const form = useOrderForm();
const favoriteTooltip = useTemplateRef("tooltip-updatefavorite");
const message = ref("");

const updateFavorite = async (target) => {
  let index = 0;
  let formData = new URLSearchParams();
  formData.append("f", "updateFavorites");

  for (const [key, value] of Object.entries(order.form)) {
    const option = order.options.find((option) => {
      if (option.value !== undefined) {
        return option.key === key && option.value === value;
      }
      return option.key === key;
    });
    if (option && !option.excludeFromSave) {
      formData.append("form_data[" + index + "][name]", key);
      formData.append("form_data[" + index + "][value]", value !== null ? value : "");
      index++;
    }
  }
  formData.append("form_data[" + index + "][name]", "id");
  formData.append("form_data[" + index + "][value]", order.form.id);

  const response = await fetch(window.LEGACY_URL + "/inc/ajax.php", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: formData,
  });

  if (response.ok) {
    favoriteTooltip.value.toggle(target);
    message.value = await response.text();
  }
};
</script>

<template>
  <div v-if="form.isEditingFavorite()" class="tailwind">
    <div class="#bg-inkdroplight #rounded-[6px] #p-[20px] #relative">
      <h4 class="#text-[20px] #mb-4 #font-medium">{{ __("Favoriet") }}</h4>
      <div class="#space-y-2">
        <Button
          v-if="user"
          btn-class="secondary"
          size="full-sm"
          alignment="left"
          @click="updateFavorite($event.currentTarget)"
          >{{ __("Overschrijf") }}
        </Button>
      </div>
    </div>
    <ToolTip ref="tooltip-updatefavorite" container-classes="!#p-4 #rounded-[4px]" placement="top">
      <p>{{ message ?? "Opgeslagen" }}</p>
    </ToolTip>
  </div>
</template>
