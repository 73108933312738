<script setup>
import { ref } from "vue";
import { useEmitter } from "../order/composables/emitter";
import SvgUse from "./SvgUse.vue";

const { emit } = useEmitter();

const container = ref(null);

const props = defineProps({
  title: {
    type: String,
    default: "",
    required: false,
  },
  popup: {
    type: Object,
    default: null,
  },
});

const openPopupNew = function () {
  emit("open-info-popup", {
    title: props.popup.title,
    info: props.popup.info,
    placement: "left",
    element: container.value,
  });
};
const closePopupNew = function () {
  emit("close-info-popup");
};
const stayPopup = function () {
  emit("stay-info-popup", {
    title: props.popup.title,
    info: props.popup.info,
    placement: "left",
    element: container.value,
  });
};
</script>

<template>
  <div class="#group #flex #min-w-0 #items-center #justify-between #gap-x-8 #w-full">
    <div
      ref="container"
      class="#group #min-w-0 #flex #gap-x-4 #items-center #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis"
    >
      <h3
        class="#inline #min-w-0 #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
      >
        {{ title }}
      </h3>
      <div
        v-if="popup?.title && popup?.info"
        ref="fonticon"
        class="fonticon"
        @mouseenter="openPopupNew"
        @mouseleave="closePopupNew"
        @click="stayPopup"
      >
        <svg-use
          id="circle-info"
          type="solid"
          class="#h-[1em] #mt-2 md:#hidden md:group-hover:#block #block #transition-all hover:#fill-brightcyan #cursor-pointer #fill-[#a8a8a8] #w-8"
        />
      </div>
    </div>
    <slot></slot>
  </div>
</template>
