<script setup>
import VHtml from "../../components/VHtml.vue";
import { computed } from "vue";
import { capitalize } from "../../helpers";
import SvgUse from "../../components/SvgUse.vue";

const props = defineProps({
  instance: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const { section, message, cta, icon, iconType } = props.instance;

const messageParts = computed(() => {
  var output = [];
  var options = message.split("{cta}");
  let cta_count = 0;

  for (var index in options) {
    output.push({
      key: "text",
      value: options[index],
      cta: null,
    });

    if (index < options.length - 1) {
      let item = cta[cta_count];
      output.push({
        key: "cta",
        value: item.text,
        cta: item.close
          ? () => {
              item.fn();
              emit("close");
            }
          : item.fn,
      });
      cta_count++;
    }
  }

  return output;
});
</script>

<template>
  <p>
    <span class="tailwind">
      <svg-use
        v-if="icon"
        :id="icon"
        :type="iconType ?? 'solid'"
        class="#w-[17px] #h-[17px] #mr-[10px] #fill-current #inline #align-baseline"
      />
    </span>
    <b v-if="section">{{ capitalize(section) }}&nbsp;</b>
    <template v-for="(part, index) in messageParts" :key="index">
      <a v-if="part.key === 'cta'" href="javascript:;" @click="part.cta">{{ part.value }}</a>
      <v-html v-else :html="part.value" />
    </template>
  </p>
</template>
