<template>
  <div class="#flex #flex-col #gap-y-2 #text-sm" :class="textColor">
    <div v-if="priceData.basket" class="#flex #justify-between #items-start">
      <a
        :href="route('shoppingcart')"
        class="#cursor-pointer #border-b #border-dotted #border-current hover:#text-vividmagenta"
        >{{ priceData.basket.label }}</a
      >
      <p>{{ priceData.basket.value }}</p>
    </div>
    <div v-if="priceData.production && priceData.production.label && showDetails">
      <div class="#flex #justify-between #items-start">
        <p>{{ priceData.production.label }}</p>
        <p v-html="priceData.production.value"></p>
      </div>
    </div>
    <div
      class="#grid #transition-all"
      :class="showDetails ? '#grid-rows-[1fr]' : '#grid-rows-[0fr]'"
    >
      <div class="#overflow-hidden">
        <div
          v-if="priceData.subtotal && priceData.subtotal.value"
          class="#w-max #ml-auto #space-t-2 #border-t-[1px] #py-2 #border-solid #border-neutral-300"
        >
          <div
            v-if="priceData.subtotal && priceData.subtotal.label"
            class="#flex #justify-between #items-start #gap-x-6"
          >
            <p></p>
            <p class="#font-bold">{{ priceData.subtotal.value }}</p>
          </div>
        </div>
        <div
          v-if="
            priceData.discount &&
            priceData.discount.percentage &&
            priceData.discount.percentage.label
          "
          class="#flex #justify-between #items-start #pb-2"
        >
          <p>{{ priceData.discount.percentage.label }}</p>
          <p>{{ priceData.discount.percentage.value }}</p>
        </div>
        <div
          v-if="priceData.discount && priceData.discount.amount && priceData.discount.amount.label"
          class="#flex #justify-between #items-start"
        >
          <p>{{ priceData.discount.amount.label }}</p>
          <p>{{ priceData.discount.amount.value }}</p>
        </div>
      </div>
    </div>
    <div class="#grid #transition-all">
      <div
        v-if="priceData.delivery && priceData.delivery.label"
        class="#flex #justify-between #items-start"
      >
        <a
          :href="route('article3')"
          class="#cursor-pointer #border-b #border-dotted #border-current hover:#text-vividmagenta"
          >{{ priceData.delivery.label }}</a
        >
        <p v-html="priceData.delivery.value"></p>
      </div>
    </div>
    <div
      class="#grid #transition-all"
      :class="showDetails ? '#grid-rows-[1fr]' : '#grid-rows-[0fr]'"
    >
      <div class="#overflow-hidden">
        <div
          v-if="priceData.subtotal2 && priceData.subtotal2.value"
          class="#w-max #ml-auto #space-t-2 #border-t-[1px] #py-2 #border-solid #border-neutral-300"
        >
          <div
            v-if="priceData.subtotal2 && priceData.subtotal2.label"
            class="#flex #justify-between #items-start #gap-x-6"
          >
            <p></p>
            <p class="#font-bold">{{ priceData.subtotal2.value }}</p>
          </div>
        </div>
        <div
          v-if="priceData.payment && priceData.payment.label"
          class="#flex #justify-between #items-start #pb-2"
        >
          <p>{{ priceData.payment.label }}</p>
          <p>{{ priceData.payment.value }}</p>
        </div>
        <div class="#w-max #ml-auto #space-y-2 #border-y-[1px] #py-2">
          <div
            v-if="priceData.total && priceData.total.excl && priceData.total.excl.label"
            class="#flex #justify-end #items-start #gap-x-6"
          >
            <p></p>
            <p class="#font-bold">{{ priceData.total.excl.value }}</p>
          </div>
          <div
            v-if="priceData.tax && priceData.tax.reduced && priceData.tax.reduced.label"
            class="#flex #justify-end #items-start #gap-x-6"
          >
            <p class="#text-[#707070]">{{ priceData.tax.reduced.label }}</p>
            <p>{{ priceData.tax.reduced.value }}</p>
          </div>
          <div
            v-if="priceData.tax && priceData.tax.standard && priceData.tax.standard.label"
            class="#flex #justify-end #items-start #gap-x-6"
          >
            <p class="#text-[#707070]">{{ priceData.tax.standard.label }}</p>
            <p>{{ priceData.tax.standard.value }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="#group #flex #justify-end #items-center #gap-x-2 #cursor-pointer #mb-2 hover:#text-vividmagenta"
      @click="
        showDetails = !showDetails;
        $emit('intercom');
      "
    >
      <p v-if="showDetails" class="#text-xs #border-b #border-current #border-dotted #pt-5">
        {{ __("Toon minder") }}
      </p>
      <p v-else class="#text-xs #border-b #border-current #border-dotted">
        {{ __("Toon details") }}
      </p>
      <svg-use
        id="angle-down"
        type="solid"
        class="#w-[0.7em] #h-[0.7em] #fill-inkdropdark group-hover:#fill-vividmagenta"
        :class="{ 'mt-4 #rotate-180': showDetails }"
      ></svg-use>
    </div>
    <div
      v-if="priceData.total && priceData.total.incl && priceData.total.incl.label"
      class="#flex #justify-between #items-start"
    >
      <p>{{ priceData.total.incl.label }}</p>
      <p class="#font-medium" :class="totalColor">{{ priceData.total.incl.value }}</p>
    </div>
  </div>
</template>

<script>
import SvgUse from "../components/SvgUse.vue";
import { lang } from "../mixins";
import { mapGetters } from "vuex";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    priceData: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: "regular",
    },
  },
  emits: ["intercom"],
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    textColor() {
      return {
        "#text-inkdropdark": this.color === "regular",
        "#text-white": this.color === "dark",
      };
    },
    totalColor() {
      return {
        "#text-brightcyan": this.color === "regular",
        "#text-white": this.color === "dark",
      };
    },
  },
};
</script>
