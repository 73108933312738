<template>
  <div id="button-holder" style="text-align: center">
    <table class="selected_files_preview">
      <tbody>
        <tr class="file_list_top_desktop" style="display: block">
          <td colspan="5"></td>
        </tr>
        <tr
          v-for="(file, index) in selectedFiles"
          v-show="index < 5 || open"
          :key="`selected_file_${index}`"
          class="template-download_fp spacing-both upload_items_desktop hidden-xs"
        >
          <td id="file_name_td" class="file_name_td" translate="no">
            <span :title="file">{{ file }}</span>
          </td>
          <td style="text-align: right" class="uploadstatus tailwind">
            <svg-use id="check" type="solid" class="#w-[17px] #h-[17px] #fill-current #inline" />
          </td>
        </tr>
        <tr
          v-for="(progress, index) in store.state.order.fileProgress"
          :key="`uploading_${index}`"
          class="template-download_fp spacing-both upload_items_desktop hidden-xs"
        >
          <td id="file_name_td" class="file_name_td" translate="no">
            <span :title="progress.name">{{ progress.name }}</span>
            <p
              v-if="progress.status === 'error'"
              class="error text-danger"
              v-html="progress.error"
            ></p>
          </td>
          <td style="text-align: right; vertical-align: top" class="uploadstatus tailwind">
            <template v-if="progress.status === 'uploading'">
              {{ calcProgress(progress) }}%
            </template>
            <a
              v-else-if="progress.status === 'error'"
              href="javascript:;"
              @click="() => form.clearFileProgress(progress.name)"
            >
              <svg-use
                id="octagon-xmark"
                type="regular"
                class="#w-[17px] #h-[17px] #fill-current #inline"
              />
            </a>
            <svg-use
              v-else-if="['processing', 'finished'].includes(progress.status)"
              id="check"
              type="solid"
              class="#w-[17px] #h-[17px] #fill-current #inline"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="selectedFiles.length > 5"
      id="show_more_selected"
      style="display: block; display: flex; align-items: center; justify-content: center"
    >
      <a href="javascript:;" @click="open = !open">
        {{ open ? __("toon minder") : __("toon meer") }}
      </a>
      <span
        class="tailwind"
        :class="{ 'order_form-angle-up': open, 'order_form-angle-down': !open }"
        style="display: inline-flex; top: 0"
      >
        <svg-use
          :id="open ? 'angle-up' : 'angle-down'"
          type="solid"
          class="#w-[17px] #h-[15px] #fill-current #inline"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import SvgUse from "../components/SvgUse.vue";
import { computed, ref } from "vue";
import { __ } from "./composables/lang";
import { useOrderForm } from "../modules/orderModule";

const store = useStore();
const form = useOrderForm();
const open = ref(false);

const selectedFiles = computed(() => {
  let filenames = store.state.order.form.filenames ?? "";
  filenames = filenames.split("/").filter((i) => i);

  return filenames.filter((filename) => {
    return store.state.order.files.some((file) => file.name === filename);
  });
});

const calcProgress = (progress) => {
  if (progress.total === 0) {
    return 0;
  }

  return parseInt((progress.loaded / progress.total) * 100);
};
</script>
