<template>
  <a
    class="#group #flex #flex-col #min-w-full #h-[300px] #w-1/4 #rounded-[6px] #overflow-hidden sm:#min-w-[50%] lg:#min-w-[25%]"
    :href="getUrl(trustpilotId)"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div
      class="#flex #justify-between #items-center #gap-x-[5px] #p-[15px] #w-full #bg-inkdropdark"
    >
      <div class="#text-white">
        <p class="#text-base #font-medium #line-clamp-1">{{ name }}</p>
        <p class="#text-xs #text-inkdroplight #line-clamp-1">{{ timeDiff }}</p>
      </div>
      <div class="#flex #gap-x-[2px]">
        <div v-for="n in stars" :key="n" :class="getStarColor(rating)" class="#w-6 #h-6">
          <svg-use
            id="trustpilot-star"
            type="custom-icons"
            class="#w-full #h-full #fill-white #p-[2px]"
          />
        </div>
        <div v-for="n in emptystars" :key="n" class="#w-6 #h-6 #bg-[#DCDCE6]">
          <svg-use
            id="trustpilot-star"
            type="custom-icons"
            class="#w-full #h-full #fill-white #p-[2px]"
          />
        </div>
      </div>
    </div>
    <div class="#flex #flex-col #p-[15px] #bg-white #text-inkdropdark #flex-1">
      <h5
        class="#min-h-[54px] #pb-[5px] #mb-[10px] #text-base #font-bold #border-[#d5d5d5] #border-b-[1px] #line-clamp-2"
      >
        {{ title }}
      </h5>
      <p class="#text-sm #line-clamp-5">
        {{ description }}
      </p>
      <div class="#flex-1"></div>
      <a
        :href="getUrl(trustpilotId)"
        target="_blank"
        rel="noopener noreferrer"
        class="#block #opacity-0 #w-max #text-xs #text-inkdropdark #font-medium #border-b-[1px] #border-dotted #border-inkdropdark #transition-all group-hover:#opacity-100 hover:#text-vividmagenta hover:#border-vividmagenta"
      >
        {{ __("Review bekijken op Trustpilot.nl") }}
      </a>
    </div>
  </a>
</template>

<script>
import { lang } from "../mixins";
import { mapGetters } from "vuex";
import SvgUse from "../components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  mixins: [lang],
  props: {
    trustpilotId: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    timeDiff: {
      type: String,
      default: "",
    },
    rating: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    locationStyling: {
      type: String,
      default: "homepage",
    },
    score: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      starColor: "#00b67a",
      roundedScore: 0,
    };
  },
  computed: {
    ...mapGetters(["config"]),
    stars() {
      return this.rating;
    },
    emptystars() {
      return 5 - this.stars;
    },
  },
  mounted() {
    const fractionalPart = parseFloat((this.score % 1).toFixed(1));
    if (fractionalPart >= 0.8) {
      this.roundedScore = Math.ceil(this.score);
    } else if (fractionalPart >= 0.3) {
      this.roundedScore = Math.floor(this.score) + 0.5;
    } else {
      this.roundedScore = Math.floor(this.score);
    }
  },
  methods: {
    getUrl(id) {
      const prefix = this.config("app.locale") === "nl" ? "nl" : "www";

      return `https://${prefix}.trustpilot.com/reviews/${id}`;
    },
    getStarColor(number) {
      return {
        "#bg-[#00b67a]": number >= 4.5,
        "#bg-[#73cf11]": number >= 3.5 && number < 4.5,
        "#bg-[#ffce00]": number >= 2.5 && number < 3.5,
        "#bg-[#ff8622]": number >= 1.5 && number < 2.5,
        "#bg-[#ff3722]": number < 1.5,
      };
    },
  },
};
</script>
