<script setup>
import OrderOption from "../classes/OrderOption";
import { useSetup } from "../composables/option";
import { isRequired } from "../checks/basic";
import { useSelectOptionProps } from "../composables/props";
import { ref } from "vue";
import SelectField from "../fields/SelectField.vue";
import { FatalFormError } from "../composables/errors";
import { __ } from "../composables/lang";
import { useOrderForm } from "../../modules/orderModule";

defineOptions(
  new OrderOption({
    key: "size_photoframe_sizes",
    value: "",
    label: "Fotolijsten",
    showPriceDiff: true,
    updateUrl: true,
  }),
);

const options = ref([
  {value: "none", label: "- selecteer -"},
  {value: "150x150", label: "Vierkant - 15x15cm"},
  {value: "200x200", label: "Vierkant - 20x20cm"},
  {value: "230x230", label: "Vierkant - 23x23cm"},
  {value: "250x250", label: "Vierkant - 25x25cm"},
  {value: "300x300", label: "Vierkant - 30x30cm"},
  {value: "400x400", label: "Vierkant - 40x40cm"},
  {value: "500x500", label: "Vierkant - 50x50cm"},
  {value: "600x600", label: "Vierkant - 60x60cm"},
  {value: "700x700", label: "Vierkant - 70x70cm"},
  {value: "100x150", label: "Rechthoek - 10x15cm"},
  {value: "180x240", label: "Rechthoek - 18x24cm"},
  {value: "200x250", label: "Rechthoek - 20x25cm"},
  {value: "200x300", label: "Rechthoek - 20x30cm"},
  {value: "210x300", label: "Rechthoek - 21x30cm"},
  {value: "240x300", label: "Rechthoek - 24x30cm"},
  {value: "300x400", label: "Rechthoek - 30x40cm"},
  {value: "400x500", label: "Rechthoek - 40x50cm"},
  {value: "400x600", label: "Rechthoek - 40x60cm"},
  {value: "500x700", label: "Rechthoek - 50x70cm"},
  {value: "600x800", label: "Rechthoek - 60x80cm"},
  {value: "610x910", label: "Rechthoek - 61x91cm"},
  {value: "700x1000", label: "Rechthoek - 70x100cm"}
]);

const props = defineProps(useSelectOptionProps());
const { option, check, disabled, tooltipTitle, tooltipContent } = useSetup(props);
const form = useOrderForm();

check(isRequired());
form.addOptionCheck({
  option,
  section: __("Fotolijst"),
  fn: (option, form) => {
    if (form.isEmpty(option) || form.getValue(option) === "none") {
      throw new FatalFormError(__("Er moet een waarde ingevuld worden"));
    }
  },
});
</script>

<template>
  <SelectField
    v-bind="$props"
    :disabled="disabled"
    :tooltip-title="tooltipTitle"
    :tooltip-content="tooltipContent"
    :option="option"
    :options="options"
    :default-option="'none'"
  />
</template>
