<template>
  <MediaItem icon="comments">
    <template #title>
      <a href="#chat" @click="openChat">{{ __("Chat support") }}</a>
    </template>
    <p v-if="storehours.open">
      {{ __("Stel je vraag en krijg binnen 3 minuten antwoord.") }}
    </p>
    <p v-else>
      <span v-html="storehours.html"></span>
    </p>
  </MediaItem>
</template>

<script>
import { mapGetters } from "vuex";
import { lang } from "../mixins";
import MediaItem from "../components/MediaItem.vue";
export default {
  name: "ContactChat",
  components: { MediaItem },
  mixins: [lang],
  computed: {
    ...mapGetters(["storehours"]),
  },
  methods: {
    openChat() {
      this.emitter.emit("trigger-intercom", "showNewMessage");
    },
  },
};
</script>
