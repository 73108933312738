<script setup>
import { getCurrentInstance } from "vue";
import HiddenField from "../fields/HiddenField.vue";
import OrderOption from "../classes/OrderOption";

defineOptions(
  new OrderOption({
    key: "id",
  }),
);
defineProps({ initial: { type: Number, default: null } });
const option = getCurrentInstance().type;
</script>

<template>
  <HiddenField :option="option" :initial="initial" type="integer" />
</template>
